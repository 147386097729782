import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import api from '../../../api';
import { Button, Modal, Form, Spinner, ProgressBar } from 'react-bootstrap';
import moment from 'moment-timezone';
import InfoIcon from '../../../components/InfoIcon';
import '../../../styles/AppointmentBooking.css';
import VisAlert from '../../VisAlert';
import VerificationCodeComp from '../../../components/VerificationCodeComp';
import BookingComp from '../../../components/BookingComp';


const AppointmentBookingForm = () => {
    const { therapistId } = useParams();
    const [usrStatus, setUsrStatus] = useState('');
    const [newUsrId, setNewUsrId] = useState(null);
    const [step, setStep] = useState(0); // Track the current slide
    const [progress, setProgress] = useState(0); // Track progress bar value
    const [email, setEmail] = useState('');
    const [isNewUser, setIsNewUser] = useState(false);
    const [isVerified, setIsVerified] = useState(false);
    const [profile, setProfile] = useState(null); // Store user profile data
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phone, setPhone] = useState('');
    const [loading, setLoading] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertType, setAlertType] = useState('info');

    const navigate = useNavigate();
    // Fetch profile data from localStorage if user is logged in
    const isLoggedIn = Boolean(localStorage.getItem('profile'));

    useEffect(() => {
        // Only trigger redirect when step === 3
        if (step === 4) {
            const timer = setTimeout(() => {
                navigate('/therapists');
            }, 5000);

            // Clean up the timer if the component is unmounted or if step changes
            return () => clearTimeout(timer);
        }
    }, [step, navigate]);

    useEffect(() => {
        if (alertMessage) {
            const timeoutId = setTimeout(() => {
                setAlertMessage(''); // Clear the alert message
                setAlertType('info');    // Optionally clear the alert type as well
            }, 5000); // 5 seconds

            // Clean up the timeout if the component unmounts or if alertMessage changes
            return () => clearTimeout(timeoutId);
        }
    }, [alertMessage]);

    useEffect(() => {
        console.log("isNewUser:", isNewUser);
        console.log("Step:", step);
        // Whenever isNewUser changes, handle moving to the verification step
        if (step === 1 && !isNewUser && usrStatus === 'inactive') {
            nextStep();  // Proceed to the verification step if the user is not new
        }
    }, [isNewUser, step, usrStatus]); 
    
    useEffect(() => {
        if (newUsrId) {
            console.log('newUsrId has been updated:', newUsrId);
        }
    }, [newUsrId]);

    useEffect(() => {
        if (isLoggedIn) {
            const storedProfile = JSON.parse(localStorage.getItem('profile'));
            setProfile(storedProfile);
            setUsrStatus('active')
            setIsNewUser(false);
            setIsVerified(true);
            setNewUsrId(storedProfile.user_id);
            setStep(3); 
        }
    }, [isLoggedIn]);

    // Function to move to the next step
    const nextStep = () => {
    setStep((prevStep) => {
        const newStep = prevStep + 1;

        // If moving to step 3 (final step), set progress to 100%
        if (!isLoggedIn && newStep === 4) {
            setProgress(100);
        } else if (!isLoggedIn) {
            // Increment progress for earlier steps
            setProgress((prevProgress) => prevProgress + (isNewUser ? 20 : 25));
        }

        return newStep;
    });
};

    // Updated function to handle email submission
    const handleEmailSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const response = await api.get(`/api/check-email/${email}`);
            console.log('response.data is', response.data);

            if (response.data.status === 'new_user') {
                setUsrStatus('new')
                setIsNewUser(true);
                nextStep(); // Move to first name, last name, and phone number
            } else if (response.data.status === 'inactive_user') {
                setUsrStatus('inactive')
                setIsNewUser(false);
                setNewUsrId(response.data.user_id);
                await api.post('/api/send-confirmation-code/', { email });
                setStep(2); // Move to verification step
            } else if (response.data.status === 'active_user') {
                // Directly move to booking form for active users
                setUsrStatus('active')
                setStep(1);
                // setIsNewUser(false);
                // setIsVerified(true);
                // setNewUsrId(response.data.user_id);
                // const resp = api.get(`api/profiles/${response.data.user_id}/`)
                // setProfile(resp.data);
                // setStep(2);
            }
        } catch (error) {
            console.error('Error checking email:', error);
        } finally {
            setLoading(false);
        }
    };

    // Function to handle new user data submission
    const handleNewUserSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const formattedFirstName = firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
            const formattedLastName = lastName.charAt(0).toUpperCase() + lastName.slice(1).toLowerCase();
            const formattedPhone = phone.replace(/\D/g, '').slice(0, 10); // Ensure it's 10 digits

            // Send new user details and request verification code
            const resp = await api.post('/api/send-confirmation-code/', {
                email,
                first_name: formattedFirstName,
                last_name: formattedLastName,
                phone: formattedPhone,
            });
            setNewUsrId(resp.data.user_id);
            setIsNewUser(false);
            nextStep(); // Move to verification step
        } catch (error) {
            console.error('Error sending new user data:', error);
        } finally {
            setLoading(false);
        }
    };

    // Handle verification success (from VerificationCodeComp)
    const handleVerificationSuccess = () => {
        //setProfile(profileData); // Set user profile data after verification
        //console.log('profiledata is ', profileData, 'and profile is ', profile);
        setIsVerified(true);
        nextStep(); // Move to booking form
    };

    console.log('isLoggedIn..', isLoggedIn);
    console.log('usrStatus:', usrStatus);
    console.log('step:', step);
    console.log('isNewUser:', isNewUser);
    return (
        <div>
            {alertMessage && <VisAlert message={alertMessage} type={alertType} />}

            {!isLoggedIn && (
                <div style={{ paddingTop: '10px', paddingBottom: '30px' }}>
                    <ProgressBar now={progress} label={`${progress}%`} />
              </div>
            )}

            {!isLoggedIn && step === 0 && (
                <Form onSubmit={handleEmailSubmit}>
                    <Form.Group controlId="email">
                        <Form.Label>Enter your email</Form.Label>
                        <Form.Control
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Enter your email"
                            required
                        />
                    </Form.Group>
                    <Button type="submit" disabled={loading}>
                        {loading ? <Spinner animation="border" size="sm" /> : 'Next'}
                    </Button>
                </Form>
            )}

            {!isLoggedIn && step === 1 && !isNewUser && usrStatus === 'active' && (
                <div>
                    Our records indicate that you have an <strong>active</strong> account with us. Please <Link to="/login">login</Link> to continue booking.
                </div>
            )}

            {!isLoggedIn && step === 1 && isNewUser && (
                    <Form onSubmit={handleNewUserSubmit}>
                    <Form.Group controlId="firstName">
                        <Form.Label>First Name</Form.Label>
                        <Form.Control
                            type="text"
                            value={firstName}
                            onChange={(e) => {
                                const value = e.target.value;
                                if (/^[a-zA-Z\s]*$/.test(value)) {
                                    setFirstName(value);
                                }
                            }}
                            required
                            placeholder="Enter your first name"
                        />
                    </Form.Group>
                    <Form.Group controlId="lastName">
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control
                            type="text"
                            value={lastName}
                            onChange={(e) => {
                                const value = e.target.value;
                                if (/^[a-zA-Z\s]*$/.test(value)) {
                                    setLastName(value);
                                }
                            }}
                            required
                            placeholder="Enter your last name"
                        />
                    </Form.Group>
                    <Form.Group controlId="phone">
                    <Form.Label>Phone Number</Form.Label>
                    <Form.Control
                        type="text"
                        value={phone}
                        onChange={(e) => {
                            const value = e.target.value;
                            if (/^\d*$/.test(value) && value.length <= 10) {
                                setPhone(value);
                            }
                        }}
                        required
                        placeholder="Enter your phone number"
                        maxLength={10} // Optional: limits the input box to max 10 digits
                    />
                </Form.Group>
                    <Button type="submit" disabled={loading}>
                        {loading ? <Spinner animation="border" size="sm" /> : 'Submit'}
                    </Button>
                </Form>
            )}

            {!isLoggedIn && step === 2 && !isVerified && (
                <VerificationCodeComp
                    email={email}
                    onVerificationSuccess={handleVerificationSuccess}
                    setAlertMessage={setAlertMessage}
                    setAlertType={setAlertType}
                />
)}

            {step === 3 && isVerified && (
                <BookingComp therapistId={therapistId} newUsrId={newUsrId} usrStatus={usrStatus} isLoggedIn={isLoggedIn} profile={profile} onBookingSuccess={nextStep} setAlertMessage={setAlertMessage} setAlertType={setAlertType}/>
            )}

            {step === 4 && (
                 <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100vh', // Full viewport height to vertically center the content
                        flexDirection: 'column', // Stack items vertically
                        textAlign: 'center' // Center align the text
                    }}>
                    <h3>Thank you for booking your appointment!</h3>
                    {/* Embed the GIF */}
                    <div style={{ marginTop: '20px' }}>
                        <img src="https://res.cloudinary.com/dhdn6hovg/image/upload/v1729429849/verified_spuv3j.gif" alt="verification gif"/>
                    </div>

                    <p>You will be redirected shortly...</p>
                </div>
            )}

        </div>
    );
};

export default AppointmentBookingForm;