import axios from "axios";
import { ACCESS_TOKEN } from "./constants";

const api = axios.create({
    baseURL: import.meta.env.VITE_API_URL
});

api.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem(ACCESS_TOKEN);
        //console.log('Access token:', token); 
        // Don't attach the token for non-protected routes
        const nonProtectedRoutes = ['/api/check-email/', '/api/therapists/', '/api/verify-code/', '/api/user-search-email/', '/api/send-confirmation-code/', '/api/user/active-status/', 'api/password-reset/', 'api/reset-password/'];

        // Check if the request URL is in the non-protected routes list
        const isNonProtectedRoute = nonProtectedRoutes.some(route => config.url === route);

        if (token && !isNonProtectedRoute) {
            config.headers.Authorization = `Bearer ${token}`;
        }

        return config;
    }, 
    (error) => {
        return Promise.reject(error);
    }
);


// Add a response interceptor
api.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response && error.response.status === 401) {
            // Emit a custom event to notify about authentication error
            window.dispatchEvent(new Event('auth-error'));
        }
        return Promise.reject(error);
    }
);

export default api;
