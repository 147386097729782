import React, { useState, useEffect } from 'react';
import { format, addMinutes } from 'date-fns';
import { Button, Modal } from 'react-bootstrap';
import ApptDetailsForm from './ApptDetailsForm';
import api from '../../api';

const ApptFilledForm = ({ 
    isOpen, 
    onSave, 
    onClose,
    onDelete,
    setOpenSoap,
    setSelectedAppointment,
    setSelectedProfId,
    openSoap,
    setOpenCheckout,
    openCheckout,
    disabledButtons,
    clinicId,
    appointment,       
    selectedUser, 
    aptMsg,      
    setAptMsg,
    fetchAppointments,
    openHealthHist,
    setOpenHealthHist,
    setAppointments,
    setAppModalOpen,
    setAlertMessage,
    setAlertType,
}) => {
    const [ApptTitle, setApptTitle] = useState('Swedish Massage');
    const [ApptDuration, setApptDuration] = useState(30);
    const [ApptEmail, setApptEmail] = useState('');
    const [selectedDate, setSelectedDate] = useState('');
    const [resourceId, setResourceId] = useState(null);
    const [isTherapist, setIsTherapist] = useState(false);
    const [services, setServices] = useState([]);  // Available services for dropdown
    const [availableDurations, setAvailableDurations] = useState([]);  // Durations for the selected service
    const [isLoading, setIsLoading] = useState(true);
    const [endTime, setEndTime] = useState('');
    const [rate, setRate] = useState('');
    const [editableRate, setEditableRate] = useState(''); // For the editable input box
    const [isLoadingRate, setIsLoadingRate] = useState(false);
    const [showPaymentModal, setShowPaymentModal] = useState(false);
    const [custName, setCustName] = useState('');

    // Load data from localStorage once on mount
    useEffect(() => {
        if (appointment && selectedUser) {
            console.log('useeffect appt', appointment);
            const { title, start, end, email, resourceId: appointmentResourceId, apt_msg, username } = appointment;
            const duration = (new Date(end) - new Date(start)) / (1000 * 60);
            const profile = JSON.parse(localStorage.getItem('profile'));
            console.log('useeffect START', start);
            setCustName(username);
            setApptTitle(title);
            setSelectedDate(start);
            setEndTime(end);
            setApptDuration(duration);
            setApptEmail(email);
            setResourceId(appointmentResourceId);
            setIsTherapist(profile?.is_therapist || false);
            setAptMsg(apt_msg);
            
            // Fetch services for the therapist (resourceId)
            fetchServices(appointmentResourceId);
            const currentUserProfData = api.get(`/api/profile/${selectedUser.user_id}`);
            console.log('currentUserProfData is ', currentUserProfData.data);
            api.get(`/api/health-history/profile/${currentUserProfData.id}/`)
                .then((response) => {
                    console.log('resp', response);
                    const healthData = response.data.health_data; // Ensure this is the correct path
                    if (healthData?.allergies) {
                        setAllergies(healthData.allergies); // Update allergies state
                    }
                })
                .catch((error) => {
                    console.error("Error fetching health history:", error);
            });
        }
    }, [appointment, selectedUser]);

    const fetchServices = async (resourceId) => {
        try {
            const response = await api.get(`/api/therapists/${resourceId}/services/`);
            setServices(response.data);
            const currentService = response.data.find(service => service.name === ApptTitle);
            // unrelated to fetchservices, this gets the prof.id
            const resp = await api.get(`api/profile/${selectedUser.user_id}/`);
            // console.log('setSelectedProfId selectedUser.user_id', selectedUser.user_id);
            // console.log('setSelectedProfId', resp);
            setSelectedProfId(resp.data.id);
            if (currentService) {
                setAvailableDurations(currentService.durations);  // Set durations for this service
            }
        } catch (error) {
            console.error('Error fetching services:', error);
            setServices([]);
        } finally {
            setIsLoading(false); // Set loading to false after services are fetched
        }
    };

    const fetchRate = async () => {
        const differenceInMinutes = Math.floor(
            (new Date(endTime) - new Date(selectedDate)) / (1000 * 60)
        );
        console.log('fetchRATE: endtime', new Date(endTime))
        console.log('fetchRATE: selecteddate', new Date(selectedDate))
        console.log('selectedDateendTime', selectedDate)
        console.log('endTimeendTime', endTime)
        console.log('diff in minutes is', differenceInMinutes);

        setIsLoadingRate(true);

        try {
            const response = await api.get(`/api/service-rate/${ApptTitle}/${differenceInMinutes}/`);
            console.log('service rate is ', response.data.rate);
            setRate(response.data.rate);
            setEditableRate(response.data.rate); // Initialize the editable input box
        } catch (error) {
            console.error('Error fetching rate:', error);
        } finally {
            setIsLoadingRate(false);
        }
    };

    const handleRequestPayment = async () => {
        try {
            const emailData = {
                appointmentId: appointment.id,
                userEmail: ApptEmail,
                clinicId,
                price: editableRate,
                custName: custName, // Pass the editable price
            };
    
            const response = await api.post('/api/request-payment/', emailData);
    
            if (response.status === 200) {
                setAlertMessage('Payment request sent successfully!');
                setAlertType('success');
                setShowPaymentModal(false);
            } else {
                console.error('Error sending payment request:', response.data);
                setAlertMessage('Failed to send payment request. Please try again.');
                setAlertType('warning');
            }
        } catch (error) {
            console.error('Error in handleRequestPayment:', error);
            setAlertMessage('An error occurred while sending the payment request.');
            setAlertType('warning');
        }
    };
    


    useEffect(() => {
        console.log("SelectedDate:", selectedDate);
        console.log("EndTime:", endTime);
        console.log("Appointment:", appointment);
    
        if (appointment && selectedDate && endTime) {
            fetchRate();
        }
    }, [appointment, selectedDate, endTime]);

    const handleDelete = () => {
        onDelete(JSON.parse(localStorage.getItem('selAppt')).id);
    };

    const handleServiceChange = (selectedService) => {
        setApptTitle(selectedService);
        const service = services.find(s => s.name === selectedService);
        if (service) {
            setAvailableDurations(service.durations);
            setApptDuration(service.durations[0]); // Default to the first available duration
        }
    };

    const handleDurationChange = (duration) => {
        setApptDuration(duration);
    };

    const handleOpenPaymentModal = () => {
        setShowPaymentModal(true);
    };

    const handleClosePaymentModal = () => {
        setShowPaymentModal(false);
    };

    // Early return for loading state
    if (isLoading || !selectedDate || !selectedUser) {
        return <p>Loading appointment details...</p>;
    }

    const handleRedirectToDisplayHealthHistory = () => {
        setOpenCheckout(false);
        setOpenSoap(false);
        setOpenHealthHist(true);
    };

    const handleRedirectToDisplaySoap = () => {
        setOpenSoap(true);
        setOpenCheckout(false);
        setOpenHealthHist(false);
        setSelectedAppointment(JSON.parse(localStorage.getItem('selAppt')));
    };

    const handleRedirectToDisplaySched = async () => {
        setOpenSoap(false);
        setOpenHealthHist(false);
        setOpenCheckout(false);
        if (fetchAppointments) {
            await fetchAppointments(format(new Date(), 'yyyy-MM-dd'));
        }
    };

    const handleRedirectToDisplayCheckout = () => {
        setOpenSoap(false);
        setOpenHealthHist(false);
        setOpenCheckout(true);
    };

    const handleSave = () => {
        onSave({
            id: appointment.id,
            therapist: resourceId,
            start_time: format(selectedDate, "yyyy-MM-dd'T'HH:mm:ss"),
            end_time: format(addMinutes(selectedDate, ApptDuration), "yyyy-MM-dd'T'HH:mm:ss"),
            title: ApptTitle,
            date: format(selectedDate, 'yyyy-MM-dd'),
            username: `${selectedUser.first_name}, ${selectedUser.last_name.charAt(0)}`,
            user_email: ApptEmail,
            appt_length: ApptDuration,
            user: appointment.user,
            clinic: clinicId,
            apt_msg: aptMsg,
        });
    };

    const renderButtonGroup = () => {
        if (openHealthHist) {
            // Only display SOAP Notes or Schedule buttons if on Health History page
            return (
                <div className="button-group">
                    <Button
                        className='btn-soap'
                        onClick={handleRedirectToDisplaySoap}
                    >
                        SOAP Notes
                    </Button>
                    <Button
                        className='btn-schedule'
                        onClick={handleRedirectToDisplaySched}
                    >
                        Schedule
                    </Button>
                </div>
            );
        } else if (openSoap) {
            // Only display Schedule or SOAP Notes buttons if on SOAP Notes page
            return (
                <div className="button-group">
                    <Button
                        className='btn-schedule'
                        onClick={handleRedirectToDisplaySched}
                    >
                        Schedule
                    </Button>
                    <Button
                        className='btn-healthhistory'
                        onClick={handleRedirectToDisplayHealthHistory}
                    >
                        Health History
                    </Button>
                </div>
            );
        } else if (openCheckout) {
            // Only display Schedule button if on Checkout page
            return (
                <div className="button-group">
                    <Button
                        className='btn-schedule'
                        onClick={handleRedirectToDisplaySched}
                    >
                        Schedule
                    </Button>
                </div>
            );
        } else {
            // Display all buttons (SOAP Notes, Checkout, and Health History) on Schedule page
            return (
                <div className="button-group">
                    <Button
                        className='btn-soap'
                        onClick={handleRedirectToDisplaySoap}
                    >
                        SOAP Notes
                    </Button>
                    <Button
                        className='btn-checkout'
                        onClick={handleRedirectToDisplayCheckout}
                    >
                        Checkout
                    </Button>
                    <Button
                        className='btn-healthhistory'
                        onClick={handleRedirectToDisplayHealthHistory}
                    >
                        Health History
                    </Button>
                </div>
            );
        }
    };

    return (
        <div className="appointment-frame">
            {isTherapist && (
                <div className="button-frame">
                    {renderButtonGroup()}
                </div>
            )}
            <ApptDetailsForm
                ApptTitle={ApptTitle}
                setApptTitle={handleServiceChange}
                ApptDuration={ApptDuration}
                setApptDuration={handleDurationChange}
                ApptEmail={ApptEmail}
                setApptEmail={setApptEmail}
                selectedDate={selectedDate}
                handleSaveAppointment={handleSave}
                handleCancel={onClose}
                isEditing={true}
                onDelete={handleDelete}
                selectedUser={selectedUser}
                resourceId={resourceId}
                openCheckout={openCheckout}
                disabledButtons={disabledButtons}
                services={services} 
                availableDurations={availableDurations}
                aptMsg={aptMsg}
                setAptMsg={setAptMsg}
                fetchAppointments={fetchAppointments}
            />
         {isLoadingRate ? (
                <p>Loading rate...</p>
            ) : (
                <div className="request-payment">
                    <Button className="btn-req_payment" onClick={handleOpenPaymentModal}>
                        Request Payment
                    </Button>
                </div>
            )}
             {/* Modal for Request Payment */}
             <Modal show={showPaymentModal} onHide={handleClosePaymentModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Request Payment</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        <strong>Email:</strong> {ApptEmail}
                    </p>
                    <label htmlFor="modalEditableRate">Price (CAD):</label>
                    <input
                        id="modalEditableRate"
                        type="text"
                        value={editableRate}
                        onChange={(e) => {
                            const value = e.target.value;
                            // Allow only digits and limit to 5 characters
                            if (/^\d{0,5}$/.test(value)) {
                                setEditableRate(value);
                            }
                        }}
                        className="form-control"
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClosePaymentModal}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleRequestPayment} disabled={!editableRate}>
                        Send Request
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default ApptFilledForm;
