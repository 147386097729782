import React, { useEffect, useState } from "react";
import api from "../../api";
import receiptImage from "../../assets/receipt.jpg";
import paidImage from "../../assets/paid.png";
import VisAlert from "../VisAlert";
import jsPDF from "jspdf";
import "jspdf-autotable";
import '../../styles/ListReceiptsPage.css';


const ListReceiptsPages = () => {
    const [receipts, setReceipts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [pdfContent, setPdfContent] = useState(null);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertType, setAlertType] = useState('error');


    const fetchReceiptDetails = async (receipt, clinic_id) => {
        try {
            const response = await api.get('/api/receipt-details/', {
                params: {
                    receipt_id: receipt.receipt_sale_id,
                    clinic_id,
                },
            });
    
            const { receipt_totals, appointment, clinic_info } = response.data;
    
            // Normalize receipt_totals to ensure it's an array
            const normalizedTotals = Array.isArray(receipt_totals) ? receipt_totals : [receipt_totals];
    
            const itemsWithTherapist = normalizedTotals.map((item) => ({
                ...item,
                therapist_name: appointment.therapist_name,
            }));
    
            return { itemsWithTherapist, appointment, clinic_info };
        } catch (error) {
            console.error("Error fetching receipt details:", error);
            throw error;
        }
    };
    
    
    useEffect(() => {
        const fetchReceipts = async () => {
            try {
                const userData = localStorage.getItem('user');
                const parsedUserData = JSON.parse(userData);
                const username = parsedUserData.username;
                const { clinic_id } = parsedUserData.profile;
    
                console.log('Fetched username:', username);
                console.log('Fetched clinic_id:', clinic_id);
    
                const receiptsResponse = await api.get('/api/user/receipts/', {
                    params: { email: username, clinic_id },
                });
    
                const receiptsWithTherapist = await Promise.all(
                    receiptsResponse.data.map(async (receipt) => {
                        const { itemsWithTherapist } = await fetchReceiptDetails(receipt, clinic_id);
                        return {
                            ...receipt,
                            items: itemsWithTherapist, // Update items with therapist_name
                        };
                    })
                );
    
                console.log("Receipts with Therapist Names:", receiptsWithTherapist);
                setReceipts(receiptsWithTherapist);
            } catch (err) {
                console.error("Error fetching receipts:", err);
                setError(err);
            } finally {
                setLoading(false);
            }
        };
    
        fetchReceipts();
    }, []);
    

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error.message}</div>;

    const handleImageClick = async (receipt) => {
        try {
            const userData = localStorage.getItem('user');
            const parsedUserData = JSON.parse(userData);
            const { clinic_id } = parsedUserData.profile;
    
            console.log('clinic_id issssss', clinic_id);
    
            const { itemsWithTherapist, appointment, clinic_info } = await fetchReceiptDetails(receipt, clinic_id);
    
            // Generate the receipt
            const pdfBlob = await generateReceipt(
                receipt.receipt_sale_id,
                clinic_info,
                window.location.origin,
                appointment.therapist_name,
                appointment.therapist_name,
                itemsWithTherapist,
                receipt
            );
    
            // Display the PDF in the modal
            const pdfUrl = URL.createObjectURL(pdfBlob);
            setPdfContent(pdfUrl);
            setShowModal(true);
        } catch (error) {
            console.error("Error fetching or generating receipt:", error);
        }
    };
    
    
    const handleEmailClick = async (receipt) => {
        try {
            const { clinic_id } = JSON.parse(localStorage.getItem("user")).profile;
    
            // Fetch the clinic info and receipt details
            const { itemsWithTherapist, clinic_info, appointment } = await fetchReceiptDetails(receipt, clinic_id);
    
            // Prepare the data for generating the PDF
            const pdfBlob = await generateReceipt(
                receipt.receipt_sale_id,
                clinic_info,
                appointment.therapist_name,
                itemsWithTherapist,
                receipt
            );
    
            // Prepare the email request payload
            const formData = new FormData();
            formData.append("pdf", pdfBlob, `receipt_${receipt.receipt_sale_id}.pdf`);
            formData.append("receiptData", JSON.stringify({
                receipt_sale_id: receipt.receipt_sale_id,
                clinic_name: clinic_info.name,
                customer_email: receipt.customer_email,
                customer_name: receipt.customer_name,
                sales_rep: receipt.sales_rep,
                checkout_therapist: receipt.checkout_therapist,
                date: receipt.date,
                grand_total: receipt.grand_total,
                selected_payment: receipt.selected_payment,
                is_void: receipt.is_void,
            }));
            formData.append("receiptTotalsData", JSON.stringify(itemsWithTherapist));
    
            // Send the email request to the backend
            const response = await api.post("/api/send-receipt-email/", formData, {
                headers: { "Content-Type": "multipart/form-data" },
            });
    
            if (response.status === 200) {
                setAlertMessage("Receipt email sent successfully!");
                setAlertType('success');
            } else {
                console.error("Error sending receipt email:", response.data);
                setAlertMessage("Failed to send receipt email. Please try again.");
                setAlertType('warning');
            }
        } catch (error) {
            console.error("Error in handleEmailClick:", error);
            setAlertMessage("An error occurred while sending the email.");
            setAlertType('warning');
        }
    };

    const generateReceipt = async (receiptId, clinicData, urlData, therapistSel, salesRep, receiptTotalsData, receipt) => {
        const doc = new jsPDF({
            unit: 'pt',
            format: [227, 450]
        });

        const lineHeight = 14;
        const leftMargin = 15;

        const { name, address, city, state, phone } = clinicData;
        const saleDate = receipt.date || new Date().toLocaleString('en-US', {
            hour12: true,
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: 'numeric',
            minute: 'numeric',
        });

        let y = 95;

        const drawAlignedText = (label, value, yPosition, fontSize = 9, fontStyle = 'normal') => {
            doc.setFont("Helvetica", fontStyle);
            doc.setFontSize(fontSize);
            doc.text(`${label}:`, leftMargin, yPosition);
            doc.text(value, leftMargin + 100, yPosition);
        };

        if (receipt.is_void) {
            const img = new Image();
            img.src = "https://res.cloudinary.com/dhdn6hovg/image/upload/v1732544843/void_sloq7k.png";
            doc.addImage(img, "PNG", 0, 0, doc.internal.pageSize.width, doc.internal.pageSize.height);
          }
        // Header
        doc.setFontSize(12);
        doc.setFont("Helvetica", "bold");
        doc.text(name || 'Clinic Name', doc.internal.pageSize.width / 2, y, { align: 'center' });
        y += lineHeight;
        doc.setFontSize(10);
        doc.text(address || 'Clinic Address', doc.internal.pageSize.width / 2, y, { align: 'center' });
        y += lineHeight;
        doc.text(`${city || ''}, ${state || ''}`, doc.internal.pageSize.width / 2, y, { align: 'center' });
        y += lineHeight;
        doc.text(phone || 'Clinic Phone', doc.internal.pageSize.width / 2, y, { align: 'center' });
        y += lineHeight * 2;

        // Receipt details
        drawAlignedText('Receipt ID', receiptId.toString(), y);
        y += lineHeight;
        drawAlignedText('Sale Date', saleDate, y);
        y += lineHeight;
        drawAlignedText('Sales Rep', salesRep, y);
        y += lineHeight;
        drawAlignedText('Therapist', therapistSel, y);
        y += lineHeight * 2;

        // Items
        doc.setFont("Helvetica", "bold");
        doc.setFontSize(10);
        doc.text('Items:', leftMargin, y);
        y += lineHeight;

        doc.setFont("Helvetica", "normal");
        receiptTotalsData.forEach((item) => {
            const itemDescription = `${item.appt_length} min ${item.title}`;
            const itemRate = `Rate: $${item.rate}`;
            const itemTax = `Tax: $${item.tax}`;
            const itemTotal = `Total: $${item.total}`;
            doc.text(itemDescription, leftMargin, y);
            y += lineHeight;
            doc.text(itemRate, leftMargin, y);
            y += lineHeight;
            doc.text(itemTax, leftMargin, y);
            y += lineHeight;
            doc.text(itemTotal, leftMargin, y);
            y += lineHeight * 1.5;
        });

        // Totals
        doc.setFont("Helvetica", "bold");
        drawAlignedText('Grand Total', `$${receipt.grand_total}`, y);
        y += lineHeight * 2;

        // Footer
        doc.setFontSize(8);
        doc.setFont("Helvetica", "italic");
        doc.text("Thank you for your business!", doc.internal.pageSize.width / 2, y, { align: 'center' });
        y += lineHeight;
        doc.text("Please retain this receipt for your records.", doc.internal.pageSize.width / 2, y, { align: 'center' });

        doc.internal.pageSize.height = y + 20;

        return doc.output("blob");
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setPdfContent(null);
    };

    return (
        <div>
            <h2>Your Receipts</h2>
            {receipts.length > 0 ? (
                <table className="receipt-table">
                    <thead>
                        <tr>
                            <th>Receipt ID</th>
                            <th>Items</th>
                            <th>Total</th>
                            <th>Payment Method</th>
                            <th>Receipt PDF</th>
                        </tr>
                    </thead>
                    <tbody>
                        {receipts.map((receipt) => (
                            <tr key={receipt.receipt_sale_id}>
                                <td>{receipt.receipt_sale_id}</td>
                                <td>
                                    {receipt.items ? (
                                        receipt.items.map((item, index) => (
                                            <div key={index}>
                                                {item.appt_length} Minute {item.title} with {receipt.checkout_therapist}
                                            </div>
                                        ))
                                    ) : (
                                        <div>No items available</div>
                                    )}
                                </td>

                                <td>${receipt.grand_total}</td>
                                <td>{receipt.selected_payment}</td>
                                <td>
                                <img
                                    src="https://res.cloudinary.com/dhdn6hovg/image/upload/v1732586133/pdf-removebg-preview_yifjqa.png"
                                    alt="PDF Preview"
                                    onClick={() => handleImageClick(receipt)}
                                    style={{ cursor: "pointer", width: "24px", height: "24px" }}
                                />
                                <img
                                    src="https://res.cloudinary.com/dhdn6hovg/image/upload/v1732590609/email-image_qm2wgk.png"
                                    alt="Send Email"
                                    onClick={() => handleEmailClick(receipt)}
                                    style={{ cursor: "pointer", width: "35px", height: "35px"}}
                                />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <p>No receipts found.</p>
            )}

            {showModal && (
                <div className="modal">
                    <div className="modal-content">
                        <span className="close" onClick={handleCloseModal}>
                            &times;
                        </span>
                        <iframe src={pdfContent} width="100%" height="500px" title="PDF Preview" />
                    </div>
                </div>
            )}
        </div>
    );
};

export default ListReceiptsPages;

