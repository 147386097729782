import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import api from '../api';

const VerificationCodeComp = ({ email, onVerificationSuccess, setAlertMessage, setAlertType }) => {
    const [verificationCode, setVerificationCode] = useState(['', '', '', '', '']);
    const [loading, setLoading] = useState(false);
    const [attemptCount, setAttemptCount] = useState(0);
    //const [showResendLink, setShowResendLink] = useState(false); // Control "Resend Code" button visibility

    const handleVerificationCodeChange = (index, value) => {
        const updatedCode = [...verificationCode];
        updatedCode[index] = value.replace(/[^0-9]/g, ''); // Only allow digits
        setVerificationCode(updatedCode);

        // Move focus to the next input if a digit is entered
        if (value && index < verificationCode.length - 1) {
            document.getElementById(`code-input-${index + 1}`).focus();
        }
    };

    const handleKeyUp = (e, index) => {
        // Move to the previous input box if backspace is pressed and the current box is empty
        if (e.key === 'Backspace' && !verificationCode[index] && index > 0) {
            document.getElementById(`code-input-${index - 1}`).focus();
        }
    };

    const handleSubmitVerificationCode = async (e) => {
        if (e) e.preventDefault(); // Prevent default form submission behavior
        const code = verificationCode.join('');
        setLoading(true);
        try {
            console.log(`Sending verification code request with email: ${email}, code: ${code}`);
            const response = await api.post('/api/verify-code/', { email, code });
            if (response.data.success) {
                onVerificationSuccess(); // Notify parent component that verification succeeded
            } else {
                setAttemptCount((prev) => prev + 1);
                if (attemptCount >= 4) {
                    // Show "Resend Code" button after 5 failed attempts
                    setAlertMessage('You have entered the wrong verification code too many times.');
                    setAlertType('warning');
                    //setShowResendLink(true);
                } else {
                    setAlertMessage('Invalid code. Please try again.');
                    setAlertType('danger');
                }
            }
        } catch (error) {
            console.log('Error details:', error.response ? error.response.data : error.message);
            setAlertMessage('There was an error verifying your code. Please try again.');
            setAlertType('danger');
        } finally {
            setLoading(false);
        }
    };

    // const handleResendCode = async () => {
    //     setLoading(true);
    //     try {
    //         // Call API to resend the confirmation code
    //         await api.post('/api/send-confirmation-code/', { email });
    //         setAlertMessage('A new verification code has been sent to your email.');
    //         setAlertType('info');
    //         setAttemptCount(0); // Reset attempt count after resending code
    //         setShowResendLink(false); // Hide the resend link
    //     } catch (error) {
    //         setAlertMessage('Error resending code. Please try again later.');
    //         setAlertType('danger');
    //     } finally {
    //         setLoading(false);
    //     }
    // };

    return (
        <>
        <h4 style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>Enter Confirmation Code</h4>
            <form onSubmit={handleSubmitVerificationCode}>
                <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
                    {verificationCode.map((code, index) => (
                        <input
                            key={index}
                            id={`code-input-${index}`}
                            type="text"
                            value={code}
                            onChange={(e) => handleVerificationCodeChange(index, e.target.value)}
                            onKeyUp={(e) => handleKeyUp(e, index)}
                            maxLength={1}
                            style={{ width: '30px', marginRight: '5px', textAlign: 'center' }}
                        />
                    ))}
                </div>
                <div style={{ textAlign: 'center' }}>
                    <Button type="submit" variant="primary" style={{ marginTop: '10px' }} disabled={loading}>
                        {loading ? 'Verifying...' : 'Submit'}
                    </Button>
                </div>

                {/* Display "Resend Code" link after 5 failed attempts */}
                {/* {showResendLink && (
                    <div style={{ marginTop: '10px', textAlign: 'center' }}>
                        <Button onClick={handleResendCode} variant="link" disabled={loading}>
                            Resend Code
                        </Button>
                    </div>
                )} */}
            </form>
        </>

    );
};

export default VerificationCodeComp;
