import React, { useState, useEffect } from 'react';
import api from '../../api';
import '../../styles/CurrentTherapistPage.css';
import ImageFetcher from '../Schedule/Calendar/ImageFetcher';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';



const CurrentTherapistsPage = () => {
    const [therapists, setTherapists] = useState([]);
    const [userVerified, setUserVerified] = useState(false);
    const navigate = useNavigate();
    const API_URL = import.meta.env.VITE_API_URL;

    // useEffect(() => {
    //     const fetchUser = async () => {
    //         //const userResp = await api.get('api/user/current/');
    //         const user = JSON.parse(localStorage.getItem('user'));
    //         console.log('userResp', user.profile.first_login);
    //         setUserVerified(!user.profile.first_login)
    //     }
    //     fetchUser();
    // }, [])


    useEffect(() => {
        const fetchTherapists = async () => {
            try {
                // Fetch therapists list
                const therapistsResponse = await api.get('/api/therapists/');
                console.log('therapistResp', therapistsResponse.data);
                setTherapists(therapistsResponse.data);
            } catch (error) {
                console.error('Error fetching therapists:', error);
            }
        };

        fetchTherapists();
    }, []);

    const redirectToBookings = (therapistId) => {
        navigate(`/therapists/${therapistId}/book_therapist`);
    };


    return (
        
        <div>
            {/* { userVerified ? ( */}
                <>
            <h1 className="therapist-title">List of Therapists</h1>
            <div className="therapists-list">
                {therapists.map(therapist => (
                    <div key={therapist.user_id} className="therapist-card">
                        <h3>{therapist.name}</h3>
                        <div style={{padding: '5px'}}>
                            {therapist.image ? (
                                <ImageFetcher
                                        imageUrl={therapist.image}
                                        alt={`${therapist.name}'s Profile`}
                                        style={{ width: '150px', height: '150px', border: '2px solid black', padding:'5px'}}
                                    />
                                ) : (
                                    <ImageFetcher
                                        imageUrl='https://res.cloudinary.com/dhdn6hovg/image/upload/v1729429796/blank-user_qnnpfi.jpg'
                                        alt={`${therapist.name}'s Profile`}
                                        style={{ width: '150px', height: '150px', border: '2px solid black', padding:'5px'}}
                                    />
                                )}



                        </div>
                        <Button onClick={() => redirectToBookings(therapist.user_id)}>Book Now</Button>
                    </div>
                ))}
            </div>
            </>
            {/* ): ( */}
                {/* <p>Sorry, you must update your <Link to="/profile">profile</Link>  before you can view therapists and/or book appointments.</p> */}
            {/* )} */}
        </div>
    );
};

export default CurrentTherapistsPage;