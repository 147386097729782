import React from 'react';
import '../styles/InfoIcon.css';

const InfoIcon = ({ msgText }) => {
  return (
    <div className="info-icon">
      <div className="info-icon-circle">i</div>
      <div className="tooltip">{msgText}</div>
    </div>
  );
};

export default InfoIcon;
