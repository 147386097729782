import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import '../../styles/HealthHistory.css';
import api from '../../api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { toZonedTime } from 'date-fns-tz';
import { format } from 'date-fns';

const ViewHealthHistory = ({ prof_id }) => {
    const torontoTz = 'America/Toronto';
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        phone: '',
        dob: '',
        address: '',
        occupation: '',
        cardiovascular: [],
        respiratory: [],
        infections: [],
        gastrointestinal: [],
        headNeck: [],
        muscleJoint: [],
        womenConditions: [],
        otherConditions: [],
    });
    const [loading, setLoading] = useState(true);
    const [dateUpdated, setDateUpdated] = useState(null);

    useEffect(() => {
        const fetchHealthHistory = async () => {
            try {
                const response = await api.get(`/api/health-history/profile/${prof_id}/`);
                if (response.data && response.data.health_data) {
                    setFormData({ ...formData, ...response.data.health_data });
                    if (response.data.date_updated) {
                        const formattedDate = format(toZonedTime(response.data.date_updated, torontoTz), 'yyyy-MM-dd HH:mm');
                        setDateUpdated(formattedDate);
                    }
                }
            } catch (error) {
                console.error('Error fetching health history data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchHealthHistory();
    }, [prof_id]);

    const categories = [
        {
            label: 'Cardiovascular',
            key: 'cardiovascular',
            conditions: [
                'High Blood Pressure',
                'Low Blood Pressure',
                'Chronic Congestive Heart Failure',
                'Heart Attack',
                'Heart Disease',
                'Heart Murmur',
                'Angina',
                'Phlebitis / Varicose Veins',
                'Stroke / CVA',
                'Pacemaker or Similar Device',
                'Blood Clots',
                'Poor Circulation',
            ],
        },
        {
            label: 'Respiratory',
            key: 'respiratory',
            conditions: [
                'Chronic Cough',
                'Shortness of Breath',
                'Bronchitis',
                'Asthma',
                'Emphysema',
                'Pneumonia',
                'Tuberculosis',
                'Sinusitis',
            ],
        },
        // Add other categories here...
    ];

    return (
        <div className="health-history-view-container">
            {loading ? (
                <p>Loading health history...</p>
            ) : (
                <div className="form-container">
                    {dateUpdated && (
                        <p>
                            Last updated: <strong>{dateUpdated}</strong>
                        </p>
                    )}
                    <h2>Health History Overview</h2>
                    <Form.Group controlId="firstName">
                        <Form.Label>First Name</Form.Label>
                        <Form.Control type="text" value={formData.firstName} readOnly />
                    </Form.Group>
                    <Form.Group controlId="lastName">
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control type="text" value={formData.lastName} readOnly />
                    </Form.Group>
                    <Form.Group controlId="phone">
                        <Form.Label>Phone</Form.Label>
                        <Form.Control type="text" value={formData.phone} readOnly />
                    </Form.Group>
                    <Form.Group controlId="dob">
                        <Form.Label>Date of Birth</Form.Label>
                        <Form.Control type="text" value={formData.dob} readOnly />
                    </Form.Group>
                    <Form.Group controlId="address">
                        <Form.Label>Address</Form.Label>
                        <Form.Control type="text" value={formData.address} readOnly />
                    </Form.Group>
                    <Form.Group controlId="occupation">
                        <Form.Label>Occupation</Form.Label>
                        <Form.Control type="text" value={formData.occupation} readOnly />
                    </Form.Group>
                    <h3>Health Conditions</h3>
                    {categories.map((category, index) => {
                        const selectedConditions = formData[category.key]?.filter(condition =>
                            category.conditions.includes(condition)
                        );
                        return selectedConditions && selectedConditions.length > 0 ? (
                            <div key={index} className="health-category">
                                <Form.Group>
                                    <Form.Label className="health-label">{category.label}</Form.Label>
                                    <div className="condition-group">
                                        {selectedConditions.map((condition, idx) => (
                                            <div key={idx} className="condition-item">
                                                <FontAwesomeIcon icon={faCheck} color="green" /> {condition}
                                            </div>
                                        ))}
                                    </div>
                                </Form.Group>
                            </div>
                        ) : null;
                    })}
                </div>
            )}
        </div>
    );
};

export default ViewHealthHistory;
