import React, { useState } from 'react';
import { Button, Form, Spinner } from 'react-bootstrap';
import VerificationCodeComp from './VerificationCodeComp'; // Reuse the component
import api from '../api';
import VisAlert from '../pages/VisAlert';

const PasswordRec = () => {
  const [step, setStep] = useState(0); // Track the current slide
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState('info');
  const [isVerified, setIsVerified] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await api.post('/api/password-reset/', { email });
      if (response.data.success) {
        setStep(1); // Move to verification step
        setAlertMessage('A confirmation code has been sent to your email.');
        setAlertType('success');
      } else {
        setAlertMessage('Email not found.');
        setAlertType('danger');
      }
    } catch (error) {
      setAlertMessage('Error checking email. Please try again.');
      setAlertType('danger');
    } finally {
      setLoading(false);
    }
  };

  const handleVerificationSuccess = () => {
    setIsVerified(true);
    setStep(2); // Move to password reset step
  };

  const handlePasswordSubmit = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      setAlertMessage('Passwords do not match.');
      setAlertType('danger');
      return;
    }
    setLoading(true);
    try {
      const response = await api.post('/api/reset-password/', {
        email,
        new_password: newPassword,
      });
      if (response.data.success) {
        setAlertMessage('Password reset successful. Redirecting to login...');
        setAlertType('success');
        setTimeout(() => {
          window.location.href = '/login'; // Redirect to login page
        }, 3000);
      } else {
        setAlertMessage('Failed to reset password.');
        setAlertType('danger');
      }
    } catch (error) {
      setAlertMessage('Error resetting password. Please try again.');
      setAlertType('danger');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
        {alertMessage && <VisAlert message={alertMessage} type={alertType} /> }
        
        <div style={{ padding: '20px' }}>

        {step === 0 && (
            <Form onSubmit={handleEmailSubmit}>
            <Form.Group controlId="email">
                <Form.Label>Email Address</Form.Label>
                <Form.Control
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your email"
                required
                />
            </Form.Group>
            <div style={{ textAlign: 'center', marginTop: '10px' }}>
                <Button type="submit" disabled={loading}>
                {loading ? <Spinner animation="border" size="sm" /> : 'Submit'}
                </Button>
            </div>
            </Form>
        )}

        {step === 1 && !isVerified && (
            <VerificationCodeComp
            email={email}
            onVerificationSuccess={handleVerificationSuccess}
            setAlertMessage={setAlertMessage}
            setAlertType={setAlertType}
            />
        )}

        {step === 2 && isVerified && (
            <Form onSubmit={handlePasswordSubmit}>
            <Form.Group controlId="newPassword">
                <Form.Label>New Password</Form.Label>
                <Form.Control
                type="password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                placeholder="Enter new password"
                required
                />
            </Form.Group>
            <Form.Group controlId="confirmPassword" style={{ marginTop: '10px' }}>
                <Form.Label>Confirm Password</Form.Label>
                <Form.Control
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                placeholder="Confirm new password"
                required
                />
            </Form.Group>
            <div style={{ textAlign: 'center', marginTop: '10px' }}>
                <Button type="submit" disabled={loading}>
                {loading ? <Spinner animation="border" size="sm" /> : 'Submit'}
                </Button>
            </div>
            </Form>
        )}
        </div>
    </>
  );
};

export default PasswordRec;
