import React, { useEffect, useState, useCallback, useMemo  } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import api from '../../api';
import '../../styles/MainSchedPage.css';
import DOMPurify from 'dompurify';
import SingleDayCal from './Calendar/SingleDayCal';
// import ConfirmOverlapModal from './ConfirmOverlapModal';
import DisplaySoap from '../Muscle/DisplaySoap';
import ApptDetailsForm from './ApptDetailsForm';
import ApptFilledForm from './ApptFilledForm';
import MainCheckoutPage from '../Checkout/MainCheckoutPage';
import ViewHealthHistory from '../HealthHistory/ViewHealthHistory';
import moment from 'moment-timezone';
import { format, isBefore, addMinutes, isSameDay, parseISO } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';
import Button from 'react-bootstrap/Button';
import VisAlert from '../VisAlert';
import ImageFetcher from './Calendar/ImageFetcher';
import { faL } from '@fortawesome/free-solid-svg-icons';


const apiUrl = import.meta.env.VITE_API_URL;

const MainSchedPage = () => {
    const { year, month } = useParams();
    const [data, setData] = useState(null);
    const [currentYear, setCurrentYear] = useState(parseInt(year));
    const [currentMonth, setCurrentMonth] = useState(month);
    const [userTimezone, setUserTimezone] = useState(localStorage.getItem('userTimezone') || 'America/New_York'); // Default to UTC if not set
    const [selectedDate, setSelectedDate] = useState(() => {
        const currentDate = new Date();
        const torontoTime = toZonedTime(currentDate, 'America/Toronto');
        return torontoTime; // Full Date object, including both date and time
    });
    const [profile, setProfile] = useState(() => {
        const savedProfile = localStorage.getItem('profile');
        return savedProfile ? JSON.parse(savedProfile) : null;
    });
    const [appointments, setAppointments] = useState([]);
    const [resourceId, setResourceId] = useState(null);
    const [checkedTherapists, setCheckedTherapists] = useState([]);
    const [isAppContentOpen, setAppContentOpen] = useState(false);
    const [appointmentData, setAppointmentData] = useState(null);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [isAppModalOpen, setAppModalOpen] = useState(false);
    const [ApptTitle, setApptTitle] = useState("Swedish Massage");
    const [ApptDuration, setApptDuration] = useState(30);
    const [ApptEmail, setApptEmail] = useState('');
    const [selectedAppointment, setSelectedAppointment] = useState(null);
    const [selectedProfId, setSelectedProfId] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [requestedUser, setRequestedUser] = useState(false);
    const [disabledBtns, setDisabledBtns] = useState(false);
    const [appointmentCache, setAppointmentCache] = useState({});
    const [alertMessage, setAlertMessage] = useState('');
    const [alertType, setAlertType] = useState('error');
    // New User Data
    const [newUserFormOpen, setNewUserFormOpen] = useState(false);
    const [newUserEmail, setNewUserEmail] = useState('');
    const [newUserPhone, setNewUserPhone] = useState('');
    const [newUserFirstName, setNewUserFirstName] = useState('');
    const [newUserLastName, setNewUserLastName] = useState('');
    const [err, setErr] = useState(null);
    const [clinicId, setClinicId] = useState(null);
    // HealthHistory
    const [openHealthHist, setOpenHealthHist] = useState(false);
    // SoapNotes
    const [openSoap, setOpenSoap] = useState(false);
    //Checkout
    const [openCheckout, setOpenCheckout] = useState(false);
    const [aptMsg, setAptMsg] = useState("");
    const [userCache, setUserCache] = useState({});
    //DisplayPage
    const [displayPage, setDisplayPage] = useState(false);
    const [isSidebarVisible, setIsSidebarVisible] = useState(true);

    const [ws, setWs] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        if (!profile) {
            const savedProfile = localStorage.getItem('profile');
            if (savedProfile) {
                setProfile(JSON.parse(savedProfile));
            }
        }
    }, [profile]);

    
    useEffect(() => {
        const cacheTTL = 1000 * 60 * 60 * 24; // e.g., 24 hours in milliseconds
    
        const pruneOldCache = () => {
            Object.keys(localStorage).forEach(key => {
                if (key.startsWith('user_')) {
                    const cachedUser = JSON.parse(localStorage.getItem(key));
                    if (Date.now() - cachedUser.timestamp > cacheTTL) {
                        localStorage.removeItem(key);
                    }
                }
            });
        };
    
        // Run pruneOldCache immediately on load
        pruneOldCache();
    
        // Set up interval to run pruneOldCache every `cacheTTL` milliseconds
        const intervalId = setInterval(pruneOldCache, cacheTTL);
    
        // Clean up the interval when the component unmounts
        return () => clearInterval(intervalId);
    }, []);
    

    
    useEffect(() => {
        const userData = localStorage.getItem('user');
        console.log('userData', userData);
        if (userData) {
            const parsedUserData = JSON.parse(userData);
            const clinId = parsedUserData.profile?.clinic_id;  // Access clinic_id instead of clinic
            setClinicId(clinId);
        }
    }, []);

    const triggerAlert = (message, type = 'warning') => {
        setAlertMessage(message);
        setAlertType(type);

        setTimeout(() => {
            setAlertMessage('');
            setAlertType('');
          }, 5000);
    };

    console.log('userTimeZone', userTimezone);
    // useEffect(() => {
    //     const socket = new WebSocket(`${apiUrl.replace(/^http/, 'ws').replace(/\/$/, '')}/ws/schedule/`);
    //     setWs(socket);

    //     socket.onopen = () => {
    //         console.log('WebSocket connection established.');
    //     };

    //     socket.onmessage = async (event) => {
    //         const data = JSON.parse(event.data);
    //         if (data.message) {
    //             const dateMatch = data.message.match(/\d{4}-\d{2}-\d{2}/);
    //             if (dateMatch) {
    //                 const appointmentDate = dateMatch[0];
    //                 setSelectedDate(appointmentDate);
    //                 await fetchAppointments(appointmentDate);
    //             }
    //         }
    //     };

    //     socket.onclose = (event) => {
    //         console.log('WebSocket connection closed:', event);
    //     };

    //     return () => {
    //         socket.close();
    //     };
    // }, []);

    // useEffect(() => {
    //     // Initialize WebSocket connection
    //     const socket = new WebSocket(`${apiUrl.replace(/^http/, 'ws').replace(/\/$/, '')}/ws/schedule/`);
    //     setWs(socket);
    
    //     socket.onopen = () => {
    //         console.log('WebSocket connection established.');
    //     };
    
    //     socket.onmessage = async (event) => {
    //         const data = JSON.parse(event.data);
    //         if (data.message) {
    //             // Extract date from the message
    //             const dateMatch = data.message.match(/\d{4}-\d{2}-\d{2}/);
    //             if (dateMatch) {
    //                 const appointmentDate = dateMatch[0];
    //                 // Only fetch appointments if the message's date matches selectedDate
    //                 if (appointmentDate === selectedDate) {
    //                     await fetchAppointments(appointmentDate);
    //                 }
    //             }
    //         }
    //     };
    
    //     socket.onclose = (event) => {
    //         console.log('WebSocket connection closed:', event);
    //     };
    
    //     // Close socket on cleanup
    //     return () => {
    //         socket.close();
    //     };
    // }, []); // Initialize WebSocket connection only once

    
    
    // const fetchAppointmentsForDate = async (date) => {
    //     const formattedDate = moment(date).tz(userTimezone).format('YYYY-MM-DD');
    //     await fetchAppointments(formattedDate);
    
    //     try {
    //         const profile = JSON.parse(localStorage.getItem('profile'));
    //         setDisplayPage(profile?.is_therapist || false);
    
    //         const response = await api.get(`/api/schedule/${currentYear}/${currentMonth}`);
    //         const currentDate = moment().tz(userTimezone).format('YYYY-MM-DD');
    //         const calendarHtml = response.data.cal.replace(
    //             new RegExp(`<td class="clickable-date" data-date="${currentDate}">`, 'g'),
    //             `<td class="clickable-date today" data-date="${currentDate}">`
    //         );
    //         setData({ ...response.data, cal: calendarHtml });
    //     } catch (error) {
    //         triggerAlert('Error fetching data. {code: 472871}', 'caution');
    //     }
    // };
    const fetchAppointments = useCallback(async (formattedDate) => {
        if (appointmentCache[formattedDate]) {
            console.log(`Using cached data for ${formattedDate}`);
            setAppointments([...appointmentCache[formattedDate]]); // Spread into a new array
            return;
        }

        try {
            const response = await api.get(`/api/schedule/appts/${formattedDate}/`);
            console.log('Fetching from API:', response.data);
    
            // Modify appointment times if needed
            const modifiedAppointments = response.data.map(appointment => {
                const startTimeUTC = toZonedTime(parseISO(appointment.start_time), 'America/Toronto');
                const endTimeUTC = toZonedTime(parseISO(appointment.end_time), 'America/Toronto');
            
                return {
                    ...appointment,
                    start_time: format(startTimeUTC, "yyyy-MM-dd'T'HH:mm:ss"),
                    end_time: format(endTimeUTC, "yyyy-MM-dd'T'HH:mm:ss")
                };
            });

            console.log('modified', modifiedAppointments);
            // Update the cache with fresh data
            setAppointmentCache(prevCache => {
                const newCache = { ...prevCache };
                delete newCache[formattedDate]; // Clear cache for the date
                return newCache;
            });
    
            // Update the state with fresh data
            setAppointments([...modifiedAppointments]);
        } catch (error) {
            console.error('Error fetching appointments:', error);
        }
    }, []);
    
    useEffect(() => {
        // Initialize WebSocket connection
        const socket = new WebSocket(`${apiUrl.replace(/^http/, 'ws').replace(/\/$/, '')}/ws/schedule/`);
        setWs(socket);
    
        socket.onopen = () => {
            console.log('WebSocket connection established.');
        };
    
        socket.onmessage = async (event) => {
            const data = JSON.parse(event.data);
            if (data.message) {
                // Extract date from the message
                const dateMatch = data.message.match(/\d{4}-\d{2}-\d{2}/);
                if (dateMatch) {
                    const appointmentDate = dateMatch[0];
                    console.log(`Received update for date: ${appointmentDate}`);
    
                    // Only fetch appointments if the message's date matches selectedDate
                    if (appointmentDate === selectedDate) {
                        // Fetch updated appointments for the selected date
                        await fetchAppointments(appointmentDate);
    
                        // Optionally update the cache to keep it in sync
                        setAppointmentCache(prevCache => {
                            const newCache = { ...prevCache };
                            delete newCache[appointmentDate]; // Invalidate cache for the date
                            return newCache;
                        });
                    } else {
                        console.log('Update received, but not for the selected date.');
                    }
                }
            }
        };
    
        socket.onclose = (event) => {
            console.log('WebSocket connection closed:', event);
            // Optional: Implement reconnection logic here if needed
        };
    
        // Clean up the socket on component unmount
        return () => {
            socket.close();
        };
    }, [selectedDate, fetchAppointments]); 


    const fetchAppointmentsForDate = useCallback(async (date) => {
        const torontoDate = toZonedTime(date, userTimezone);
        const formattedDate = format(torontoDate, 'yyyy-MM-dd');
        await fetchAppointments(formattedDate);
    
        try {
            //const profile = JSON.parse(localStorage.getItem('profile'));
            setDisplayPage(profile?.is_therapist || false);
    
            const response = await api.get(`/api/schedule/${currentYear}/${currentMonth}`);
            const currentDate = moment().tz(userTimezone).format('YYYY-MM-DD');
    
            // Remove previous selected date styling
            let calendarHtml = response.data.cal.replace(
                /<td class="clickable-date selected-date" data-date="[^"]+">/g,
                '<td class="clickable-date" data-date="$1">'
            );
    
            // Apply 'today' class to the current date
            calendarHtml = calendarHtml.replace(
                new RegExp(`<td class="clickable-date" data-date="${currentDate}">`, 'g'),
                `<td class="clickable-date today" data-date="${currentDate}">`
            );
    
            // Apply 'selected-date' class to the selected date
            calendarHtml = calendarHtml.replace(
                new RegExp(`<td class="clickable-date" data-date="${formattedDate}">`, 'g'),
                `<td class="clickable-date selected-date" data-date="${formattedDate}">`
            );
    
            setData({ ...response.data, cal: calendarHtml });
        } catch (error) {
            triggerAlert('Error fetching data. {code: 472871}', 'caution');
        }
    }, [fetchAppointments, currentYear, currentMonth, userTimezone, profile]);
    
    useEffect(() => {
        console.log("Updated selectedDate in MainSchedPage:", selectedDate);
        console.log('isAppContentOpen', isAppContentOpen);
    }, [selectedDate, isAppContentOpen]);
    
    useEffect(() => {
        fetchAppointmentsForDate(selectedDate);
    }, [selectedDate, currentYear, currentMonth]);
    
    useEffect(() => {
        if (openSoap) {
            setOpenCheckout(false); // Close checkout when soap is opened
            setOpenHealthHist(false);
        } 
        if (openCheckout) {
            setOpenSoap(false);
            setOpenHealthHist(false);
        }
        if (openHealthHist) {
            setOpenSoap(false);
            setOpenCheckout(false);
        }
    }, [openSoap, openCheckout, openHealthHist]);

    useEffect(() => {
        if (data) {
            const dates = document.querySelectorAll('.clickable-date');
            dates.forEach(date => {
                date.addEventListener('click', handleDateClick);
            });

            return () => {
                dates.forEach(date => {
                    date.removeEventListener('click', handleDateClick);
                });
            };
        }
    }, [data]);

    const handleDateClick = async (event) => {
        const clickedDate = event.target.getAttribute('data-date');
        const formattedDate = format(parseISO(clickedDate), 'yyyy-MM-dd');
        setSelectedDate(formattedDate);
        await fetchAppointments(clickedDate);

        setAppContentOpen(false);
        setAppModalOpen(false);
        setAppointmentData(null);
        setOpenSoap(false);
        setOpenCheckout(false);
        setOpenHealthHist(false);
    };

    
    if (!data) {
        return <div>Loading...</div>;
    }

    const fetchUsers = async (query) => {
        try {
            const response = await api.get(`/api/user/search/?q=${query}`);
            setSearchResults(response.data);
        } catch (error) {
            triggerAlert('There was an error retrieving user information from the system, contact admin {code: 472981}', 'caution');
        }
    };

    const prevBtnAction = () => {
        let date = new Date(`${currentYear}-${currentMonth}-01`);
        date.setMonth(date.getMonth() - 1);
        let newYear = date.getFullYear();
        let newMonth = date.toLocaleString('default', { month: 'long' });
        setCurrentYear(newYear);
        setCurrentMonth(newMonth);
        navigate(`/schedule/${newYear}/${newMonth}`);
    };

    const nextBtnAction = () => {
        let date = new Date(`${currentYear}-${currentMonth}-01`);
        date.setMonth(date.getMonth() + 1);
        let newYear = date.getFullYear();
        let newMonth = date.toLocaleString('default', { month: 'long' });
        setCurrentYear(newYear);
        setCurrentMonth(newMonth);
        navigate(`/schedule/${newYear}/${newMonth}`);
    };
    
    const handleUserSelect = (user) => {
        setAptMsg(user.apt_msg);
        setSelectedUser(user);
        setApptEmail(user.email);
        setSearchQuery('');
        setSearchResults([]);
        setDisabledBtns(false);
    };


    const handleCancel = () => {
        setOpenCheckout(false);
        setOpenSoap(false);
        setAppModalOpen(false);
        setAppContentOpen(false);
        setAppointmentData(null);
        setSelectedAppointment(null);
        setShowConfirmationModal(false);
    } 

    const handleSaveAppointment = async (appointmentData) => {
        console.log('Saving appointment:', appointmentData);
        let startTime = moment(selectedDate).tz(userTimezone);
        console.log('startTime1', startTime);
        const endTime = startTime.clone().add(appointmentData.duration, 'minutes');

        try {
            console.log('selectedUser.id is ', selectedUser);
            const currentUserProfData = await api.get(`/api/profile/${selectedUser.user_id}`);
            console.log('currentUserProfData is ', currentUserProfData.data);
    
            const { first_name, last_name } = currentUserProfData.data;
            const usernamee = `${first_name}, ${last_name}`;  
            const therapistResp = await api.get(`/api/profile/${resourceId}/`);
            const therapistRespFirstName = therapistResp.data.first_name;
            const therapistRespLastName = therapistResp.data.last_name;
            const therapistName = `${therapistRespFirstName}, ${therapistRespLastName}`; 
            console.log('therapistName', therapistName);
    
            const startTimeFormatted = startTime.format('YYYY-MM-DDTHH:mm:ss');
            const endTimeFormatted = endTime.format('YYYY-MM-DDTHH:mm:ss');
            const dateFormat = moment(appointmentData.startTime).format('YYYY-MM-DD');
            console.log('dateFormat is', dateFormat);
            console.log('resourceId is', resourceId);
    
            // Check for overlapping appointments
            const existingAppt = await api.get(`/api/therapists/${resourceId}/availability/${dateFormat}/${appointmentData.duration}/`);
            console.log('Existing appointments:', existingAppt.data);
    
            const overlap = existingAppt.data.some(appt => {
                const apptStart = moment(`${dateFormat}T${appt}:00`).tz(userTimezone);
                const apptEnd = apptStart.clone().add(appointmentData.duration, 'minutes');
                console.log('Comparing with existing appointment:', { apptStart: apptStart.format('YYYY-MM-DDTHH:mm:ss'), apptEnd: apptEnd.format('YYYY-MM-DDTHH:mm:ss') });
                return (startTime.isBetween(apptStart, apptEnd, null, '[)') || endTime.isBetween(apptStart, apptEnd, null, '(]') || apptStart.isBetween(startTime, endTime));
            });
    
            // if (overlap) {
            //     setAppointmentData(appointmentData);
            //     setShowConfirmationModal(true);
            //     return;
            // }
    
            // Save appointment and update state
            await saveAppointmentToDB(appointmentData, selectedUser.id, usernamee, therapistName, startTime, endTime);
        } catch (error) {
            triggerAlert('Whoopsie, we had a problem saving this appointment. {code: 472581}', 'caution');
        }
    };
    
    const saveAppointmentToDB = async (appointmentData, currentUserId, username, therapistName, startTime, endTime) => {
        console.log('therapistName', therapistName);
        console.log('startTime2', startTime);
        console.log('apt', appointmentData);
        const torontoTz = 'America/Toronto';
    
        // Format start and end times in Toronto timezone
        // Extract the date from appointmentData.startTime
        const appointmentDate = moment(appointmentData.startTime).format('YYYY-MM-DD');
        const startTimeLocal = moment.tz(`${appointmentDate}T${startTime.format('HH:mm:ss')}`, torontoTz);
        const endTimeLocal = startTimeLocal.clone().add(appointmentData.duration, 'minutes');

        console.log('startTime3', startTimeLocal);

        const startTimeFormatted = startTimeLocal.format('YYYY-MM-DDTHH:mm:ss');
        const endTimeFormatted = endTimeLocal.format('YYYY-MM-DDTHH:mm:ss');
    
        console.log('Toronto Start Time:', startTimeFormatted);
        console.log('Toronto End Time:', endTimeFormatted);

        try {
            await api.post('/api/schedule/appointments/add/', {
                user: currentUserId,
                teacher: resourceId,
                therapist: resourceId,
                therapist_name: therapistName,
                start_time: startTimeFormatted,
                end_time: endTimeFormatted,
                title: appointmentData.title,
                date: moment(appointmentData.startTime).format('YYYY-MM-DD'),
                username: username,
                user_email: appointmentData.email,
                appt_length: appointmentData.duration,
                is_therapist_req: appointmentData.is_therapist_req,
                is_paid: false,
                clinic: clinicId,
                apt_msg: appointmentData.apt_msg,
            });
    
            // Update local state with the new appointment
            setAppointments(prevAppointments => [
                ...prevAppointments,
                {
                    user: currentUserId,
                    start_time: startTimeFormatted,
                    end_time: endTimeFormatted,
                    title: appointmentData.title,
                    username: username,
                    duration: appointmentData.duration,
                    apt_msg: appointmentData.apt_msg,
                }
            ]);
    
            // Clear the cache for the current date
            const formattedDate = moment(appointmentData.startTime).format('YYYY-MM-DD');

            setAppointmentCache(prevCache => {
                const newCache = { ...prevCache };
                delete newCache[formattedDate]; // Remove the cached data for the current date
                return newCache;
            });
    
            // Fetch updated appointments
            await fetchAppointments(formattedDate);
    
            setApptTitle("Swedish Massage");
            setApptDuration(30);
            setApptEmail('');
    
            // Close AppModal
            setAppModalOpen(false);
            setAppContentOpen(false);
            setAppointmentData(null);
            setShowConfirmationModal(false);
        } catch (error) {
            triggerAlert('Whoopsie, an error occured while saving appointment to database. {code: 472881}', 'caution');
        }
    };
    


        
    //   const handleConfirm = async () => {
    //     setShowConfirmationModal(false);
    //     if (appointmentData) {
    //         const startTime = moment(selectedDate).tz('America/Toronto');
    //         const endTime = startTime.clone().add(appointmentData.duration, 'minutes');
    //         try {
    //             const currentUserResponse = await api.get('/api/user/current/');
    //             const currentUserId = currentUserResponse.data.id;
    //             const username = currentUserResponse.data.username;
    //             const therapistResp = await api.get(`/api/user/${resourceId}/`);
    //             const therapistName = therapistResp.data.username;
    //             await saveAppointmentToDB(appointmentData, currentUserId, username, therapistName, startTime, endTime);
    //         } catch (error) {
    //             console.error('Error confirming appointment:', error);
    //         }
    //     }
    // };

    const handleClose = () => {
        setShowConfirmationModal(false);
    };

    const handleCancelAppointment = async (id) => {
        try {
            const resp = await api.get(`/api/appointments/${id}/`);
            const usr = JSON.parse(localStorage.getItem('user'));
            const fullname = `${usr.profile.first_name}, ${usr.profile.last_name} `
            const startTime = new Date(resp.data.start_time);
            const endTime = new Date(resp.data.end_time);
            const durationInMillis = endTime - startTime;
            const durationInMinutes = durationInMillis / (1000 * 60);


            const newRemovedAppointment = {
                removing_therapist_id: usr.id, // Example ID
                removing_therapist_name: fullname,
                app_name: `${durationInMinutes} minute ${resp.data.title}`,
                assigned_therapist_id: resp.data.therapist, // Example ID
                assigned_therapist_name: resp.data.therapist_name,
                patient_name: resp.data.username,
                appointment_date_time: moment(new Date()).tz(userTimezone).format("YYYY-MM-DDTHH:mm:ss").toString(), // Example date-time
                clinic: clinicId
            };

            await api.post('/api/removed-appointments/add/', newRemovedAppointment)
            await api.delete(`/api/schedule/appointments/delete/${id}/`);
            localStorage.removeItem('selAppt');
            localStorage.removeItem('selUsr');
            setAppointments((prevAppointments) =>
                prevAppointments.filter((appointment) => appointment.id !== id)
            );
            setAppModalOpen(false);
            setAppContentOpen(false);
            setAppointmentData(null);
            triggerAlert('Appointment deleted successfully', 'success');
            // Log before fetching appointments
            console.log(`Appointment with ID ${id} deleted. Fetching updated appointments for ${selectedDate}`);
            const formattedDate = moment(selectedDate).format('YYYY-MM-DD');
            await fetchAppointments(formattedDate);
        } catch (error) {
            triggerAlert('Whoopsie! We could not cancel your appointment. Contact administrator. {code: 472991}', 'caution');
        }
    };

    const formatName = (name) => {
        const lower = name.toLowerCase();
        return lower.charAt(0).toUpperCase() + lower.slice(1);
    };

    const handleCreateNewUser = async (e) => {
        e.preventDefault();
        
        try {
            // Check if the email or phone already exists
            const response = await api.get('/api/check-email-phone/', {
                params: {
                    email: newUserEmail,
                    phone: newUserPhone
                },
            });
            console.log('response is', response.data);
            if (response.data.exists) {
                // Display an error message based on the field
                if (response.data.field === 'email') {
                    setErr('Email address already exists.');
                } else if (response.data.field === 'phone') {
                    setErr('Phone number already exists.');
                }
                return;
            }

            const fname = formatName(newUserFirstName);
            const lname = formatName(newUserLastName);

            // If no error, proceed to create the user
            await api.post('api/user/register-sched/', {
                username: newUserEmail,
                profile: {
                    email: newUserEmail,
                    phone: newUserPhone,
                    first_name: fname,
                    last_name: lname,
                    first_login: 0,
                    clinic: clinicId
                }
            });
    
            let startTime = moment(selectedDate).tz(userTimezone);
            const formattedDate = moment(startTime).format('YYYY-MM-DD');
            await fetchAppointments(formattedDate);
            // Reset form and close the new user form
            setNewUserEmail('');
            setNewUserPhone('');
            setNewUserFirstName('');
            setNewUserLastName('');
            setNewUserFormOpen(false);
            setErr('');
            
        } catch (error) {
            triggerAlert('Apologies! System error - We could not create a new user. Contact administrator. {code: 472471}', 'caution');
            setErr('An error occurred while creating the user.');
        }
    };


    const handleUpdateAppointment = async (updatedAppointment) => {
        try {
            const startTimeFormatted = format(parseISO(updatedAppointment.start_time), "yyyy-MM-dd'T'HH:mm:ss");
            const endTimeFormatted = format(parseISO(updatedAppointment.end_time), "yyyy-MM-dd'T'HH:mm:ss");
            
            console.log('MainSchedPage - aptMsg before updating appointment:', aptMsg); 
            console.log('startFormmed', startTimeFormatted);

            const updatedAppointmentData = {
                id: updatedAppointment.id,
                therapist: updatedAppointment.therapist,
                start_time: startTimeFormatted,
                end_time: endTimeFormatted,
                title: updatedAppointment.title,
                date: moment(updatedAppointment.start_time).format('YYYY-MM-DD'),
                username: `${selectedUser.first_name}, ${selectedUser.last_name}`,
                user_email: updatedAppointment.user_email,
                appt_length: updatedAppointment.appt_length,
                user: updatedAppointment.user,
                clinic: clinicId,
                apt_msg: aptMsg,
            };
            
            console.log('updatedApptDat', updatedAppointmentData);
            await api.put(`/api/schedule/appointments/${updatedAppointment.id}/`, updatedAppointmentData);
    
            const userId = updatedAppointment.user; // Assuming `updatedAppointment.user` gives the user ID
            const newAptMsg = updatedAppointment.apt_msg;
            setUserCache(prevCache => {
                if (prevCache[userId]) {
                    return {
                        ...prevCache,
                        [userId]: {
                            ...prevCache[userId],
                            apt_msg: newAptMsg
                        }
                    };
                }
                return prevCache;
            });

            setAppointments(prevAppointments =>
                prevAppointments.map(appointment =>
                    appointment.id === updatedAppointment.id ? updatedAppointmentData : appointment
                )
            );
    
            const updatedDate = format(parseISO(updatedAppointment.start_time), 'yyyy-MM-dd');
            setAppointmentCache(prevCache => {
                const newCache = { ...prevCache };
                delete newCache[updatedDate];
                return newCache;
            });
    
            await fetchAppointments(updatedDate);
            triggerAlert('Appointment updated successfully', 'success');
            setAppContentOpen(false);
            setSelectedAppointment(null);
            setAppointmentData(null);
            setShowConfirmationModal(false);
        } catch (error) {
            triggerAlert('Whoopsie! We could not update this appointment. Contact administrator. {code: 472671}', 'caution');
        }
    };

    

    const handleOpenNewUserForm = () => {
        setNewUserFormOpen(true);
    };

    const handleCloseNewUserForm = () => {
        setNewUserFormOpen(false);
        setNewUserEmail('');
        setNewUserPhone('');
        setNewUserFirstName('');
        setNewUserLastName('');
    };

    const handleRedirectToDisplaySoap = (appointment) => {
        setSelectedAppointment(appointment);
        setOpenSoap(true);
    };


    if (!displayPage) {
        return <div>Sorry, you are not authorized to see this page.</div>;
    }
    
    //console.log('appointments', displayPage);

    const NewPatientIcon = React.memo(() => (
        <ImageFetcher
            imageUrl="https://res.cloudinary.com/dhdn6hovg/image/upload/v1729429849/star_cdacaw.png"
            alt="New User"
            style={{ width: '25px', height: '35px' }}
        />
    ));
    
    const DollarSignIcon = React.memo(() => (
        <ImageFetcher
            imageUrl="https://res.cloudinary.com/dhdn6hovg/image/upload/v1729429821/dollar-sign-CuG63aXK_jsyfve.png"
            alt="Dollar sign"
            style={{ width: '20px', height: '20px' }}
        />
    ));
    
    const TherapistReqIcon = React.memo(() => (
        <ImageFetcher
            imageUrl="https://res.cloudinary.com/dhdn6hovg/image/upload/v1729429822/green-circle_elke2b.png"
            alt="Req Therapist"
            style={{ width: '25px', height: '20px' }}
        />
    ));

    NewPatientIcon.displayName = "NewPatientIcon";
    DollarSignIcon.displayName = "DollarSignIcon";
    TherapistReqIcon.displayName = "TherapistReqIcon";


    return (
        <div className="main_sched_page">
            
            {alertMessage && <VisAlert message={alertMessage} type={alertType} />}
            {/* Toggle Button for Mobile View */}
            <button 
                className="toggle-sidebar-btn" 
                onClick={() => setIsSidebarVisible(!isSidebarVisible)}
                >
                {isSidebarVisible ? 'Hide Menu' : 'Show Menu'}
            </button>

        
            <div className={`sidebar ${isSidebarVisible ? 'visible' : 'hidden'}`}>
                <div className="calendar_container">
                    <button className="prev_btn" onClick={prevBtnAction}>&lt;</button>
                    <div className="main_sched" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data.cal) }} />
                    <button className="next_btn" onClick={nextBtnAction}>&gt;</button>
                </div>
                 {/* Conditional rendering of container based on isAppModalOpen state */}
                 {isAppModalOpen && resourceId && (
                    <ApptDetailsForm
                        ApptTitle={ApptTitle}
                        setApptTitle={setApptTitle}
                        ApptDuration={ApptDuration}
                        setApptDuration={setApptDuration}
                        ApptEmail={ApptEmail}
                        setApptEmail={setApptEmail}
                        selectedDate={selectedDate}
                        handleSaveAppointment={handleSaveAppointment}
                        handleCancel={handleCancel}
                        searchQuery={searchQuery}
                        setSearchQuery={setSearchQuery}
                        searchResults={searchResults}
                        fetchUsers={fetchUsers}
                        handleUserSelect={handleUserSelect}
                        selectedUser={selectedUser}
                        handleCreateNewUser={handleCreateNewUser}
                        newUserFormOpen={newUserFormOpen}
                        handleOpenNewUserForm={handleOpenNewUserForm}
                        handleCloseNewUserForm={handleCloseNewUserForm}
                        newUserEmail={newUserEmail}
                        setNewUserEmail={setNewUserEmail}
                        newUserPhone={newUserPhone}
                        setNewUserPhone={setNewUserPhone}
                        newUserFirstName={newUserFirstName}
                        setNewUserFirstName={setNewUserFirstName}
                        newUserLastName={newUserLastName}
                        setNewUserLastName={setNewUserLastName}
                        resourceId={resourceId}
                        err={err}
                        setOpenSoap={setOpenSoap}
                        requestedUser={requestedUser}
                        setRequestedUser={setRequestedUser}
                        openCheckout={openCheckout}
                        disabledButtons={disabledBtns}
                        aptMsg={aptMsg}
                        setAptMsg={setAptMsg}
                        fetchAppointments={fetchAppointments}
    />
                )}
                {isAppContentOpen && selectedAppointment && (
                    <>
                      {console.log("Rendering ApptFilledForm with appointment:", selectedAppointment)}
                    <ApptFilledForm 
                        key={selectedAppointment ? selectedAppointment.id : 'new-appointment'}
                        isOpen={isAppContentOpen}
                        appointment={selectedAppointment}
                        onCancel={handleCancel}
                        onSave={handleUpdateAppointment} // Use handleUpdateAppointment from MainSchedPage
                        onClose={() => setSelectedAppointment(null)}
                        onDelete={handleCancelAppointment}
                        selectedUser={selectedUser}
                        setOpenSoap={setOpenSoap}
                        setSelectedAppointment={setSelectedAppointment}
                        setSelectedProfId={setSelectedProfId}
                        openSoap={openSoap}
                        setOpenCheckout={setOpenCheckout}
                        openCheckout={openCheckout}
                        disabledButtons={disabledBtns}
                        clinicId={clinicId}
                        aptMsg={aptMsg}
                        setAptMsg={setAptMsg}
                        fetchAppointments={fetchAppointments}
                        openHealthHist={openHealthHist}
                        setOpenHealthHist={setOpenHealthHist}
                        setAppointments={setAppointments}
                        setAppModalOpen={setAppModalOpen}
                        setAlertMessage={setAlertMessage}
                        setAlertType={setAlertType}
                />  
                </>
                )}
            </div>

            <div className="content">
                {openSoap && selectedAppointment ? (
                    <DisplaySoap clientId={selectedAppointment.user} appointmentId={selectedAppointment.id} />
                ) : openCheckout && selectedAppointment ? (
                    <MainCheckoutPage appointment={selectedAppointment} />
                ) : openHealthHist && selectedAppointment ? (
                    <ViewHealthHistory prof_id={selectedProfId} />
                ) : (
                    <SingleDayCal className="single-day-cal"
                        selectedDate={selectedDate}
                        setSelectedDate={setSelectedDate}
                        appointments={appointments}
                        setAppointments={setAppointments}
                        fetchAppointments={fetchAppointments}
                        isPastDate={moment(selectedDate).isBefore(moment().tz('America/Toronto'), 'day')}
                        isAppModalOpen={isAppModalOpen}
                        setAppModalOpen={setAppModalOpen}
                        isAppContentOpen={isAppContentOpen}
                        setAppContentOpen={setAppContentOpen}
                        onResourceId={setResourceId}
                        setSelectedAppointment={setSelectedAppointment}
                        selectedAppointment={selectedAppointment} 
                        setSelectedUser={setSelectedUser} 
                        setDisabledBtns={setDisabledBtns}
                        triggerAlert={triggerAlert}
                        NewPatientIcon={NewPatientIcon}
                        DollarSignIcon={DollarSignIcon}
                        TherapistReqIcon={TherapistReqIcon}
                        userCache={userCache}
                        setUserCache={setUserCache}
                    />
                )}
        </div>
            {/* <ConfirmOverlapModal
                show={showConfirmationModal}
                onClose={handleClose}
                onConfirm={handleConfirm}
            /> */}
        </div>
    );
};

export default MainSchedPage;
