import React, { useState, useEffect } from 'react';
import api from '../../api'; // Import your API utility
import dollarImg from '../../assets/dollar.png';
import '../../styles/AddItemCheckout.css'

const AddItem = ({ appointment, formattedDate, onAddItem, cartItems }) => {
    const [apptData, setApptData] = useState([]);
    const [rates, setRates] = useState({});
    const [addedItems, setAddedItems] = useState(new Set()); // Track added items

    const firstName = appointment.username.split(',')[0];

    useEffect(() => {
        const fetchAppointments = async () => {
            try {
                const resp = await api.get(`/api/appointments/${formattedDate}/${appointment.username}/`);
                setApptData(resp.data);
            } catch (error) {
                console.log('Error fetching appointment data:', error);
            }
        };

        fetchAppointments();
    }, [formattedDate, appointment.username]);

    useEffect(() => {
        const fetchRates = async () => {
            const fetchedRates = {};
            for (const appt of apptData) {
                const startDate = new Date(appt.start_time);
                const endDate = new Date(appt.end_time);
                const differenceInMinutes = Math.floor((endDate - startDate) / (1000 * 60));

                try {
                    const response = await api.get(`/api/service-rate/${appt.title}/${differenceInMinutes}/`);
                    fetchedRates[`${appt.title}-${differenceInMinutes}`] = response.data.rate;
                } catch (error) {
                    console.log(`Error fetching rate for ${appt.title} with duration ${differenceInMinutes}:`, error);
                }
            }
            setRates(fetchedRates);
        };

        if (apptData.length > 0) {
            fetchRates();
        }
    }, [apptData]);

    // Ensure addedItems is updated correctly when cartItems changes
    useEffect(() => {
        const updatedAddedItems = new Set();
        cartItems.forEach(cartItem => {
            updatedAddedItems.add(`${cartItem.title}-${cartItem.start_time}-${cartItem.end_time}`);
        });
        setAddedItems(updatedAddedItems);
    }, [cartItems]);

    const handleQtyChange = (index, qty) => {
        const newApptData = [...apptData];
        newApptData[index] = {
            ...newApptData[index],
            qty: qty
        };
        setApptData(newApptData);
    };

    const handleDiscountChange = (index, value) => {
        const newApptData = [...apptData];
        newApptData[index] = {
            ...newApptData[index],
            discount: parseFloat(value) || 0.0
        };
        setApptData(newApptData);
    };

    const handleDiscountTypeChange = (index, value) => {
        const newApptData = [...apptData];
        newApptData[index] = {
            ...newApptData[index],
            discountType: value
        };
        setApptData(newApptData);
    };

    const handleAddItem = (index) => {
        const item = {
            ...apptData[index],
            rate: rates[`${apptData[index].title}-${Math.floor((new Date(apptData[index].end_time) - new Date(apptData[index].start_time)) / (1000 * 60))}`],
            discountType: apptData[index].discountType || 'Percent'
        };

        if (onAddItem) {
            console.log('Adding item:', item); // Debugging
            onAddItem(item);
            setAddedItems(prev => new Set(prev.add(`${item.title}-${item.start_time}-${item.end_time}`)));
        }
    };

    const isDisabled = (index) => {
        const appt = apptData[index];
        const itemKey = `${appt.title}-${appt.start_time}-${appt.end_time}`;
        
        // Check if the appointment is paid
        if (appt.is_paid) {
            return true; // Disable the button if appointment is paid
        }
        
        // Check if the item is already in the addedItems set
        return addedItems.has(itemKey);
    };

    // Calculate the count of paid and total appointments
    const paidCount = apptData.filter(appt => appt.is_paid).length;
    const totalCount = apptData.length;

    return (
        <div>
            <h4><strong>Add Item</strong></h4>
            <div className="item-header">
                <div className="service-title">Services for {firstName}</div>
                <div className="total-cnt">({paidCount} Paid / {totalCount} Total)</div>
                <div className="date">on {formattedDate}</div>
            </div>
            <div className="item-body">
                {apptData.map((appt, index) => {
                    const startDate = new Date(appt.start_time);
                    const endDate = new Date(appt.end_time);
                    const differenceInMinutes = Math.floor((endDate - startDate) / (1000 * 60));
                    const therapistName = appt.therapist_name.split(',')[0].trim();
                    const rateKey = `${appt.title}-${differenceInMinutes}`;
                    const rate = rates[rateKey] || 'N/A';
    
                    return (
                        <div key={index} className="appointment-wrapper">
                            {appt.is_paid && (
                                <div className="dollar-sign-container">
                                    <img src={dollarImg} alt="Dollar Sign" style={{ width: '24px', height: '24px' }} />
                                </div>
                            )}
                            <div className="appointment-item">
                                <div className="appointment-details">
                                <strong className={appt.is_paid ? 'paid' : ''}>
                                    {differenceInMinutes} min {appt.title} with {therapistName}
                                </strong>
                                    <div className="appointment-content">
                                        Price: $<input style={{width: '80px'}} type="number" value={rate} readOnly /> &nbsp;
                                        Qty: 
                                        <input 
                                            type="number" 
                                            value={appt.qty || 1} 
                                            onChange={(e) => handleQtyChange(index, e.target.value)} 
                                            style={{width: '60px'}} 
                                        /> &nbsp;
                                        Discount:
                                        <div className="discount-container">
                                            <input 
                                                type="number" 
                                                step="0.01" 
                                                value={appt.discount || 0.0} 
                                                min="0"
                                                onChange={(e) => handleDiscountChange(index, e.target.value)} 
                                                style={{width: '80px'}} 
                                            />
                                            <select
                                                value={appt.discountType || 'Percent'}
                                                onChange={(e) => handleDiscountTypeChange(index, e.target.value)}
                                            >
                                                <option value="Percent">Percent</option>
                                                <option value="Amount">Amount</option>
                                            </select>
                                        </div>
                                        <button 
                                            className="appt-btn" 
                                            onClick={() => handleAddItem(index)} 
                                            disabled={isDisabled(index) || appt.is_paid} // Disable if item is already added or if appt.is_paid
                                        >
                                            Add Item
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default AddItem;