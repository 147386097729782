import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import { Calendar, dateFnsLocalizer } from 'react-big-calendar';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'
import moment from 'moment-timezone';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { format, isBefore, addMinutes, isSameDay, parseISO, parse, set } from 'date-fns';
import { toZonedTime, toDate } from 'date-fns-tz';
import enUS from 'date-fns/locale/en-US';
import CalCustomTb from './CalCustomTb';
import AppModal from './AppModal';
import Modal from 'react-modal';
import api from '../../../api';
import AppDetailModal from './AppDetailModal';
import TherapistSelFilter from './TherapistSelFilter';
import '../../../styles/SingleDayCal.css';
import starImage from '../../../assets/star.png';
import greenCircle from '../../../assets/green-circle.png';
import ContextMenu from './ContextMenu';
import dollarSign from '../../../assets/dollar-sign.png';
import useFetchAvailability from './useFetchAvailability';
import ImageFetcher from './ImageFetcher';

const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek: () => 1,  // Start week on Monday
    getDay: (date) => date.getDay(),
    locales: { 'en-US': enUS },
  });

const DnDCalendar = withDragAndDrop(Calendar)

const SingleDayCal = ({ selectedDate, setSelectedDate, appointments, setAppointments, isPastDate, fetchAppointments, isAppModalOpen, setAppModalOpen, isAppContentOpen, setAppContentOpen, onResourceId, setSelectedAppointment, selectedAppointment, setSelectedUser, setDisabledBtns, triggerAlert, NewPatientIcon, DollarSignIcon, TherapistReqIcon, userCache, setUserCache}) => {
    const [prevAppointmentState, setPrevAppointmentState] = useState({});
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [title, setTitle] = useState('Swedish Massage');
    //const [resources, setResources] = useState([]);
    const [selectedResourceId, setSelectedResourceId] = useState(null);
    const [isModalAppOpen, setIsModalAppOpen] = useState(false);
    const [currentTime, setCurrentTime] = useState(new Date());
    const [email, setEmail] = useState();
    const [draggedAppointment, setDraggedAppointment] = useState(null);
    //const [therapistAvailability, setTherapistAvailability] = useState({});
    //const [noTherapistsMessage, setNoTherapistsMessage] = useState(false);
    const [checkedTherapists, setCheckedTherapists] = useState([]);
    const [contextMenu, setContextMenu] = useState({ visible: false, x: 0, y: 0, appointment: null });
    const { resources, specificShifts, therapistAvailability, noTherapistsMessage } = useFetchAvailability(selectedDate);
    //const [userCache, setUserCache] = useState({});
    // const [hoveredAppointment, setHoveredAppointment] = useState(null);
    // const [hoverPosition, setHoverPosition] = useState({ top: 0, left: 0 });
    // const [showHoverContainer, setShowHoverContainer] = useState(false);


    Modal.setAppElement('#root');


    useEffect(() => {
        const interval = setInterval(() => {
          setCurrentTime(new Date());
        }, 60000); // Update the time every minute
    
        return () => clearInterval(interval);
      }, []);

    useEffect(() => {
        // Example: Initialize checkedTherapists based on fetched resources
        if (resources.length > 0) {
            const initialChecked = resources.map(resource => resource.resourceId); // Adjust as needed
            setCheckedTherapists(initialChecked);
        }
    }, [resources]);


    const isWithinWorkingHours = (therapistId, date) => {
        const hours = therapistAvailability[therapistId];
        if (!hours) return false;
    
        // Convert date to the desired timezone manually
        const options = { timeZone: 'America/Toronto', hour: '2-digit', hour12: false };
        const hour = new Intl.DateTimeFormat('en-US', options).format(date);
    
        return hour >= hours.start && hour < hours.end;
    };

    const slotPropGetter = (date, resourceId) => {
        if (isWithinWorkingHours(resourceId, date)) {
            return {
                style: {
                    backgroundColor: '#dcdcdc', // light gray for working hours
                }
            };
        } else {
            return {
                style: {
                    backgroundColor: '#f0f8ff', 
                }
            };
        }
    };

    const openModal = () => {
        setModalIsOpen(true);
        setTitle('Swedish Massage');
    };

    const closeModal = () => {
        setModalIsOpen(false);
        setTitle('');
    };

    // const fetchUserById = async (userId) => {
    //     try {
    //         const response = await api.get(`api/user/findobj/${userId}`); // Adjust API endpoint as necessary
    
    //         // Log the response to inspect its content
    //         console.log('Raw response text:', response.data);
    
    //         return response.data
    //         // Attempt to parse the response
    //         //const userData = JSON.parse(textResponse);
    
    //         //return userData;
    //     } catch (error) {
    //         triggerAlert('Error retrieving user data. Please contact admin {code: 471276}', 'caution');
    //         return null;
    //     }
    // };

    const fetchUserById = async (userId) => {
        // Check in-memory cache first
        if (userCache[userId]) {
            console.log(`Using in-memory cache for user ID: ${userId}`);
            return userCache[userId];
        }
    
        // Check localStorage
        const cachedUser = localStorage.getItem(`user_${userId}`);
        if (cachedUser) {
            console.log(`Using localStorage cache for user ID: ${userId}`);
            const userData = JSON.parse(cachedUser);
            // Update in-memory cache
            setUserCache(prevCache => ({ ...prevCache, [userId]: userData }));
            return userData;
        }
    
        // Fetch from API as a last resort
        try {
            const response = await api.get(`/api/user/findobj/${userId}`);
            console.log('Fetched from API:', response.data);
    
            // Store in in-memory cache and localStorage
            setUserCache(prevCache => ({ ...prevCache, [userId]: response.data }));
            localStorage.setItem(
                `user_${userId}`, 
                JSON.stringify({
                    ...response.data,
                    timestamp: Date.now() // Add current time in milliseconds
                })
            );
    
            return response.data;
        } catch (error) {
            triggerAlert('Error retrieving user data. Please contact admin {code: 471276}', 'caution');
            return null;
        }
    };
    

    // when a user selects an appointment - Modal
    // const handleSelectEvent = async (appointment) => {
    //     // Clear any previous data immediately
    //     console.log("Clearing previous selection.");
    //     setSelectedAppointment(null);
    //     setSelectedUser(null);
    //     setDisabledBtns(false);
    
    //     localStorage.removeItem('selAppt');
    //     localStorage.removeItem('selUsr');
    
    //     const eventStart = new Date(appointment.start);
    //     console.log("Event start time:", eventStart);
    
    //     // Check if appointment is paid
    //     console.log("Appointment is_paid status:", appointment.is_paid);
    //     if (appointment.is_paid) {
    //         setDisabledBtns(true);
    //     }
    
    //     // Check working hours before proceeding
    //     const withinWorkingHours = isWithinWorkingHours(appointment.resourceId, eventStart);
    //     console.log("Within working hours:", withinWorkingHours);
    //     if (!withinWorkingHours) {
    //         triggerAlert('This event is outside of the working hours.', 'warning');
    //         return;
    //     }
    
    //     // Store current appointment locally while we fetch the user data
    //     let currentAppointment = { ...appointment };
    //     console.log("Setting current appointment:", currentAppointment);
    //     setSelectedAppointment(currentAppointment);
    //     localStorage.setItem('selAppt', JSON.stringify(currentAppointment));
    
    //     try {
    //         // Fetch user data
    //         console.log("Fetching user data for user ID:", currentAppointment.user);
    //         const user = await fetchUserById(currentAppointment.user);
    
    //         if (user) {
    //             console.log("User data fetched successfully:", user);
    //             setSelectedUser(user);
    //             localStorage.setItem('selUsr', JSON.stringify(user));
    //         } else {
    //             console.warn("User not found for ID:", currentAppointment.user);
    //             triggerAlert("User not found.", "warning");
    //             setSelectedAppointment(null);
    //             setSelectedUser(null);
    //             localStorage.removeItem('selAppt');
    //             localStorage.removeItem('selUsr');
    //         }
    //     } catch (error) {
    //         console.error('Error fetching user:', error);
    //         triggerAlert("Error fetching user data.", "danger");
    //     }
    
    //     console.log("Opening ApptFilledForm with data.");
    //     setAppContentOpen(true); // Open ApptFilledForm
    //     setAppModalOpen(false);   // Close ApptDetailsForm
    // };
    

    const handleSelectEvent = async (appointment) => {
        console.log("Clearing previous selection.");
        setSelectedAppointment(null);
        setSelectedUser(null);
        setDisabledBtns(false);
    
        localStorage.removeItem('selAppt');
        localStorage.removeItem('selUsr');
    
        const eventStart = new Date(appointment.start);
        if (appointment.is_paid) setDisabledBtns(true);
    
        const withinWorkingHours = isWithinWorkingHours(appointment.resourceId, eventStart);
        if (!withinWorkingHours) {
            triggerAlert('This event is outside of the working hours.', 'warning');
            return;
        }
    
        const currentAppointment = { ...appointment };
        setSelectedAppointment(currentAppointment);
        localStorage.setItem('selAppt', JSON.stringify(currentAppointment));
    
        try {
            const user = await fetchUserById(currentAppointment.user);
            if (user) {
                setSelectedUser(user);
                localStorage.setItem('selUsr', JSON.stringify(user));
            } else {
                triggerAlert("User not found.", "warning");
                setSelectedAppointment(null);
                setSelectedUser(null);
                localStorage.removeItem('selAppt');
                localStorage.removeItem('selUsr');
            }
        } catch (error) {
            console.error('Error fetching user:', error);
            triggerAlert("Error fetching user data.", "danger");
        }
    
        setAppContentOpen(true);
        setAppModalOpen(false);
    };
    
    // const handleMouseEnter = (event, appointment) => {
    //     const rect = event.target.getBoundingClientRect();
    //     setHoveredAppointment(appointment);
    //     setHoverPosition({
    //         top: rect.top + window.scrollY + rect.height / 2, // Adjust the Y position as needed
    //         left: rect.left + window.scrollX + rect.width, // Align the container next to the event
    //     });
    //     setShowHoverContainer(true);
    // };
    
    // const handleMouseLeave = () => {
    //     setShowHoverContainer(false);
    //     setHoveredAppointment(null);
    // };



    const handleCancelAppointment = async (id) => {
        try {
            await api.delete(`/api/schedule/appointments/delete/${id}/`);
            setAppointments((prevAppointments) =>
                prevAppointments.filter((appointment) => appointment.id !== id)
            );
            setIsModalAppOpen(false);
            triggerAlert('Appointment deleted successfully', 'success');
            // Log before fetching appointments
            console.log(`Appointment with ID ${id} deleted. Fetching updated appointments for ${selectedDate}`);
            const formattedDate = moment(selectedDate).format('YYYY-MM-DD');

            await fetchAppointments(formattedDate);
        } catch (error) {
            console.error('Error canceling appointment:', error);
    
            // Additional logging for fetchAppointments error
            console.error('Error fetching appointments after deletion:', error.response ? error.response.data : error.message);
        }
    };

    // const handleSelectSlot = ({ start, resourceId }) => {
    //     setAppContentOpen(false);
    //     setSelectedAppointment(null);
    //     setSelectedUser(null);
    //     setDisabledBtns(false);
    
    //     const now = moment().tz('America/Toronto');
    //     if (isPastDate || moment(start).isBefore(now)) {
    //         triggerAlert('You cannot add an appointment to a previous date or time', 'warning');
    //         return;
    //     }
    
    //     if (!isWithinWorkingHours(resourceId, start)) {
    //         triggerAlert('Selected time is outside of working hours.', 'warning');
    //         return;
    //     }
    
    //     const selected = moment(start).tz('America/Toronto');  // Ensure start is wrapped in moment()
    //     if (selected.format('YYYY-MM-DD') !== moment(selectedDate).format('YYYY-MM-DD')) {  // Wrap selectedDate in moment()
    //         setSelectedDate(selected);  // Only set date if it’s different
    //     }
    
    //     setSelectedResourceId(resourceId);
    //     onResourceId(resourceId);
    //     setTitle('');
    //     setAppModalOpen(true);
    // };
    
    
    const handleSelectSlot = ({ start, resourceId }) => {
        console.log('Selected slot start time:', start);
        setAppContentOpen(false);
        setSelectedAppointment(null);
        setSelectedUser(null);
        setDisabledBtns(false);


        localStorage.removeItem('selAppt');
        localStorage.removeItem('selUsr');


        const now = toZonedTime(new Date(), 'America/Toronto'); // Current date in Toronto timezone
        const selectedStart = toZonedTime(start, 'America/Toronto'); // Convert start time to Toronto timezone
    
        // Check if the selected date is in the past or before the current time
        if (isPastDate || isBefore(selectedStart, now)) {
            triggerAlert('You cannot add an appointment to a previous date or time', 'warning');
            return;
        }
    
        if (!isWithinWorkingHours(resourceId, selectedStart)) {
            triggerAlert('Selected time is outside of working hours.', 'warning');
            return;
        }
    
        setSelectedDate(selectedStart);
        setSelectedResourceId(resourceId);
        onResourceId(resourceId);
    
        setAppModalOpen(true);
    };

    
 
    const handleDurationSelect = async (title, duration) => {
        if (!selectedDate || !selectedResourceId) {
            console.error('Selected date or resource ID is null.');
            return;
        }
    
        let startTime = moment(selectedDate).tz('America/Toronto');
        const endTime = startTime.clone().add(duration, 'minutes');
    
        try {
            const currentUserResponse = await api.get('/api/user/current/');
            const currentUserId = currentUserResponse.data.id;
            const currentUserProfData = await api.get(`/api/profile/${currentUserId}`)
            const currUserFirstName = currentUserProfData.data.first_name;
            const currUserLastName = currentUserProfData.data.last_name;
            const username = `${currUserFirstName}, ${currUserLastName}`;
            const therapistResp = await api.get(`/api/user/${selectedResourceId}/`);
            const therapistProfData = await api.get(`/api/profile/${selectedResourceId}`)
            const {first_name, last_name} = therapistProfData.data
            const therapistName = `${first_name}, ${last_name}`;
    
            // Format start and end times without converting to ISO string
            const startTimeFormatted = startTime.format('YYYY-MM-DDTHH:mm:ss');
            const endTimeFormatted = endTime.format('YYYY-MM-DDTHH:mm:ss');
    
            console.log('therapist name is', therapistName);
            console.log('username is ', username);

            await api.post('/api/schedule/appointments/add/', {
                user: currentUserId,
                teacher: selectedResourceId,
                therapist: selectedResourceId,
                therapist_name: therapistName,
                start_time: startTimeFormatted,
                end_time: endTimeFormatted,
                title: title,
                date: startTime.format('YYYY-MM-DD'),
                username: username,
                user_email: email,
                appt_length: duration,
                svg_image_id: 1,
            });
    
            // Update local state with the new appointment
            setAppointments(prevAppointments => [
                ...prevAppointments,
                {
                    user: currentUserId,
                    start_time: startTimeFormatted,
                    end_time: endTimeFormatted,
                    title: title,
                    username: username,
                    duration: duration,
                }
            ]);
    
            // Fetch updated appointments
            const formattedDate = startTime.format('YYYY-MM-DD');
            await fetchAppointments(formattedDate);
    
            setTitle('');
            closeModal();
        } catch (error) {
            triggerAlert('There was an error saving the appointment, please contact the administrator {code: 472134}', 'caution');
        }
    }

    const eventPropGetter = (event) => {
        const eventEnd = new Date(event.end);
        const eventStart = new Date(event.start);
        if (eventStart >= currentTime && eventEnd < currentTime) {
            return {
                style: {
                backgroundColor: 'green',
                color: 'white',
                },
            };
        }
        if (eventEnd < currentTime) {
            return {
                style: {
                backgroundColor: 'gray',
                color: 'white',
                },
            };
            }
            return {
                style: {
                    backgroundColor: 'purple',
                    color: 'white',
                },
            };
        };

    useEffect(() => {
            // Update previous state whenever appointments change
        const updatedState = {};
        appointments.forEach(appointment => {
            updatedState[appointment.id] = {
                is_new_patient: appointment.is_new_patient,
                is_paid: appointment.is_paid,
                is_therapist_req: appointment.is_therapist_req,
                apt_msg: appointment.apt_msg,
            };
        });
        setPrevAppointmentState(updatedState);
    }, [appointments]);


    const renderIcons = (event) => {
        console.log("Event Properties:", {
            is_new_patient: event.is_new_patient,
            is_paid: event.is_paid,
            is_therapist_req: event.is_therapist_req
        });
    
        return (
            <>
                {event.is_new_patient && <NewPatientIcon />}
                {event.is_paid && <DollarSignIcon />}
                {event.is_therapist_req && <TherapistReqIcon />}
            </>
        );
    };
    

    const eventRenderer = ({ event }) => (
        <div
            // onMouseEnter={(e) => handleMouseEnter(e, event)} // Pass the event and mouse event
            // onMouseLeave={handleMouseLeave}
            // style={{ cursor: 'pointer' }}
        >
            <div>
                <span style={{ fontWeight: 'normal' }}>{event.title}</span> | <strong>{event.username}</strong>
            </div>
            {renderIcons(event)}
        </div>
    );

    const handleEdit = (appointment) => {
        console.log('Edit appointment:', appointment);
        setContextMenu({ visible: false, x: 0, y: 0, appointment: null });
    };

    const handleDelete = (appointment) => {
        console.log('Delete appointment:', appointment);
        setContextMenu({ visible: false, x: 0, y: 0, appointment: null });
    };

    const handleCloseContextMenu = () => {
        setContextMenu({ visible: false, x: 0, y: 0, appointment: null });
    };

    const handleNavigate = (date) => {
        setSelectedDate(date);
    };

    const handleEventResize = async ({ event, start, end }) => {
        console.log('Resize: event', event);
        console.log('Resize: start', start);
        console.log('Resize: end', end);
        
  
        const newDuration = (new Date(end) - new Date(start)) / (1000 * 60); // Calculate duration in minutes
        
            // Update local state
        setAppointments(prevAppointments =>
            prevAppointments.map(appt =>
                appt.id === event.id ? { ...appt, start, end, duration: newDuration } : appt
            )
        );
    
            // Send the update to the backend
        try {
            await api.patch(`/api/schedule/appointments/${event.id}/`, {
                start_time: start.toISOString(),
                end_time: end.toISOString(),
                appt_length: newDuration,
            });
        } catch (error) {
            console.error('Error updating appointment:', error);
        }
        const formattedDate = format(new Date(start), 'yyyy-MM-dd');
        await fetchAppointments(formattedDate);
    };
    
    

    const handleEventDrop = async ({ event, start, end, resourceId }) => {
        console.log('handleDrop');
        console.log('drop - selectedDate', selectedDate);

        const newStart = new Date(start);
        const newEnd = new Date(end);
        const therapistId = resourceId || event.resourceId;
    
        // Check if the new start and end times are within the working hours of the therapist
        const isWithinHours = isWithinWorkingHours(therapistId, newStart) && isWithinWorkingHours(therapistId, newEnd);
    
        if (!isWithinHours) {
            triggerAlert('Cannot move appointment outside of working hours.', 'warning');
            // Revert the event to its original position by triggering a re-render
            setAppointments(prevAppointments => [...prevAppointments]);
            return;
        }
    
        try {
            // const userJSON = JSON.parse(localStorage.getItem('user'));
            // const currentUserId = userJSON.id;
    
            // const formattedStart = moment(start).format('YYYY-MM-DDTHH:mm:ss');
            // const formattedEnd = moment(end).format('YYYY-MM-DDTHH:mm:ss');
            const formattedStart = new Date(start).toISOString();
            const formattedEnd = new Date(end).toISOString();
            console.log('drop- formattedstart', formattedStart);
    
            // Update the appointment time in the database
            await api.patch(`/api/schedule/appointments/${event.id}/`, {
                start_time: formattedStart,
                end_time: formattedEnd,
                therapist: therapistId,
            });
    
            // Update the event in the local state
            const updatedEvents = appointments.map((ev) => {
                if (ev.id === event.id) {
                    return { ...ev, start, end }; // Use the new start and end time
                }
                return ev;
            });
    
            setAppointments(updatedEvents);
            await fetchAppointments(selectedDate);
        } catch (error) {
            triggerAlert('There was an error updating this appointment, please contact administrator {code: 472149}', 'caution');
        }
    };
    


    
    // const getSchedulerTimes = (date) => {
    //     const isDSTChangeDay = moment(date).isSame("2024-11-03", "day");
    //     const timezone = 'America/Toronto';
    
    //     const start = isDSTChangeDay 
    //         ? moment.tz("2024-11-03 09:00:00", timezone).toDate()
    //         : moment.tz(date, timezone).set({ hour: 9, minute: 0, second: 0 }).toDate();
    
    //     const end = isDSTChangeDay
    //         ? moment.tz("2024-11-03 23:00:00", timezone).toDate()
    //         : moment.tz(date, timezone).set({ hour: 23, minute: 0, second: 0 }).toDate();
    
    //     return { start, end };
    // };
    // const newDate = moment(selectedDate).tz('America/Toronto').format('YYYY-MM-DD');
    // const { start: startsched, end: endsched } = getSchedulerTimes(newDate);

    const getSchedulerTimes = (date) => {
        const timezone = 'America/Toronto';
        const targetDate = format(date, 'yyyy-MM-dd');
        const isDSTDate = targetDate === '2024-11-03'; // DST change date
    
        const start = isDSTDate 
            ? toZonedTime(new Date('2024-11-03T09:00:00Z'), timezone) 
            : set(toZonedTime(date, timezone), { hours: 9, minutes: 0, seconds: 0 });
    
        const end = isDSTDate
            ? toZonedTime(new Date('2024-11-03T23:00:00Z'), timezone)
            : set(toZonedTime(date, timezone), { hours: 23, minutes: 0, seconds: 0 });
    
        return { start, end };
    };

    const schedulerTimes = getSchedulerTimes(selectedDate);


    console.log('schedTimesStart', schedulerTimes.start);
    //console.log('selecteeddayis ', newDate)

    appointments.forEach(appointment => {
        console.log('start_time:', appointment.start_time);
        console.log('end_time:', appointment.end_time);
    });



    const eventsData = useMemo(() => {
        return appointments.map(appointment => {

            console.log('Raw start_time:', appointment.start_time);
            console.log('Raw end_time:', appointment.end_time);
            const utcStartTime = parseISO(appointment.start_time);
            const utcEndTime = parseISO(appointment.end_time);

            // Convert UTC times to Toronto timezone
            const startTime = toDate(utcStartTime, { timeZone: 'America/Toronto' });
            const endTime = toDate(utcEndTime, { timeZone: 'America/Toronto' });

            console.log('Converted startTime (Toronto):', startTime);
            console.log('Converted endTime (Toronto):', endTime);

            return {
                id: appointment.id,
                title: appointment.title,
                start: startTime,
                end: endTime,
                resourceId: appointment.teacher,
                username: appointment.username,
                user: appointment.user,
                email: appointment.user_email,
                is_new_patient: appointment.is_new_patient,
                is_therapist_req: appointment.is_therapist_req,
                is_paid: appointment.is_paid,
                apt_msg: appointment.apt_msg,
            };
        });
    }, [appointments]);
    
    console.log('eventsData:', eventsData);

    const filteredEvents = useMemo(() => 
        eventsData.filter(event => checkedTherapists.includes(event.resourceId)),
        [eventsData, checkedTherapists]
    );

    
    const filteredResources = useMemo(() => {
        return resources.filter(resource => 
            checkedTherapists.includes(resource.resourceId)
        );
    }, [resources, checkedTherapists]);

    const handleSaveAppointment = (appointmentData) => {
        // Handle saving appointment to the database
        console.log('Saving appointment:', appointmentData);
    
        // Update appointments state with the new appointment
        setAppointments([...appointments, appointmentData]);
    
        // Close AppModal
        setAppModalOpen(false);
    
        // You may want to send the data to the server here
        // Example: axios.post('your-api-endpoint', appointmentData)
      };


    return (
        <div className="single-day-cal">
            {noTherapistsMessage && (
                <div style={{ color: 'red', fontWeight: 'bold', marginBottom: '10px' }}>
                    Sorry, no therapists are working on this day.
                </div>
            )}
            <div className="calendar-container">
                <div className="therapist-filter-container">
                    <TherapistSelFilter 
                        resources={resources} 
                        checkedTherapists={checkedTherapists} 
                        setCheckedTherapists={setCheckedTherapists} 
                        specificShifts={specificShifts} 
                    />
                </div>
                <div className="calendar-wrapper">
                    <DnDCalendar
                        localizer={localizer}
                        events={filteredEvents}
                        selectable
                        onSelectEvent={handleSelectEvent}
                        onSelectSlot={handleSelectSlot}
                        defaultView="day"
                        views={['day']}
                        step={15}
                        timeslots={1}
                        date={toZonedTime(selectedDate, 'America/Toronto')}
                        style={{ height: 1200 }}
                        startAccessor="start"
                        endAccessor="end"
                        min={schedulerTimes.start}
                        max={schedulerTimes.end}
                        resources={filteredResources}
                        resourceIdAccessor="resourceId"
                        resourceTitleAccessor="resourceTitle"
                        onNavigate={handleNavigate}
                        components={{
                            toolbar: CalCustomTb,
                            event: eventRenderer,
                        }}
                        eventPropGetter={eventPropGetter}
                        slotPropGetter={slotPropGetter}
                        resizable={true}
                        onEventResize={handleEventResize}
                        onEventDrop={handleEventDrop}
                    />
                     {/* {showHoverContainer && hoveredAppointment && (
                        <div className="hover-container" 
                            style={{
                                position: 'absolute',
                                top: `${hoverPosition.top}px`,
                                left: `${hoverPosition.left}px`,
                                background: 'white',
                                border: '1px solid #ccc',
                                padding: '10px',
                                zIndex: 1000,
                            }}
                        >
                            <div>
                                <h4>{hoveredAppointment.username}</h4>
                                <p>Title: {hoveredAppointment.title}</p>
                                <p>Start: {format(new Date(hoveredAppointment.start), 'yyyy-MM-dd HH:mm')}</p>
                                <p>End: {format(new Date(hoveredAppointment.end), 'yyyy-MM-dd HH:mm')}</p>
                            </div>
                        </div>
                    )} */}
                     <ContextMenu
                        contextMenu={contextMenu}
                        onClose={handleCloseContextMenu}
                        onEdit={handleEdit}
                        onDelete={handleDelete}
                    />
                </div>
            </div>

            {/* <AppModal
                isOpen={modalIsOpen}
                onClose={closeModal}
                onSave={handleDurationSelect}
                title={title}
                setTitle={setTitle}
                email={email}
                setEmail={setEmail}
            /> */}

            {/* <AppModal
                    handleClose={() => setAppModalOpen(false)}
                    show={isAppModalOpen}
                    selectedDate={selectedDate}
                    onSave={handleSaveAppointment} 
            /> */}
           {/* <AppDetailModal  
                isOpen={isModalAppOpen}
                onClose={() => setIsModalAppOpen(false)}
                appointment={selectedAppointment}
                onCancel={handleCancelAppointment}
            /> */}
        </div>
    );
};

export default SingleDayCal;
