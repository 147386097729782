import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import api from '../../../api';
import '../../../styles/TherapistAvailabilityView.css'


function TherapistAvailabilityView() {
    const [availability, setAvailability] = useState([]);
    //const { therapistId } = useParams()

  useEffect(() => {
    const fetchAvailability = async () => {
      try {
        const storedUser = localStorage.getItem('user');
        const user = storedUser ? JSON.parse(storedUser) : null;
        console.log('user id ', user.id);
        const response = await api.get(`/api/therapists/${user.id}/availability/`);
        setAvailability(response.data);
        console.log('resp.data', response.data)
      } catch (error) {
        console.error('Error fetching therapist availability:', error);
      }
    };

    fetchAvailability();
  }, []);
  
  const formatTime = (time) => {
    return time ? time.slice(0, 5) : 'OFF'; // Extract first 5 characters (hours and minutes)
  };

  const weekdays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
  return (
    <div className="tables-container">
      <h3>Your current availability</h3>
      <table className="therapistAvailTable">
            <thead>
                <tr className="tableRow">
                    <th>Weekday</th>
                    <th>Start</th>
                    <th>End</th>
                </tr>
                </thead>
                <tbody>
        {availability.map(item => (
                    <tr className="tableData" key={item.id}>
                        <td>{weekdays[item.weekday]}</td>
                        <td className={item.start_time ? 'green-text' : 'red-text'}>{formatTime(item.start_time)}</td>
                        <td className={item.end_time ? 'green-text' : 'red-text'}>{formatTime(item.end_time)}</td>
                    </tr>))}
                </tbody>
            </table>


    </div>
  );
}

export default TherapistAvailabilityView;
