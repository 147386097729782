import React, { useRef, useImperativeHandle, useEffect, useState } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import styled from 'styled-components';
import '../../styles/Canvas.css';

const Canvas = React.forwardRef(({ svgData, muscleColors, onSaveCanvas }, ref) => {
    const signatureRef = useRef();
    const [penColor, setPenColor] = useState('black');
    const [penSize, setPenSize] = useState(5);
    const containerRef = useRef(null);

    useImperativeHandle(ref, () => ({
        saveCanvas: () => {
            if (signatureRef.current) {
                const updatedCanvasDataUrl = signatureRef.current.toDataURL('image/png');
                console.log('Captured Canvas Data URL:', updatedCanvasDataUrl); // Debugging line

                // Ensure the onSaveCanvas callback is called with the correct data
                onSaveCanvas(updatedCanvasDataUrl);
            } else {
                console.error('SignatureCanvas reference is null');
            }
        }
    }));

    useEffect(() => {
        const drawSVGOnCanvas = async () => {
            if (signatureRef.current && containerRef.current && svgData) {
                const canvas = signatureRef.current.getCanvas();
                const ctx = canvas.getContext('2d');
    
                console.log('Canvas and context initialized.');
                console.log('SVG Data URL:', svgData);
    
                try {
                    // Fetch raw SVG content
                    const response = await fetch(svgData);
                    if (!response.ok) {
                        throw new Error(`Error fetching SVG: ${response.statusText}`);
                    }
                    const svgText = await response.text();
                    console.log('Fetched raw SVG content:', svgText);
    
                    // Convert the SVG content to a Blob and create an Object URL
                    const blob = new Blob([svgText], { type: 'image/svg+xml' });
                    const url = URL.createObjectURL(blob);
                    console.log('Generated Object URL:', url);
    
                    const image = new Image();
                    image.onload = function () {
                        const containerWidth = containerRef.current.clientWidth;
                        const containerHeight = containerRef.current.clientHeight;
    
                        canvas.width = containerWidth;
                        canvas.height = containerHeight;
    
                        console.log('Canvas dimensions set:', {
                            width: canvas.width,
                            height: canvas.height,
                        });
    
                        ctx.clearRect(0, 0, canvas.width, canvas.height);
                        ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
                        console.log('Image drawn on canvas.');
    
                        // Revoke the Object URL after use
                        URL.revokeObjectURL(url);
                    };
    
                    image.onerror = function (err) {
                        console.error('Error loading image:', err);
                    };
    
                    image.src = url;
                    console.log('Image source set:', image.src);
                } catch (error) {
                    console.error('Error fetching or rendering SVG:', error);
                }
            } else {
                console.warn('Canvas, container, or SVG data is not available.');
            }
        };
    
        drawSVGOnCanvas();
    
        window.addEventListener('resize', drawSVGOnCanvas);
        return () => {
            window.removeEventListener('resize', drawSVGOnCanvas);
        };
    }, [svgData, muscleColors]);
    

    return (
        <div>
            <div className="canvas-controls">
                <label>Pen Color:</label>
                <input type="color" value={penColor} onChange={(e) => setPenColor(e.target.value)} />

                <label>Pen Size:</label>
                <input
                    type="number"
                    value={penSize}
                    onChange={(e) => setPenSize(Number(e.target.value))}
                    min="1"
                    max="10"
                />
            </div>

            <StyledSVGContainer ref={containerRef}>
                <SignatureCanvas
                    ref={signatureRef}
                    penColor={penColor}
                    canvasProps={{
                        className: 'signature-canvas',
                    }}
                    style={{
                        border: '1px solid #000',
                        width: '100%',
                        height: '100%',
                        zIndex: 2,
                    }}
                    minWidth={penSize}
                    maxWidth={penSize}
                />
            </StyledSVGContainer>
        </div>
    );
});

Canvas.displayName = 'Canvas'; 

const StyledSVGContainer = styled.div`
    display: flex; /* Enables flexbox */
    justify-content: center; /* Centers horizontally */
    align-items: center; /* Centers vertically */
    width: 600px; /* Define a square size */
    height: 600px; /* Make it square by setting height equal to width */
    margin: 0 auto; /* Center the container horizontally */
    position: relative;
    box-sizing: border-box; /* Include padding and border in dimensions */
    background-color: #f9f9f9; /* Optional: Add a light background for visibility */
    border: 1px solid #ddd; /* Optional: Add a border for better visuals */
`;




export default Canvas;
