import React, {useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import '../../../styles/TherapistSelFilter.css'

const TherapistSelFilter = ({ resources, checkedTherapists, setCheckedTherapists }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const handleCheckboxChange = (resourceId) => {
        setCheckedTherapists(prevChecked => 
            prevChecked.includes(resourceId) 
                ? prevChecked.filter(id => id !== resourceId) 
                : [...prevChecked, resourceId]
        );
    };

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };
    // console.log('resources', resources);
    // console.log('checkedTher', checkedTherapists);
    
    return (
        <div className="therapist-filter">
            <h3>
                Therapists
                <button onClick={toggleExpand} style={{ marginLeft: '10px', cursor: 'pointer', background: 'none', border: 'none' }}>
                    <FontAwesomeIcon icon={isExpanded ? faMinus : faPlus} />
                </button>
            </h3>
            {isExpanded && (
                <div className="therapist-list">
                    {resources.map(resource => (
                        <div key={resource.resourceId}>
                            <label>
                                <input
                                    type="checkbox"
                                    checked={checkedTherapists.includes(resource.resourceId)}
                                    onChange={() => handleCheckboxChange(resource.resourceId)}
                                />
                                {resource.resourceTitle}
                            </label>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default TherapistSelFilter;