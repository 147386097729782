import React from "react";
import '../../styles/Modal.css';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { Modal, Button } from 'react-bootstrap';

const PaymentConfirmModal = ({ show, paymentMethod, onConfirm, onCancel, total }) => {
    console.log('payment method is', paymentMethod)
    return (
        <Modal show={show} onHide={onCancel}>
            <Modal.Header closeButton>
                <Modal.Title>Confirm Payment</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {paymentMethod === 'PayPal' ? (
                     <p>Pay with {paymentMethod} below:</p>
                ): <p>Are you sure you want to pay with {paymentMethod}</p>}
            </Modal.Body>
            {paymentMethod === 'PayPal' ? (
                    <PayPalScriptProvider options={{ "client-id": "AX1cy1s_MOSEWw1r6wiSjqF4hyfQdNy7q9-MGzDRnR9xu454FPzK1vt3RrMdOmIEzVcK5CCTsWStniTI" }}>
                        <PayPalButtons
                            createOrder={(data, actions) => {
                                // Ensure the modal is opened and payment method is set
                                return actions.order.create({
                                    purchase_units: [{
                                        amount: {
                                            value: total, // Pass the grand total
                                        }
                                    }]
                                });
                            }}
                            onApprove={async (data, actions) => {
                                const order = await actions.order.capture();
                                console.log('Order approved:', order);
                                onConfirm(); // Confirm the payment
                            }}
                            onError={(error) => {
                                console.error('PayPal error:', error);
                                // Optionally handle the error
                            }}
                        />
                    </PayPalScriptProvider>
                ) : (
                    <Modal.Footer>
                        <Button variant="secondary" onClick={onCancel}>
                            Cancel
                        </Button>
                        <Button variant="primary" onClick={onConfirm}>
                            Confirm
                        </Button>
                    </Modal.Footer>
                )}
            
        </Modal>
    );
};
export default PaymentConfirmModal;