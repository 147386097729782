const ContextMenu = ({ contextMenu, onClose, onEdit, onDelete }) => {
    if (!contextMenu.visible) return null;

    return (
        <div
            style={{
                position: 'absolute',
                top: contextMenu.y,
                left: contextMenu.x,
                backgroundColor: 'white',
                boxShadow: '0 2px 5px rgba(0,0,0,0.15)',
                zIndex: 1000,
                padding: '10px',
                borderRadius: '4px'
            }}
        >
            <button onClick={() => onEdit(contextMenu.appointment)}>Edit</button>
            <button onClick={() => onDelete(contextMenu.appointment)}>Delete</button>
            <button onClick={onClose}>Close</button>
        </div>
    );
};

export default ContextMenu;