import React from 'react';
import '../../../styles/CalCustomTb.css';

const CalCustomTb  = ({ label }) => {
    return (
      <div className="custom-toolbar">
        <h1 className="custom-toolbar-label">{label}</h1>
      </div>
    );
  };

export default CalCustomTb;