import React, { useState } from 'react';
import AddItem from './AddItem';
import CartItems from './CartItems';
import '../../styles/MainCartPage.css';
import VisAlert from '../VisAlert';

const MainCheckoutPage = ({ appointment }) => {
    console.log('MainCheckout appt', appointment);
    const obj = new Date(appointment.start);
    const year = obj.getFullYear();
    console.log('MainCheckout year dateobj', year);
    const month = String(obj.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(obj.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;

    const [cartItems, setCartItems] = useState([]);
    const [alertMsg, setAlertMsg] = useState("");
    const [alertType, setAlertType] = useState('warning');


    const handleAddItem = (item) => {
        if (cartItems.length === 0) {
            setCartItems([item]);
        } else {
            setAlertType('warning')
            setAlertMsg("You can only check out one appointment at a time.");
        }
    };

    const handleRemoveItem = (item) => {
        setCartItems([]);
    };


    return (
        <div className="main-checkout-page"> 
            <VisAlert message={alertMsg} type={alertType} />
            <h3><strong>Checkout</strong></h3>
            <div className="main-divider">
                <div className="cart-items">
                    <CartItems 
                        items={cartItems} 
                        appointment={appointment} 
                        formattedDate={formattedDate} 
                        onRemoveItem={handleRemoveItem} 
                        alertVis={setAlertMsg}
                        alertType={setAlertType}
                    />
                </div>
                <div className="add-item">
                    <AddItem 
                        appointment={appointment} 
                        formattedDate={formattedDate} 
                        onAddItem={handleAddItem} 
                        cartItems={cartItems} // Pass cartItems to AddItem
                    />
                </div>
            </div>
        </div>
    );
};

export default MainCheckoutPage;
