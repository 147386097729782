import React, { useState, useEffect } from "react";
import api from "../../../api";

const SquareSetupForm = ({ userId, setAlertMsg, setAlertType }) => {
    const [appId, setAppId] = useState("");
    const [accessToken, setAccessToken] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isCredentialsSaved, setIsCredentialsSaved] = useState(false);
    const [locationId, setLocationId] = useState("");
    const [webhookSecret, setWebhookSecret] = useState("");
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);

    // Fetch existing Square credentials on component mount
    useEffect(() => {
        const fetchSquareCredentials = async () => {
            setIsLoading(true);
            try {
                const response = await api.get("/api/square-credentials/");
                if (response.data) {
                    setAppId(response.data.application_id || "");
                    setAccessToken("********"); // Mask the access token for security
                    setWebhookSecret("**********");
                    setIsCredentialsSaved(true);
                } else {
                    setIsCredentialsSaved(false);
                }
            } catch (error) {
                console.error("Error fetching Square credentials:", error.response?.data || error);
                setIsCredentialsSaved(false); // No credentials found or error
            } finally {
                setIsLoading(false);
            }
        };

        fetchSquareCredentials();
    }, []);

    // Update submit button state when form values change
    useEffect(() => {
        const areAllFieldsFilled = appId.trim() && accessToken.trim() && locationId.trim() && webhookSecret.trim();
        setIsSubmitDisabled(!areAllFieldsFilled); // Disable if fields are empty
    }, [appId, accessToken, locationId, webhookSecret]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            await api.post("/api/square-credentials/", {
                user: userId,
                application_id: appId,
                access_token: accessToken,
                location_id: locationId,
                webhook: webhookSecret,
            });
            setAlertMsg("Square credentials saved successfully!");
            setAlertType("success");
            setIsCredentialsSaved(true); // Mark credentials as saved
            setAccessToken("********"); // Mask the token again
            setLocationId("********");
            setWebhookSecret("**********");
        } catch (error) {
            console.error("Error saving credentials:", error.response?.data || error);
            setAlertMsg("Failed to save Square credentials.");
            setAlertType("warning");
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div style={{ maxWidth: "500px", margin: "auto", textAlign: "center" }}>
            {isCredentialsSaved && (
                <div style={{ marginBottom: "20px" }}>
                    <img
                        src="https://res.cloudinary.com/dhdn6hovg/image/upload/v1732802722/checkmark_wlnj8d.png"
                        alt="Checkmark"
                        style={{ width: "50px", height: "50px" }}
                    />
                    <h3 style={{ color: "green" }}>Square Credentials Already Saved</h3>
                </div>
            )}

            <form onSubmit={handleSubmit} style={{ maxWidth: "400px", margin: "auto" }}>
                <h2>Setup Square Integration</h2>
                <div style={{ marginBottom: "20px" }}>
                    <label>Square Application ID:</label>
                    <input
                        type="text"
                        value={appId}
                        onChange={(e) => setAppId(e.target.value)}
                        required
                        style={{
                            width: "100%",
                            padding: "10px",
                            marginTop: "8px",
                            borderRadius: "4px",
                            border: "1px solid #ccc",
                        }}
                    />
                </div>
                <div style={{ marginBottom: "20px" }}>
                    <label>Square Access Token:</label>
                    <input
                        type="password"
                        value={accessToken}
                        onChange={(e) => setAccessToken(e.target.value)}
                        required
                        style={{
                            width: "100%",
                            padding: "10px",
                            marginTop: "8px",
                            borderRadius: "4px",
                            border: "1px solid #ccc",
                        }}
                    />
                </div>
                <div style={{ marginBottom: "20px" }}>
                    <label>Location ID:</label>
                    <input
                        type="text"
                        value={locationId}
                        onChange={(e) => setLocationId(e.target.value)}
                        required
                        style={{
                            width: "100%",
                            padding: "10px",
                            marginTop: "8px",
                            borderRadius: "4px",
                            border: "1px solid #ccc",
                        }}
                    />
                </div>
                <div style={{ marginBottom: "20px" }}>
                    <label>Webhook Secret:</label>
                    <input
                        type="password"
                        value={webhookSecret}
                        onChange={(e) => setWebhookSecret(e.target.value)}
                        required
                        style={{
                            width: "100%",
                            padding: "10px",
                            marginTop: "8px",
                            borderRadius: "4px",
                            border: "1px solid #ccc",
                        }}
                    />
                </div>

                <button
                    type="submit"
                    disabled={isSubmitDisabled || isLoading}
                    style={{
                        width: "100%",
                        padding: "10px 20px",
                        backgroundColor: isSubmitDisabled ? "#cccccc" : "#007bff",
                        color: "#fff",
                        border: "none",
                        borderRadius: "4px",
                        cursor: isSubmitDisabled ? "not-allowed" : "pointer",
                    }}
                >
                    {isLoading ? "Saving..." : "Save Credentials"}
                </button>
            </form>
        </div>
    );
};

export default SquareSetupForm;