import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';

const AuthErrorModal = ({ show, handleClose }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleLoginRedirect = () => {
    handleClose();
    navigate('/login');
  };

  const shouldShowModal = show && !(
    location.pathname === '/login' || 
    location.pathname.startsWith('/therapists') || 
    location.pathname === '/creates' || 
    location.pathname === '/register' || 
    location.pathname.startsWith('/verify') ||
    location.pathname === '/forgotpass'
);


  console.log('Show Modal:', show, 'Should Show:', shouldShowModal);
  
  return (
    <Modal show={shouldShowModal} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Session Expired</Modal.Title>
      </Modal.Header>
      <Modal.Body>Your session has expired. Please log in again.</Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleLoginRedirect}>
          Go to Login
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AuthErrorModal;
