import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import '../../../styles/BlockDates.css'
import api from '../../../api';
import moment from 'moment-timezone';
import VisAlert from '../../VisAlert';

const BlockedDatesForm = () => {
    // const { therapistId } = useParams();
    const navigate = useNavigate();
    const [blockedDates, setBlockedDates] = useState([]);
    const [newBlockedDate, setNewBlockedDate] = useState('');
    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');
    const [availability, setAvailability] = useState([]);
    const [availabilityForSelectedDay, setAvailabilityForSelectedDay] = useState(null);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertType, setAlertType] = useState('error');


    useEffect(() => {
        const fetchBlockedDates = async () => {
            try {
                const storedUser = localStorage.getItem('user');
                const user = storedUser ? JSON.parse(storedUser) : null;
                const response = await api.get(`/api/therapists/${user.id}/availability/`);
                setAvailability(response.data);
            } catch (error) {
                console.error('Error fetching therapist availability:', error);
            }
        };

        fetchBlockedDates();
    }, []);

    useEffect(() => {
        // Filter availability based on selected date's weekday
        const selectedDate = moment.tz(newBlockedDate, 'YYYY-MM-DD', 'America/Toronto').startOf('day');
        console.log('selectedDate', selectedDate);
        const selectedDayOfWeek = (selectedDate.day() + 6) % 7; // 0 (Sunday) to 6 (Saturday)
        console.log('selectedDayOfWeek ', selectedDayOfWeek );
        const selectedAvailability = availability.find(avail => avail.weekday === selectedDayOfWeek);

        if (selectedAvailability) {
            console.log('selectedAvailability.start_time,', selectedAvailability.start_time)
            setStartTime(selectedAvailability.start_time || '');
            setEndTime(selectedAvailability.end_time || '');
        } else {
            setStartTime('');
            setEndTime('');
        }
    }, [newBlockedDate, availability]);

    const handleAddBlockedDate = async () => {
        try {
            const user = JSON.parse(localStorage.getItem('user'));
            const profile = JSON.parse(localStorage.getItem('profile'));
            console.log('Profile:', profile);
            const { first_name, last_name } = profile;
            const username = `${first_name}, ${last_name.charAt(0)}`;
    
            const clinicId = profile.clinic;
            const userEmail = profile.email;
            // Combine date and time into ISO format
            const startTimeFormatted = moment(`${newBlockedDate} ${startTime}`, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DDTHH:mm:ss');
            const endTimeFormatted = moment(`${newBlockedDate} ${endTime}`, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DDTHH:mm:ss');
    
            // Validate start and end times
            if (!moment(startTimeFormatted, 'YYYY-MM-DDTHH:mm:ss', true).isValid()) {
                console.error('Invalid startTime format');
                setAlertType('error');
                setAlertMessage('Invalid start time format');
                return;
            }
            if (!moment(endTimeFormatted, 'YYYY-MM-DDTHH:mm:ss', true).isValid()) {
                console.error('Invalid endTime format');
                setAlertType('error');
                setAlertMessage('Invalid end time format');
                return;
            }
    

            await api.post(`/api/schedule/appointments/add/`, {
                teacher: user.id,
                user: user.id,
                therapist: user.id,
                start_time: startTimeFormatted,
                end_time: endTimeFormatted,
                title: 'Blocked Date', 
                date: newBlockedDate,
                username: username,
                therapist_name: username,
                user_email: userEmail,
                appt_length: 100, // Adjust as necessary
                clinic: clinicId,
            });
            console.log("SUCCESS - blockedDates");
            setAlertType('success');
            setAlertMessage(`Successfully blocked the selected times on ${newBlockedDate}`);

        } catch (error) {
            setAlertType('error');
            setAlertMessage(`Error blocking the selected times on ${newBlockedDate}`);
            console.error('Error adding blocked date:', error);
        }
    };
    

    // Disable save button if either startTime or endTime is empty
    const isSaveDisabled = !startTime || !endTime;

    const handleChangeStartTime = (e) => {
        setStartTime(e.target.value);
    };
    
    const handleChangeEndTime = (e) => {
        setEndTime(e.target.value);
    };


    console.log('starttime issss', startTime)
    return (
        <div className="div-btn">
             {alertMessage && <VisAlert message={alertMessage} type={alertType} />}
            <h2>Block Specific Dates</h2>
            <input
                type="date"
                value={newBlockedDate}
                onChange={(e) => setNewBlockedDate(e.target.value)}
            />
            {newBlockedDate && (
                <div>
                    <label>
                        From:
                        <input
                            type="time"
                            value={startTime}
                            onChange={handleChangeStartTime}
                        />
                    </label>
                    <label>
                        To:
                        <input
                            type="time"
                            value={endTime}
                            onChange={handleChangeEndTime}
                        />
                    </label>
                    <div>
                        <Button onClick={handleAddBlockedDate} disabled={isSaveDisabled}>Save</Button>
                    </div>
                </div>
            )}
            <ul>
                {blockedDates.map(date => (
                    <li key={date.id}>{date.date}</li>
                ))}
            </ul>
        </div>
    );
};

export default BlockedDatesForm;
