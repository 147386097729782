import React, { useState, useEffect } from "react";
import '../styles/VisAlert.css';

const VisAlert = ({ message, type, duration = 5000 }) => {
    const [visible, setVisible] = useState(false);
  
    useEffect(() => {
      if (message) {
        setVisible(true);
        const timer = setTimeout(() => {
          setVisible(false);
        }, duration);
  
        return () => clearTimeout(timer);
      } else {
        // Handle when message is null
        setVisible(false);
      }
    }, [message, duration]); // Dependency on message ensures useEffect runs on message change
  
    // Always render the alert, but use CSS to control its visibility
    return (
      <div className={`alert ${type} ${visible ? 'show' : ''}`}>
        {message}
      </div>
    );
  };
  
  export default VisAlert;