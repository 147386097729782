import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Form from "../components/Form"
import Modal from 'react-bootstrap/Modal'; 

function Register() {
    const [showModal, setShowModal] = useState(false);
    const auth = localStorage.getItem('userId');
    const navigate = useNavigate();

    useEffect(() => {
        if (auth) {
            // If the user is already logged in, show the modal
            setShowModal(true);

            // Automatically redirect to home after a short delay (optional)
            setTimeout(() => {
                setShowModal(false);
                navigate('/');  // Redirect to home page
            }, 2000);  // Redirect after 2 seconds
        }
    }, [auth, navigate]);

    console.log('auth is', auth);
    return (
        <>
        { !auth && (
        <div>
            <Form route="/api/user/register-sched/" method="register"/>
            <p style={{ textAlign: 'center', fontWeight: 'bold', marginTop: '1rem' }}>
                Already registered? <Link to="/login">Click here to login</Link>.
            </p>
        </div>
        )}

            {/* Modal to notify the user they are already logged in */}
            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Already Logged In</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    You are already logged in. Redirecting to the profile page...
                </Modal.Body>
            </Modal>
        </>
    );
}

export default Register

