import React, { useState, useEffect } from 'react';
import api from '../../api';


// const convertRawUrlToApiUrl = (rawUrl) => {
//     // Extract the path part after '/src/branch/main/'
//     const pathPart = rawUrl.split('/src/branch/main/')[1];
    
//     // Extract owner and repo from the base part
//     const baseParts = rawUrl.split('/src/branch/')[0].split('/');
//     const owner = baseParts[3];
//     const repo = baseParts[4];

//     // Construct the API URL
//     const apiUrl = `https://gitea.com/api/v1/repos/${owner}/${repo}/contents/${pathPart}?ref=main`;

//     return apiUrl;
// };




// const NoteDisplay = ({ note }) => {
//     const [imageData, setImageData] = useState(null);

//     useEffect(() => {
//         const fetchImage = async () => {
//             const imageUrl = convertRawUrlToApiUrl(note.canvas_image);
//             console.log("Image URL:", imageUrl);
//             if (!imageUrl) {
//                 console.error('No image URL provided');
//                 return;
//             }

//             try {
//                 const proxyUrl = `api/proxy-image?url=${encodeURIComponent(imageUrl)}`;
//                 const response = await api.get(proxyUrl, { responseType: 'blob' });

//                 console.log("Response Content-Type:", response.headers['content-type']);
//                 console.log("Response Data (first 100 bytes):", response.data.slice(0, 100));
                
//                 const imageBlobUrl = URL.createObjectURL(response.data);
//                 console.log('imagebloburl', imageBlobUrl);
//                 setImageData(imageBlobUrl);

//                 console.log('Image Blob URL:', imageBlobUrl);
//             } catch (error) {
//                 console.error('Error fetching image:', error);
//             }
//         };
        

//         if (note.canvas_image) {
//             fetchImage();
//         }
//     }, [note.canvas_image]);

//     return (
//         <div>
//             {imageData ? (
//                 <img 
//                     src={imageData} 
//                     alt="Canvas Image" 
//                     className="canvas-image" 
//                 />
//             ) : (
//                 <p>Loading image...</p>
//             )}
//         </div>
//     );
// };


const NoteDisplay = ({ note }) => {
    return (
        <div>
            {note.canvas_image ? (
                <img 
                    src={note.canvas_image} 
                    alt="Canvas Image" 
                    className="canvas-image" 
                    style={{ maxWidth: '100%', height: '100%' }} // Ensures responsive display
                />
            ) : (
                <p>No image available.</p>
            )}
        </div>
    );
};

export default NoteDisplay;
