import React, { useState, useEffect } from 'react';
import api from '../../api';

const RemovedAppts = () => {
    const [apptData, setApptData] = useState([]);

    useEffect(() => {
        const fetchRemovedAppointments = async () => {
            try {
                const response = await api.get('/api/removed-appointments/');
                setApptData(response.data);
            } catch (error) {
                console.error('Error fetching removed appointments:', error);
            }
        };

        fetchRemovedAppointments();
    }, []);

    const formatDateTime = (dateTime) => {
        return dateTime.replace('T', ' ').replace('Z', '');
    };

    return (
        <>
            <div>Deleted appointments</div>
            <ul>
                {apptData.map(appt => (
                    <li key={appt.id}>
                        {formatDateTime(appt.appointment_date_time)} {appt.app_name} - {appt.patient_name}
                    </li>
                ))}
            </ul>
        </>
    );
};

export default RemovedAppts;
