import React, { useState, useEffect, useCallback } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js'; 
import { BrowserRouter, Routes, Route, useParams, useNavigate, useLocation } from 'react-router-dom';
import Login from "./pages/Login";
import Register from "./pages/Register";
import Home from "./pages/Home";
import NotFound from "./pages/NotFound";
import Unauthorized from './pages/Unauthorized';
import ProtectedRoute from "./components/ProtectedRoute";
import Toolbar from "./components/ToolBar";
import AuthErrorModal from "./components/AuthErrorModal";
import CreateSuperuser from './pages/Administrative/CreateSuperuser';
// import EventsPage from './pages/Forum/EventsPage';
// import BillingPage from './pages/Forum/BillingPage';
// import InquriesPage from './pages/Forum/InquriesPage';
// import OffTopicPage from './pages/Forum/OffTopicPage';
// import ProblemPage from './pages/Forum/ProblemPage';
// import TechnicalPage from './pages/Forum/TechnicalPage';
import ListReceiptsPages from './pages/Receipt/ListReceiptsPage';
import ListReceiptsById from './pages/Receipt/ListReceiptsById';
import ForumPage from './pages/Forum/ForumPage';
import Note from './components/Note';
import MuscleMap from './pages/Muscle/MuscleMap';
import AddService from './pages/Administrative/AddService';
import ClinicInfo from './pages/Checkout/ClinicInfo';
import SimpleResizableCalendar from './pages/Schedule/Calendar/SimpleResizableCalendar';
// import DisplaySoap from './pages/Muscle/DisplaySoap';
// import AddSoapNote from './pages/Muscle/AddSoapNote';
import MainSchedPage from './pages/Schedule/MainSchedPage';
import TherapistAvailabilityView from './pages/Schedule/Availability/TherapistAvailabilityView';
import TherapistAvailabilityForm from './pages/Schedule/Availability/TherapistAvailabilityForm';
import BlockedDatesForm from './pages/Schedule/Availability/BlockedDatesForm';
import AppointmentBookingForm from './pages/Schedule/Availability/AppointmentBookingForm';
import VerifyAccount from './components/VerifyAccount';
import UserSettingsPage from './pages/UserSettingsPage';
import HealthHistory from './pages/HealthHistory/HealthHistory';
import CurrentTherapistsPage from "./pages/Therapist/CurrentTherapistsPage";
import VisAlert from './pages/VisAlert';
import moment from 'moment-timezone';
import api from './api';
import { Nav } from 'react-bootstrap';
import PasswordRec from './components/PasswordRec';

moment.tz.setDefault('America/Toronto');

function Logout() {
  const navigate = useNavigate(); // Call useNavigate outside of useEffect

  useEffect(() => {
    localStorage.clear();  // Clear local storage
    navigate('/login');     // Navigate to login page after clearing storage
  }, [navigate]);

  return null; // We return null because we don't need to render anything
}

// function RegisterAndLogout() {
//   const navigate = useNavigate();  // Call useNavigate outside of useEffect

//   useEffect(() => {
//     if (location.pathname !== '/register') {
//       localStorage.clear(); // Clear local storage when the component is mounted
//       navigate('/register'); // Navigate to the /register route
//     }
//   }, [navigate]);

//   return null; // We return null because we don't need to render anything
// }


const AppContent = () => {
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState('error');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [username, setUsername] = useState('');
  const [isTherapist, setIsTherapist] = useState(false);
  const [userId, setUserId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate(); 
  const location = useLocation();

  const triggerAlert = (message, type = 'warning') => {
    setAlertMessage(message);
    setAlertType(type);

    setTimeout(() => {
        setAlertMessage('');
        setAlertType('');
      }, 5000);
};



  const handleLogin = (username) => {
    //console.log("User logged in with username:", username);
    setIsAuthenticated(true);
    setUsername(username);
    setShowModal(false);
    navigate('/'); 
  };

  const handleLogout = useCallback(() => {
      setIsAuthenticated(false);
      setUsername('');
      setUserId(null);
      setIsTherapist(false);
      localStorage.clear();
      navigate('/login');
}, [setIsAuthenticated, setUsername, setUserId, setIsTherapist, navigate]);


const fetchUserData = useCallback(async () => {
  const token = localStorage.getItem('token');  // Assuming the token is stored in localStorage
  console.log('token', token);
  
  try {
      console.log('Fetching current user data');
      const response = await api.get('/api/user/current/');
      const { user, clinic_subdomain } = response.data;
      console.log('user is', user);
      
      if (user) {
          const { profile } = user;
          
          // Only proceed if `profile` exists
          if (profile) {
              localStorage.setItem('userId', user.id);
              setIsAuthenticated(true);
              setUsername(user.username);
              setIsTherapist(profile.is_therapist);
              setUserId(user.id);
          } else {
              console.warn('User profile is missing');
          }
      }
  } catch (error) {
      if (error.response && error.response.status === 401) {
          // Handle 401 Unauthorized
          console.log('User is not authorized, logging out.');
          handleLogout(); // Log the user out when 401 is encountered
          triggerAlert('Your session has expired. You have been logged out.', 'caution');
      } else {
          console.error('Error fetching user data:', error);
      }
  }
}, [setIsAuthenticated, setUsername, setIsTherapist, setUserId, handleLogout]);

// useEffect(() => {
//   if (!isAuthenticated) {
//       fetchUserData();
//   }
// }, [isAuthenticated, fetchUserData]);

  
useEffect(() => {
  // Explicitly list the paths where authentication should not be checked
  const nonAuthPaths = ['/login', '/register', '/therapists', '/verify', '/forgotpass'];

  // Check if the current path is non-authenticated or starts with '/therapists'
  const isNonAuthPath = nonAuthPaths.some(path => location.pathname.startsWith(path));

  // Only fetch user data if we are on an authenticated route
  if (!isNonAuthPath && !isAuthenticated) {
      const localUser = localStorage.getItem('user');
      if (!localUser) {
          console.log('No user data in localStorage, fetching user data');
          fetchUserData(); // Fetch user data only on authenticated routes
      } else {
          // Set the user data from localStorage to avoid unnecessary fetch
          const parsedUser = JSON.parse(localUser);
          setIsAuthenticated(true);
          setUsername(parsedUser.username);
          setUserId(parsedUser.id);
          setIsTherapist(parsedUser.profile?.is_therapist || false);
      }
  }

  const handleLoginEvent = () => {
      console.log('Login event triggered, fetching user data');
      fetchUserData();
  };

  const handleAuthError = () => {
      console.log('Auth error event triggered');
      setShowModal(true);
      setIsAuthenticated(false);
      setUsername('');
      setUserId(null);
      setIsTherapist(false);
      localStorage.clear();
      navigate('/login');
  };

  window.addEventListener('login', handleLoginEvent);
  window.addEventListener('auth-error', handleAuthError);

  // Clean up event listeners on unmount
  return () => {
      window.removeEventListener('login', handleLoginEvent);
      window.removeEventListener('auth-error', handleAuthError);
  };
}, [fetchUserData, location.pathname, navigate, isAuthenticated]);

  
 
 

  // useEffect(() => {
  //   // Listen for the 'auth-error' event to update the state when user is unauthorized
  //   const handleAuthError = () => {
  //     setIsAuthenticated(false);
  //     setUsername('');
  //     setUserId(null);
  //     setIsTherapist(false);
  //     navigate('/login'); // Redirect to login page
  //   };

  //   window.addEventListener('auth-error', handleAuthError);

  //   // Clean up the event listener on unmount
  //   return () => {
  //     window.removeEventListener('auth-error', handleAuthError);
  //   };
  // }, []);


  const handleModalClose = () => setShowModal(false);


  const checkTherapistAuthorization = async (userId, client_id, appointment_id) => {
    // Example of checking therapist authorization
    try {
      console.log("appointment_id", appointment_id)
      const response = await api.get(`/api/appointments/${appointment_id}/`);
      console.log('userId', userId)
      const appointment = response.data;
      console.log("ppt", response.data)
      return appointment.therapist === userId;
    } catch (error) {
      console.error('Error checking therapist authorization:', error);
      return false;
    }
  };

  const DisplaySoapWithParams = (props) => {
    const { client_id, appointment_id } = useParams();
    return (
      <ProtectedRoute therapistCheck={checkTherapistAuthorization} client_id={client_id} appointment_id={appointment_id}>
        <DisplaySoap {...props} />
      </ProtectedRoute>
    );
  };

  const AddSoapNoteWithParams = (props) => {
    const { client_id, appointment_id } = useParams();
    return (
      <ProtectedRoute therapistCheck={checkTherapistAuthorization} client_id={client_id} appointment_id={appointment_id}>
        <AddSoapNote {...props} />
      </ProtectedRoute>
    );
  };


  console.log('isAuth', isAuthenticated);
  return (
    <>
       {alertMessage && <VisAlert message={alertMessage} type={alertType} />}
       <Toolbar 
        isAuthenticated={isAuthenticated} 
        username={username} 
        userId={userId} 
        isTherapist={isTherapist} 
        onLogout={handleLogout} 
      />
      <Routes>
      <Route path="/" element={<Login onLogin={handleLogin} />} />
        {/* <Route path="/" element={<ProtectedRoute><Home username={username} /></ProtectedRoute>} /> */}
        <Route path="/login" element={<Login onLogin={handleLogin} />} />
        <Route path="/register" element={<Register />} />
        <Route path="/forgotpass" element={<PasswordRec />} />
        <Route path="/verify/:confirmation_code" element={< VerifyAccount/>} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/unauthorized" element={<Unauthorized />} />
        <Route path="*" element={<NotFound />} />
        <Route path="/creates" element={<CreateSuperuser />}/>
        <Route path="/simple" element={<ProtectedRoute isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} userId={userId}><SimpleResizableCalendar /></ProtectedRoute>} />
        <Route path="/profile" element={<ProtectedRoute isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} userId={userId}><UserSettingsPage /></ProtectedRoute>} />
        {/* <Route path="/forum/inquiries" element={<ProtectedRoute><ForumPage name="Inquiries" /></ProtectedRoute>} />
        <Route path="/forum/off-topic" element={<ProtectedRoute><ForumPage name="OffTopic" /></ProtectedRoute>} />
        <Route path="/forum/billing" element={<ProtectedRoute><ForumPage name="Billing" /></ProtectedRoute>} />
        <Route path="/forum/problems" element={<ProtectedRoute><ForumPage name="Problems" /></ProtectedRoute>} />
        <Route path="/forum/tech-support" element={<ProtectedRoute><ForumPage name="TechSupport" /></ProtectedRoute>} />
        <Route path="/forum/upcoming-events" element={<ProtectedRoute><ForumPage name="UpcomingEvents" /></ProtectedRoute>} />
        <Route path="/notes/:noteId" element={<ProtectedRoute><Note /></ProtectedRoute>} /> */}
        <Route path="/schedule/:year/:month" element={<ProtectedRoute isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} userId={userId}><MainSchedPage /></ProtectedRoute>} />
        <Route path="/therapists" element={<CurrentTherapistsPage />} />
        {/* <Route path="/therapists/:therapistId/availability" element={<TherapistAvailabilityView />} />
        <Route path="/therapists/:therapistId/form" element={<TherapistAvailabilityForm />} /> */}
        {/* <Route path="/therapists/:therapistId/block_dates_form" element={<BlockedDatesForm />} /> */}
        <Route path="/therapists/:therapistId/book_therapist" element={<AppointmentBookingForm />} />
        <Route path="/soap" element={<ProtectedRoute isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} userId={userId}><MuscleMap /></ProtectedRoute>} />
        <Route path="/health-form" element={<ProtectedRoute isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} userId={userId}><HealthHistory /></ProtectedRoute>} />
        {/* <Route path="/add-service" element={<AddService />} />
        <Route path="/clinic-info" element={<ClinicInfo />} /> */}
        <Route path="/receipts" element={<ProtectedRoute isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} userId={userId}><ListReceiptsPages /></ProtectedRoute>} />
        <Route path="/receipts/:userId" element={<ProtectedRoute isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} userId={userId} ><ListReceiptsById /></ProtectedRoute>} />
        {/* <Route path="/displaysoap/:client_id/:appointment_id" element={<DisplaySoap/>} />
        <Route path="/displaysoap/add/:client_id/:appointment_id" element={<AddSoapNoteWithParams />} /> */}
      </Routes>
      <AuthErrorModal show={showModal} handleClose={handleModalClose} />
    </>
  );
};

const App = () => {
  return (
    <BrowserRouter>
      <AppContent /> {/* The main app content that needs navigation */}
    </BrowserRouter>
  );
};
export default App;
