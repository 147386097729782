import React,  { useEffect, useState, useRef }  from 'react';
import moment from 'moment-timezone';
import { Button } from 'react-bootstrap';
import '../../styles/ApptDetailsForm.css';
import greenCircle from '../../assets/green-circle.png';
import greenUnCircle from '../../assets/green-unfilled-circle.png';
import api from '../../api';
import { format, addMinutes, formatISO, parseISO } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';
// import DisplaySoap from '../../Muscle/DisplaySoap';
// import { useNavigate } from 'react-router-dom';


const ApptDetailsForm = ({
    ApptTitle,
    setApptTitle,
    ApptDuration,
    setApptDuration,
    ApptEmail,
    setApptEmail,
    selectedDate,
    handleSaveAppointment,
    handleCancel,
    isEditing = false,
    onDelete,
    searchQuery, // These are only used for booking new appointments
    setSearchQuery,
    searchResults,
    fetchUsers,
    handleUserSelect,
    selectedUser, // This indicates if an appointment is being viewed
    handleCreateNewUser, 
    newUserFormOpen,
    handleOpenNewUserForm,
    handleCloseNewUserForm,
    newUserEmail,
    setNewUserEmail,
    newUserPhone,
    setNewUserPhone,
    newUserFirstName,
    setNewUserFirstName,
    newUserLastName,
    setNewUserLastName,
    resourceId,
    err,
    requestedUser,
    setRequestedUser,
    openCheckout,
    disabledButtons,
    services = [],
    availableDurations = [],
    aptMsg = "",
    setAptMsg,
    fetchAppointments,
}) => {
    const [localServices, setLocalServices] = useState(services);
    const [localDurations, setLocalDurations] = useState(availableDurations);
    const [message, setMessage] = useState(aptMsg || "");
    const lastResourceId = useRef(null);
    const [allergies, setAllergies] = useState([]);

    useEffect(() => {
        setMessage(aptMsg); 
        setAptMsg(aptMsg);
    }, [aptMsg, setAptMsg]);

    useEffect(() => {
        if (isEditing) {
            setLocalServices(services);
            setLocalDurations(availableDurations);
        }
    }, [services, availableDurations, isEditing]);

    useEffect(() => {
        if (selectedUser && selectedUser.user_id) {
            api.get(`/api/profile/${selectedUser.user_id}`)
                .then((profileResponse) => {
                    const currentUserProfData = profileResponse.data;
                    console.log('currentUserProfData is ', currentUserProfData);
    
                    api.get(`/api/health-history/profile/${currentUserProfData.id}/`)
                        .then((response) => {
                            console.log('resp', response);
                            const healthData = response.data.health_data; // Ensure this is the correct path
                            if (healthData?.allergies) {
                                setAllergies(healthData.allergies); // Update allergies state
                            }
                        })
                        .catch((error) => {
                            console.error("Error fetching health history:", error);
                        });
                })
                .catch((error) => {
                    console.error("Error fetching user profile:", error);
                });
        }
    }, [selectedUser]);
    

    // Fetch services if not editing and resourceId changes
    useEffect(() => {
        if (!isEditing && resourceId && resourceId !== lastResourceId.current) {
            lastResourceId.current = resourceId;

            const fetchServices = async () => {
                try {
                    const response = await api.get(`/api/therapists/${resourceId}/services/`);
                    setLocalServices(response.data);
                    
                    if (response.data.length > 0) {
                        const firstService = response.data[0];
                        setApptTitle(firstService.name);
                        setLocalDurations(firstService.durations);
                        setApptDuration(firstService.durations[0]);
                    }
                } catch (error) {
                    console.error('Error fetching services:', error);
                    setLocalServices([]);
                }
            };

            fetchServices();
        }
    }, [resourceId, isEditing]);


    // Handle service selection change when isEditing is false
    const handleServiceChange = (selectedService) => {
        if (!isEditing) {
            const selected = localServices.find((service) => service.name === selectedService);
            setApptTitle(selectedService);
            setLocalDurations(selected ? selected.durations : []);
            if (selected && selected.durations.length > 0) {
                setApptDuration(selected.durations[0]);
            }
        }
    };

    const handleBlockSlot = async ({
        title,
        duration,
        startTime,
        endTime,
        resourceId,
        isBlocked
    }) => {
        try {
            console.log("blockedslot-title:", title);
            const user = JSON.parse(localStorage.getItem('user'));
            const profile = JSON.parse(localStorage.getItem('profile'));
            const { first_name, last_name } = profile;
            const username = `${first_name}, ${last_name.charAt(0)}`;
    
            const clinicId = profile.clinic;
            const userEmail = profile.email;
    
            // Remove timezone information from start and end times
            const startTimeWithoutOffset = format(
                parseISO(startTime),
                "yyyy-MM-dd'T'HH:mm:ss"
            );
            const endTimeWithoutOffset = format(
                parseISO(endTime),
                "yyyy-MM-dd'T'HH:mm:ss"
            );
    
            const dateFormatted = format(parseISO(startTime), "yyyy-MM-dd");
            // Make an API call to create the blocked slot
            await api.post(`/api/schedule/appointments/add/`, {
                teacher: user.id,
                user: user.id,
                therapist: user.id,
                start_time: startTimeWithoutOffset,
                end_time: endTimeWithoutOffset,
                title: title,
                date: dateFormatted,
                username: username,
                therapist_name: username,
                user_email: userEmail,
                appt_length: duration,
                clinic: clinicId,
                isBlocked: true,
                apt_msg: '',
                is_therapist_req: false,
                is_paid: false,
            });
            await fetchAppointments(format(parseISO(startTime), "yyyy-MM-dd"));
            console.log("SUCCESS - blocked slot created");
        } catch (error) {
            console.error('Error creating blocked slot:', error);
        }
    };
    
    
    

    const selectedDateZoned = toZonedTime(selectedDate, 'America/Toronto');
    console.log('ApptDetails, selectedDateZoned', selectedDateZoned);
    const startTimeFormatted = format(selectedDateZoned, 'HH:mm');
    console.log('ApptDetails, startTimeFormatted', startTimeFormatted);

    const endTimeFormatted = format(addMinutes(selectedDateZoned, ApptDuration), 'HH:mm');



    
    return (
        <div>
            {selectedUser && (
                <div className="user-details-container">
                    <div className="user-name">
                        Name: {selectedUser.first_name} {selectedUser.last_name}
                    </div>
                    <div className="user-username">
                        Email: {selectedUser.email}
                    </div>
                    <div className="user-phone">
                        Phone: {selectedUser.phone || 'N/A'}
                    </div>
                    {allergies && allergies.length > 0 && (
                    <div className="user-allergies" style={{ color: 'red', fontWeight: 'bold' }}>
                        Allergies: {allergies.join(', ')}
                    </div>
                )}
                </div>
            )}
            <div className="appointment-details-container">
                {selectedUser ? (
                    <>
                        <label htmlFor="apptypes" style={{ fontWeight: 'bold' }}>
                            Select Service Type:
                            <select
                                className="form-select"
                                name="apptypes"
                                id="apptypes"
                                value={ApptTitle}
                                onChange={(e) => handleServiceChange(e.target.value)}
                                style={{
                                    backgroundColor: '#f0f0f0',
                                    width: 'auto',
                                }}
                            >
                                {localServices.map((service) => (
                                    <option key={service.name} value={service.name}>
                                        {service.name}
                                    </option>
                                ))}
                            </select>
                        </label>
                        <div>
                            <label htmlFor="startTime" style={{ fontWeight: 'bold' }}>Start time:</label>
                            <input
                                type="text"
                                id="startTime"
                                value={startTimeFormatted}
                                readOnly
                                disabled
                                style={{
                                    backgroundImage: 'linear-gradient(to bottom,#fcfcfc 18%,#efefef 99%)',
                                    color: '#808080',
                                    width: `${startTimeFormatted.length}ch`,
                                    maxWidth: '100%',
                                    border: 'none',
                                    marginRight: '10px'
                                }}
                            />
                            <label htmlFor="duration" style={{ fontWeight: 'bold' }}>
                                Duration:
                                <select
                                    className="form-duration"
                                    name="duration"
                                    id="duration"
                                    value={ApptDuration}
                                    onChange={(e) => setApptDuration(parseInt(e.target.value))}
                                    style={{
                                        backgroundColor: '#f0f0f0',
                                        width: 'auto',
                                        marginLeft: '3px',
                                        padding: '5px',
                                        border: '1px solid #ccc',
                                        borderRadius: '4px'
                                    }}
                                >
                                    {localDurations.map((duration) => (
                                        <option key={duration} value={duration}>
                                            {duration} mins
                                        </option>
                                    ))}
                                </select>
                            </label>
                        </div>
                        <div>
                            <label htmlFor="endTime" style={{ fontWeight: 'bold' }}>End time:</label>
                            <input
                                type="text"
                                id="endTime"
                                value={endTimeFormatted}
                                readOnly
                                disabled
                                style={{
                                    backgroundImage: 'linear-gradient(to bottom,#fcfcfc 18%,#efefef 99%)',
                                    color: '#808080',
                                    width: `${endTimeFormatted.length}ch`,
                                    maxWidth: '100%',
                                    border: 'none',
                                }}
                            />
                        </div>
                        <div>
                            <label htmlFor="message" style={{ fontWeight: 'bold' }}>Add Message:</label>
                            <textarea
                                id="message"
                                maxLength="100"
                                value={message}
                                onChange={(e) => {
                                    const newMessage = e.target.value;
                                    setMessage(e.target.value);
                                    setAptMsg(e.target.value); // Synchronize aptMsg with message
                                    console.log('ApptDetailsForm - Updated aptMsg:', newMessage);
                                }}
                                style={{
                                    backgroundImage: 'linear-gradient(to bottom, #fcfcfc 18%, #efefef 99%)',
                                    width: '100%', // Adjust width as needed
                                    height: '80px', // Adjust height as needed
                                    resize: 'vertical' // Allows user to resize vertically only
                                }}
                            />
                        </div>

                        {(typeof requestedUser === 'boolean' && typeof setRequestedUser === 'function') && (
                            <div>
                                <p>Requested User: &nbsp;
                                {!requestedUser ? (
                                    <img 
                                        src={greenUnCircle}
                                        style={{ width: '25px', height: '25px'}}
                                        onClick={() => setRequestedUser(true)}
                                        alt="Not Requested User"
                                    />
                                ) : (
                                    <img 
                                        src={greenCircle}
                                        style={{ width: '33px', height: '25px' }}
                                        onClick={() => setRequestedUser(false)}
                                        alt="Requested User"
                                    />
                                )}
                                </p>
                            </div>
                        )}
                         {/* Debugging Log: Check the values of key props
                        {console.log("isEditing:", isEditing)}
                        {console.log("openCheckout:", openCheckout)}
                        {console.log("disabledButtons:", disabledButtons)}
                        {console.log("is_paid:", selectedUser.is_paid)} */}

                        {isEditing && openCheckout && !disabledButtons && (
                            <Button 
                                className="btn btn-danger w-100" 
                                size="sm" 
                                style={{ padding: '0.5rem 0.9rem' }} 
                                onClick={onDelete} 
                                disabled // Disabled because openCheckout is true
                            >
                                Delete Appointment
                            </Button>
                        )}

                        {isEditing && !openCheckout && !disabledButtons && (
                            <Button 
                                className="btn btn-danger w-100" 
                                size="sm" 
                                style={{ padding: '0.5rem 0.9rem' }} 
                                onClick={onDelete}
                            >
                                Delete Appointment
                            </Button>
                        )}

                        {openCheckout && !disabledButtons && (
                            <div className="save-cancel-buttons-container">
                                <Button 
                                    className="cancel-button" 
                                    size="sm" 
                                    style={{ padding: '0.5rem 0.9rem' }} 
                                    onClick={handleCancel}
                                >
                                    Cancel
                                </Button>
                                <Button 
                                    className="save-button" 
                                    size="sm" 
                                    style={{ padding: '0.5rem 0.9rem' }} 
                                    onClick={() => handleSaveAppointment({})} 
                                    disabled // Disabled because openCheckout is true
                                >
                                    Save
                                </Button>
                            </div>
                        )}

                        {!openCheckout && !disabledButtons && (
                            <div className="save-cancel-buttons-container">
                                <Button 
                                    className="cancel-button" 
                                    size="sm" 
                                    style={{ padding: '0.5rem 0.9rem' }} 
                                    onClick={handleCancel}
                                >
                                    Cancel
                                </Button>
                                <Button 
                                    className="save-button" 
                                    size="sm" 
                                    style={{ padding: '0.5rem 0.9rem' }} 
                                    onClick={() => handleSaveAppointment({
                                        title: ApptTitle,
                                        duration: ApptDuration,
                                        email: ApptEmail,
                                        startTime: moment(selectedDate).tz('America/Toronto').format(),
                                        endTime: moment(selectedDate).tz('America/Toronto').add(ApptDuration, 'minutes').format(),
                                        is_therapist_req: requestedUser,
                                        apt_msg: message,
                                    })}
                                >
                                    Save
                                </Button>
                            </div>
                        )}

                    </>
                ) : (
                    !newUserFormOpen && (
                        <div className="search-user-container">
                            <input
                                type="text"
                                value={searchQuery}
                                onChange={(e) => {
                                    setSearchQuery(e.target.value);
                                    fetchUsers(e.target.value);
                                }}
                                placeholder="Search by name"
                            />
                            {searchResults.length > 0 ? (
                                <div className="search-results">
                                    {searchResults.map((user) => (
                                        <div
                                            key={user.id}
                                            className="search-result-card"
                                            onClick={() => handleUserSelect(user)}
                                        >
                                            <div className="card-name">
                                                {user.first_name}, {user.last_name}
                                            </div>
                                            <div className="card-email">
                                                {user.email}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                <div className="create-block-container">
                                    <Button onClick={handleOpenNewUserForm}>Create/Search user</Button>
                                    <div className="block-slot-container">
                                    <Button
                                        className="block-slot-button"
                                        onClick={() => handleBlockSlot({
                                            title: 'Blocked Slot',
                                            duration: 15, // Initial duration in minutes (adjust as needed)
                                            startTime: moment(selectedDate).tz('America/Toronto').format(),
                                            endTime: moment(selectedDate).tz('America/Toronto').add(15, 'minutes').format(),
                                            resourceId, // Assuming resourceId is provided
                                            isBlocked: true,
                                        })}
                                    >
                                        Block Slot
                                    </Button>

                                </div>
                                </div>
                            )}
        
                        </div>
                    )
                )}
                {newUserFormOpen && (
                    <div className="new_user_form borderless">
                        <h2>Create new user</h2>
                        {err}
                        <form onSubmit={handleCreateNewUser}>
                            <label>Email</label>
                            <input
                                type="email"
                                placeholder="Email"
                                value={newUserEmail}
                                onChange={(e) => setNewUserEmail(e.target.value)}
                                required
                            />
                            <label>Phone</label>
                            <input
                                type="text"
                                placeholder="8 to 10 digits"
                                value={newUserPhone}
                                onChange={(e) => {
                            // Ensure only digits are entered
                                    if (/^\d{0,10}$/.test(e.target.value)) {
                                        setNewUserPhone(e.target.value);
                                    }
                                }}
                                required
                            />
                            <label>First name</label>
                            <input
                                type="text"
                                placeholder="First Name"
                                value={newUserFirstName}
                                onChange={(e) => {
                                    // Ensure only alphabetical characters are entered
                                    if (/^[A-Za-z]*$/.test(e.target.value) && e.target.value.length <= 30) {
                                        setNewUserFirstName(e.target.value);
                                    }
                                }}
                                required
                            />
                            <label>Last name</label>
                            <input
                                type="text"
                                placeholder="Last Name"
                                value={newUserLastName}
                                onChange={(e) => {
                                    // Ensure only alphabetical characters are entered
                                    if (/^[A-Za-z]*$/.test(e.target.value) && e.target.value.length <= 30) {
                                        setNewUserLastName(e.target.value);
                                    }
                                }}
                                required
                            />
                            <div className="button-container-newuser">
                                <Button className="cancel-btn-newuser" type="button" onClick={handleCloseNewUserForm}>Cancel</Button>
                                <Button className="submit-btn-newuser" type="submit" disabled={
                                    !newUserEmail.trim() || 
                                    !/^[0-9]{8,10}$/.test(newUserPhone) || 
                                    !/^[A-Za-z]{1,30}$/.test(newUserFirstName) || 
                                    !/^[A-Za-z]{1,30}$/.test(newUserLastName)
                                }>Submit</Button>
                            </div>
                        </form>
                    </div>
                )}
            </div>
        </div>
    );    
};

export default ApptDetailsForm;