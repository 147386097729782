import React from 'react';
import GlobalNotification from './GlobalNotification';
import { Link } from 'react-router-dom';
import moment from 'moment';

const Toolbar = ({ isAuthenticated, username, userId, isTherapist, onLogout }) => {
    const handleLogout = () => {
      console.log('Logging out');
      onLogout();
    };
  
    console.log('Toolbar rendering with isAuthenticated:', isAuthenticated, 'username:', username, 'isTherapist:', isTherapist, 'userId:', userId);
  
    const currentYear = moment().year();
    const currentMonth = moment().format('MMMM');
  
    return (
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container-fluid">
          <Link className="navbar-brand" to="/">MyApp</Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav">
              {isAuthenticated ? (
                <>
                  {/* <li className="nav-item">
                    <Link className="nav-link" to="/">Forum</Link>
                  </li> */}

                  <li className="nav-item">
                    <Link className="nav-link" to="/profile">Profile</Link>
                  </li>
                  <li className="nav-item">
                    <GlobalNotification /> 
                  </li>
                  {!isTherapist && userId && (
                    <>
                  <li className="nav-item">
                    <Link className="nav-link" to="/receipts">Receipts</Link>
                  </li>
                  <li className="nav-item">
                  <Link className="nav-link" to="/therapists">Therapists</Link>
                  </li>

                    </>
                  )}
                  {isTherapist && userId && (
                    <>
                  <li className="nav-item">
                    <Link className="nav-link" to={`/schedule/${currentYear}/${currentMonth}`}>Schedule</Link>
                  </li>
                  <li className="nav-item">
                  <Link className="nav-link" to="/therapists">Therapists</Link>
                  </li>
                    {/* <li className="nav-item">
                      <Link className="nav-link" to={`/therapists/${userId}/availability`}>Your Availability</Link>
                    </li> */}
                        {/* <li className="nav-item">
                        <Link className="nav-link" to={`/therapists/${userId}/block_dates_form`}>Block Availability</Link>
                    </li> */}
                    </>
                  )}
                  <li className="nav-item">
                    <Link className="nav-link" to="/logout" onClick={handleLogout}>Logout</Link>
                  </li>
                 
                </>
              ) : (
                <>
                <li className="nav-item">
                  <Link className="nav-link" to="/login">Login</Link>
                </li>

                <li className="nav-item">
                <Link className="nav-link" to="/therapists">Therapists</Link>
                </li>
                </>
              )}
            </ul>
          </div>
        </div>
      </nav>
    );
  };
  
export default Toolbar;
