import { useState, useEffect } from 'react';
import api from '../../../api';
import moment from 'moment-timezone';

const useFetchAvailability = (selectedDate) => {
    const [resources, setResources] = useState([]);
    const [specificShifts, setSpecificShifts] = useState([]); // Store specific shifts
    const [therapistAvailability, setTherapistAvailability] = useState({});
    const [noTherapistsMessage, setNoTherapistsMessage] = useState(false);

    useEffect(() => {
        const fetchAvailabilityByDay = async () => {
            try {
                const formattedDay = moment(selectedDate).tz('America/Toronto').format('dddd').toLowerCase();
                const formattedDate = moment(selectedDate).tz('America/Toronto').format('YYYY-MM-DD');
                console.log('formattedDate is', formattedDate);

                const [availabilityResponse, usersResponses, shiftsResponse] = await Promise.all([
                    api.get(`api/therapists/availability/${formattedDay}/`).catch(err => {
                        // Catch 400 error specifically and handle no availability case
                        if (err.response && err.response.status === 400) {
                            console.warn("No therapist available on this day");
                            setResources([]); // Clear resources when no availability
                            setTherapistAvailability({});
                            setNoTherapistsMessage(true);
                            return null; // Return null to skip further processing
                        }
                        throw err; // Re-throw other errors
                    }),
                    api.get('/api/profile/all/'),
                    api.get(`/api/therapists/specific-shifts/${formattedDate}/`)
                ]);

                if (!availabilityResponse) return; // Skip rest of logic if no availability

                // Process fetched data
                const availability = availabilityResponse.data;
                const usernames = usersResponses.data.reduce((acc, user) => {
                    acc[user.id] = `${user.first_name}, ${user.last_name.charAt(0)}`;
                    return acc;
                }, {});

                // Get unique therapist IDs
                const uniqueTherapists = new Set();
                availability.forEach(item => uniqueTherapists.add(item.user_id));
                shiftsResponse.data.forEach(shift => uniqueTherapists.add(shift.therapist));

                // Prepare resources
                const newResources = Array.from(uniqueTherapists).map(id => ({
                    resourceId: id,
                    resourceTitle: usernames[id] || 'Unknown',
                }));

                // Merge availability and specific shifts into working hours
                const workingHours = {};
                availability.forEach(item => {
                    const userId = item.user_id;
                    const start = parseInt(item.start_time.split(':')[0]);
                    const end = parseInt(item.end_time.split(':')[0]);

                    if (!workingHours[userId]) {
                        workingHours[userId] = { start, end };
                    } else {
                        workingHours[userId] = {
                            start: Math.min(workingHours[userId].start, start),
                            end: Math.max(workingHours[userId].end, end),
                        };
                    }
                });

                shiftsResponse.data.forEach(shift => {
                    const therapistId = shift.therapist;
                    const start = parseInt(shift.start_time.split(':')[0]);
                    const end = parseInt(shift.end_time.split(':')[0]);

                    if (!workingHours[therapistId]) {
                        workingHours[therapistId] = { start, end };
                    } else {
                        workingHours[therapistId] = {
                            start: Math.min(workingHours[therapistId].start, start),
                            end: Math.max(workingHours[therapistId].end, end),
                        };
                    }
                });

                setResources(newResources);
                setTherapistAvailability(workingHours);
                setSpecificShifts(shiftsResponse.data);
                setNoTherapistsMessage(false);
            } catch (error) {
                console.error('Error fetching therapist availability:', error);
            }
        };

        if (selectedDate) {
            fetchAvailabilityByDay();
        }
    }, [selectedDate]);

    return { resources, specificShifts, therapistAvailability, noTherapistsMessage };
};

export default useFetchAvailability;
