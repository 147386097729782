import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import api from "../../api";

const MassageServices = () => {
    const [services, setServices] = useState([{ id: null, name: '', rate_30: '', rate_45: '', rate_60: '', rate_75: '', rate_90: '' }]);

    useEffect(() => {
        const fetchServices = async () => {
            try {
                const response = await api.get('/api/services/');
                if (response.data.length === 0) {
                    setServices([{ id: null, name: '', rate_30: '', rate_45: '', rate_60: '', rate_75: '', rate_90: '' }]);
                } else {
                    setServices(response.data);
                }
            } catch (error) {
                console.error('Error fetching services:', error);
            }
        };
        fetchServices();
    }, []);

    const handleInputChange = (index, field, value) => {
        const updatedServices = [...services];
        updatedServices[index][field] = value;
        setServices(updatedServices);
    };

    const handleDelete = async (index) => {
        const serviceId = services[index].id;
        if (serviceId) {
            try {
                await api.delete(`/api/services/${serviceId}/`);
                const updatedServices = services.filter((_, i) => i !== index);
                setServices(updatedServices);
                alert('Service deleted successfully!');
            } catch (error) {
                console.error('Error deleting service:', error);
            }
        }
    };

    const handleUpdate = async () => {
        try {
            await api.put('/api/services/', { services });
            alert('Services updated successfully!');
        } catch (error) {
            console.error('Error updating services:', error);
        }
    };

    const handleAddNewRow = () => {
        setServices([...services, { id: null, name: '', rate_30: '', rate_45: '', rate_60: '', rate_75: '', rate_90: '' }]);
    };

    return (
        <div>
            <h3><strong>Massage Services</strong></h3>
            <div className="addrow-container">
                <Button className="btn btn-warning" onClick={handleAddNewRow}>Add New Row</Button>
            </div>
            <table>
                <thead>
                    <tr>
                        <th>Service Name</th>
                        <th>30 min</th>
                        <th>45 min</th>
                        <th>60 min</th>
                        <th>75 min</th>
                        <th>90 min</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {services.map((service, index) => (
                        <tr key={index}>
                            <td>
                                <input
                                    type="text"
                                    value={service.name}
                                    onChange={(e) => handleInputChange(index, 'name', e.target.value)}
                                />
                            </td>
                            <td>
                                <input
                                    type="number"
                                    value={service.rate_30}
                                    onChange={(e) => handleInputChange(index, 'rate_30', e.target.value)}
                                />
                            </td>
                            <td>
                                <input
                                    type="number"
                                    value={service.rate_45}
                                    onChange={(e) => handleInputChange(index, 'rate_45', e.target.value)}
                                />
                            </td>
                            <td>
                                <input
                                    type="number"
                                    value={service.rate_60}
                                    onChange={(e) => handleInputChange(index, 'rate_60', e.target.value)}
                                />
                            </td>
                            <td>
                                <input
                                    type="number"
                                    value={service.rate_75}
                                    onChange={(e) => handleInputChange(index, 'rate_75', e.target.value)}
                                />
                            </td>
                            <td>
                                <input
                                    type="number"
                                    value={service.rate_90}
                                    onChange={(e) => handleInputChange(index, 'rate_90', e.target.value)}
                                />
                            </td>
                            <td>
                                <button
                                    onClick={() => handleDelete(index)}
                                    style={{ color: 'red', border: 'none', background: 'none', cursor: 'pointer' }}
                                >
                                    X
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <div className="update-container">
                <Button onClick={handleUpdate}>Update</Button>
            </div>
        </div>
    );
};

export default MassageServices;