import React, { useRef, useEffect, useState } from 'react';
import api from '../../api';
import { format, parseISO} from 'date-fns';
import { toZonedTime } from 'date-fns-tz';
import MuscleMap from './MuscleMap';
import CloudinaryUploader from '../../components/CloudinaryUpload';
import Canvas from './Canvas';
import '../../styles/AddSoapNote.css';

const AddSoapNote = ({ client_id, appointment_id, onClose }) => {
  const [svgData, setSvgData] = useState(null);
  const [notes, setNotes] = useState("");
  const [appt, setAppt] = useState(null);
  const [apptRate, setApptRate] = useState(0);
  const [selectedMuscles, setSelectedMuscles] = useState([]);
  const [muscleColors, setMuscleColors] = useState({});
  const [isDrawing, setIsDrawing] = useState(false); // True for Canvas mode, False for Image mode
  const [canvasData, setCanvasData] = useState(null);
  const [imageData, setImageData] = useState(null); // State to hold image data

  const canvasRef = useRef(null);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await api.get(`api/appointments/${appointment_id}/`);
        const appointmentData = response.data;
        setAppt(appointmentData);

        if (appointmentData.title && appointmentData.appt_length) {
          const resp_rate = await api.get(`api/service-rate/${appointmentData.title}/${appointmentData.appt_length}/`);
          setApptRate(resp_rate.data.rate);
        }
      } catch (error) {
        console.error('Error fetching user data or appointment rate:', error);
      }
    };

    fetchUserData();
  }, [appointment_id]);

  useEffect(() => {
    // Directly set the Cloudinary URL for the SVG image
    const url = 'https://res.cloudinary.com/dhdn6hovg/image/upload/v1732048435/muscle2_ex6pcy.svg';
    setSvgData(url);
  }, []);

  const handleMuscleSelect = (newNote, selectedMuscle, color, side) => {
    console.log("Selected muscle:", selectedMuscle);
    console.log("Selected color:", color);
    console.log("Selected side:", side);

    const updatedMuscleColors = { ...muscleColors };
    console.log("Initial muscleColors:", updatedMuscleColors);

    // Parse the selected muscle to determine positions
    const parts = selectedMuscle.split("-");
    console.log("Parsed parts:", parts);

    const position = parts[1] && (parts[1] === "ant" || parts[1] === "post") ? parts[1] : "";
    console.log("Position detected:", position);

    const muscleName = parts.slice(position ? 2 : 1).join("-");
    console.log("Muscle name extracted:", muscleName);

    if (side === "Bilateral") {
        console.log("Handling bilateral muscle selection...");
        if (position) {
            updatedMuscleColors[`L-${position}-${muscleName}`] = color;
            updatedMuscleColors[`R-${position}-${muscleName}`] = color;
            updatedMuscleColors[`L-${position === "ant" ? "post" : "ant"}-${muscleName}`] = color;
            updatedMuscleColors[`R-${position === "ant" ? "post" : "ant"}-${muscleName}`] = color;
        } else {
            updatedMuscleColors[`L-${muscleName}`] = color;
            updatedMuscleColors[`R-${muscleName}`] = color;
        }
    } else if (side === "Left") {
        console.log("Handling left-side muscle selection...");
        if (position) {
            updatedMuscleColors[`L-${position}-${muscleName}`] = color;
            updatedMuscleColors[`L-${position === "ant" ? "post" : "ant"}-${muscleName}`] = color;
        } else {
            updatedMuscleColors[`L-${muscleName}`] = color;
        }
    } else if (side === "Right") {
        console.log("Handling right-side muscle selection...");
        if (position) {
            updatedMuscleColors[`R-${position}-${muscleName}`] = color;
            updatedMuscleColors[`R-${position === "ant" ? "post" : "ant"}-${muscleName}`] = color;
        } else {
            updatedMuscleColors[`R-${muscleName}`] = color;
        }
    }

    console.log("Updated muscleColors before state update:", updatedMuscleColors);

    setNotes((prevNotes) => {
        const updatedNotes = prevNotes === "" ? newNote : `${prevNotes}\n${newNote}`;
        console.log("Updated notes:", updatedNotes);
        return updatedNotes;
    });

    setSelectedMuscles((prevMuscles) => {
        const updatedMuscles = [...new Set([...prevMuscles, ...Object.keys(updatedMuscleColors)])];
        console.log("Updated selectedMuscles:", updatedMuscles);
        return updatedMuscles;
    });

    setMuscleColors((prevColors) => {
        const updatedColors = { ...prevColors, ...updatedMuscleColors };
        console.log("Updated muscleColors in state callback:", updatedColors);
        return updatedColors;
    });

    console.log("Final muscleColors after state update:", muscleColors);
};






  const toggleMode = () => {
    setIsDrawing(prevState => !prevState);
    setNotes("");  // Clear the notes when toggling the mode
    setSelectedMuscles([]);  // Clear selected muscles when toggling the mode
    setMuscleColors({});  // Clear muscle colors when toggling the mode
  };

  const saveCanvasToCloudinary = async (canvasDataUrl) => {
    const uploader = CloudinaryUploader(); // Initialize the Cloudinary uploader

    try {
        // Upload the canvas image to Cloudinary in the `svg/canvas` folder
        const uploadedUrl = await uploader.uploadToCloudinary(canvasDataUrl, 'svg/canvas', 'upload_profile_images');
        console.log('Canvas image uploaded to Cloudinary:', uploadedUrl);

        return uploadedUrl; // Return the Cloudinary URL
    } catch (error) {
        console.error('Error uploading canvas image to Cloudinary:', error);
        throw error;
    }
};


//   const handleSaveCanvas = async (data) => {
//     console.log('Captured Canvas Data:', data);
//     setCanvasData(data);

//     // Save the SOAP note without uploading the canvas image anywhere
//     await saveSOAPNoteWithData(data);
//   };

    const handleSaveCanvas = async (canvasData) => {
        try {
            const uploadedUrl = await saveCanvasToCloudinary(canvasData);
            await saveSOAPNoteWithData(uploadedUrl); // Save the Cloudinary URL to the DB
        } catch (error) {
            console.error('Error saving SOAP note with canvas image:', error);
        }
    };

  const handleSaveImage = (data) => {
    console.log('Captured Image Data:', data);
    setImageData(data);
  };


  const profileData = localStorage.getItem('profile');
  const parsedUserData = JSON.parse(profileData);
  console.log('addsoapnote parsedUserData', parsedUserData.clinic_id)
  console.log('appt is ', appt);

  const torontoTz = 'America/Toronto';
  // Ensure start and end times are in Toronto timezone

  const saveSOAPNoteWithData = async (canvasUrl) => {
    const torontoTz = 'America/Toronto';
    const utcTime = format(parseISO(appt?.start_time), "yyyy-MM-dd'T'HH:mm:ss'Z'");
    const formattedStartTime = format(new Date(appt?.start_time), "yyyy-MM-dd'T'HH:mm:ss");

    const requestBody = {
      therapist_name: appt?.therapist_name || '',
      client_name: appt?.username || '',
      appointment_date: utcTime,
      apt_duration: appt?.appt_length || 0,
      apt_title: appt?.title || '',
      apt_rate: apptRate,
      svg_image_data: isDrawing ? '' : 'Yes',
      notes: notes,
      muscle_data: muscleColors,
      is_drawing_mode: isDrawing,
      canvas_image: isDrawing ? canvasUrl : '',
      clinic_id: parsedUserData.clinic_id,
    };

    console.log('requestBody:', requestBody);

    try {
      await api.post(`/api/clients/${client_id}/appointments/${appointment_id}/create_soap/`, requestBody);
      console.log('SOAP Note saved successfully.');
      onClose(); // Close and return to SOAP notes list
    } catch (error) {
      console.error('Error saving SOAP Note:', error);
    }
  };

  const handleSaveButtonClick = () => {
    if (isDrawing) {
      if (canvasRef.current) {
        canvasRef.current.saveCanvas();
      }
    } else {
      saveSOAPNoteWithData(imageData);
    }
  };

  return (
    <div style={{ position: 'relative', width: '100%', backgroundColor: '#f0f0f0' }}>
      <h1>Adding a SOAP Note</h1>

      <button onClick={toggleMode} style={{ marginBottom: '20px' }}>
        {isDrawing ? 'Switch to Image Mode' : 'Switch to Draw Mode'}
      </button>

      {isDrawing && svgData ? (
        <Canvas ref={canvasRef} svgData={svgData} muscleColors={muscleColors} onSaveCanvas={handleSaveCanvas} />
      ) : (
        <MuscleMap onSelect={handleMuscleSelect} svgData={svgData} />
      )}

      <label><strong>Treatment Notes</strong></label>
      <br/>
      <textarea 
        value={notes}
        onChange={(e) => setNotes(e.target.value)}
        rows="10"
        cols="50"
        style={{ marginTop: '20px' }}
      />

      <hr/>

      <button onClick={handleSaveButtonClick} style={{ marginTop: '20px' }}>
        Save Note
      </button>
    </div>
  );
};

AddSoapNote.displayName = 'AddSoapNote';

export default AddSoapNote;
