import React, { useState, useEffect } from 'react';
import api from '../api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';  // Import FontAwesomeIcon
import { faComment } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const GlobalNotification = () => {
    const [notifications, setNotifications] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [unreadCount, setUnreadCount] = useState(0);

    useEffect(() => {
        // Fetch notifications
        const fetchNotifications = async () => {
            try {
                // Retrieve data from local storage
                const usr = JSON.parse(localStorage.getItem('user'));
                const prof = JSON.parse(localStorage.getItem('profile'));
        
                // Construct the request payload
                const response = await api.get('/api/notifications/', {
                    params: {
                        clinic_id: prof.clinic_id,
                        user_types: [usr.is_active, prof.is_therapist, usr.is_superuser]
                    }
                });
                const fetchedNotifications = response.data;
                setNotifications(fetchedNotifications);
                //const notifications = response.data;
                //console.log('Fetched Notifications:', notifications);
                const unread = fetchedNotifications.filter(notif => !notif.viewed).length;
                setUnreadCount(unread);
                // Handle notifications as needed
            } catch (error) {
                console.error('Error fetching notifications:', error);
            }
        };
        fetchNotifications();
    }, []);

    const handleNotificationClick = () => {
        setShowModal(true);
        const viewedNotificationIds = notifications.map(notif => notif.id);
    
        // Mark notifications as viewed in the backend
        api.post('/api/mark-notifications-viewed/', { notification_ids: viewedNotificationIds })
            .then(() => setUnreadCount(0))
            .catch(err => console.error('Error marking notifications as viewed:', err));
    };

    return (
        <div>
            <div style={{ position: 'relative', cursor: 'pointer' }} onClick={handleNotificationClick}>
                <FontAwesomeIcon icon={faComment} size="lg" />
                {unreadCount > 0 && (
                    <span style={{
                        position: 'absolute',
                        top: '-5px',
                        right: '-5px',
                        backgroundColor: 'red',
                        borderRadius: '50%',
                        color: 'white',
                        padding: '3px 6px',
                        fontSize: '12px'
                    }}>
                        {unreadCount}
                    </span>
                )}
            </div>

            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Notifications</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {notifications.length > 0 ? (
                        notifications.map((notif, index) => (
                            <div key={index}>
                                <p><strong>Date:</strong> {new Date(notif.created_at).toLocaleString()}</p>
                                <p><strong>Message:</strong> {notif.message}</p>
                                <hr />
                            </div>
                        ))
                    ) : (
                        <p>No notifications available.</p>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>Close</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default GlobalNotification;
