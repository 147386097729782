import React, { useEffect, useState, useRef } from 'react';
import api from '../../../api';

const imageCache = new Map(); // Use a Map for efficient key-value storage

const ImageFetcher = ({ imageUrl, alt, style }) => {
    const [imageData, setImageData] = useState(null);
    const [error, setError] = useState(null);
    const isMounted = useRef(true);

    const fetchImage = async (url) => {
        if (imageCache.has(url)) {
            setImageData(imageCache.get(url));
            return;
        }

        try {
            const response = await api.get(url, { responseType: 'blob' });
            const imageBlobUrl = URL.createObjectURL(response.data);

            if (isMounted.current) {
                imageCache.set(url, imageBlobUrl);
                setImageData(imageBlobUrl);
            }
        } catch (err) {
            setError(`Error loading image: ${alt}`);
        }
    };

    useEffect(() => {
        isMounted.current = true;
        if (imageUrl) {
            fetchImage(imageUrl);
        }
        return () => {
            isMounted.current = false;
        };
    }, [imageUrl]);

    return error ? (
        <p>{error}</p>
    ) : imageData ? (
        <img src={imageData} alt={alt} style={style} loading="lazy" />
    ) : (
        <p>Loading image...</p>
    );
};

export default React.memo(ImageFetcher);
