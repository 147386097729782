import React, { useState, useEffect } from "react";
import moment from "moment";
import api from "../api"; // Assuming you have an api utility for your requests
import "../styles/UpcomingAppt.css";


const UpcomingAppts = () => {
    const [tab, setTab] = useState('Month'); // Toggle between 'Month' and 'Year'
    const [appointments, setAppointments] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchAppointments = async () => {
            try {
                const currentUser = JSON.parse(localStorage.getItem('user'));
                const userId = currentUser.id;
        
                // Fetch all appointments for the current user and period
                const response = await api.get(`/api/appointments/user/${userId}/${tab}/`);
                const fetchedAppointments = response.data;
                
                setAppointments(fetchedAppointments);
                setLoading(false); 
            } catch (error) {
                console.error('Error fetching appointments:', error);
                setLoading(false);
            }
        };

        fetchAppointments();
    }, [tab]);


    const handleTabClick = (selectedTab) => {
        setTab(selectedTab);
    };

    // Function to group appointments by month and year
    const groupAppointmentsByMonthYear = (appointments) => {
        return appointments.reduce((groups, appt) => {
            const monthYear = moment(appt.start_time).format('MMMM YYYY');
            if (!groups[monthYear]) {
                groups[monthYear] = [];
            }
            groups[monthYear].push(appt);
            return groups;
        }, {});
    };

    const groupedAppointments = groupAppointmentsByMonthYear(appointments);

    return (
        <div>
            <div className="tab-toggle">
                <button 
                    className={`tab ${tab === 'Month' ? 'active' : ''}`} 
                    onClick={() => handleTabClick('Month')}
                >
                    Month
                </button>
                <button 
                    className={`tab ${tab === 'Year' ? 'active' : ''}`} 
                    onClick={() => handleTabClick('Year')}
                >
                    Year
                </button>
            </div>

            {loading ? (
                <p>Loading appointments...</p>
            ) : Object.keys(groupedAppointments).length === 0 ? (
                <p>No appointments found for the selected time period.</p>
            ) : (
                Object.keys(groupedAppointments).map((monthYear) => {
                    // Filter out appointments with the title 'Blocked Date'
                    const filteredAppointments = groupedAppointments[monthYear].filter(appt => appt.title !== 'Blocked Date');
            
                    // Only render the month heading if there are appointments to display
                    return filteredAppointments.length > 0 ? (
                        <div key={monthYear} className="appointment-group">
                            <h5>{monthYear}</h5>
                            {filteredAppointments.map((appt, index) => {
                                const apptTime = moment(appt.start_time).format('HH:mm');
                                const apptDetails = `(${apptTime}) ${appt.appt_length} minute ${appt.title} with ${appt.therapist_name}`;
            
                                return (
                                    <div key={index} className="appointment">
                                        <p>{apptDetails}</p>
                                    </div>
                                );
                            })}
                        </div>
                    ) : null;
                
                })
        )}
        </div>
    );
};

export default UpcomingAppts;