import React, { useState, useEffect, useCallback } from 'react';
import { Form, Button, Table  } from 'react-bootstrap';
import api from '../../api';
import InfoIcon from '../../components/InfoIcon';
import '../../styles/SpecificShiftForm.css';

const SpecificShiftForm = ({ userId, setTheMsg, setTheType }) => {
    const [date, setDate] = useState('');
    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');
    const [shifts, setShifts] = useState([]); // State for storing shifts

    // Memoize the fetchShifts function
    const fetchShifts = useCallback(async () => {
        try {
            const response = await api.get(`/api/therapists/${userId}/specific-shifts`); // Fetch shifts for the selected therapist
            setShifts(response.data); // Set the fetched shifts
        } catch (error) {
            console.error('Error fetching shifts:', error);
        }
    }, [userId]); 

    useEffect(() => {
        // Reset the form fields
        setDate('');
        setStartTime('');
        setEndTime('');
        fetchShifts(); // Fetch shifts whenever userId changes
    }, [userId, fetchShifts]); 

    


    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const shiftData = {
                therapist: userId,
                date: date,
                start_time: startTime,
                end_time: endTime
            };
            await api.post(`/api/therapists/${userId}/specific-shift/`, shiftData);
            setTheMsg('Specific shift added successfully');
            setTheType('success');
            fetchShifts(); // Fetch shifts again after adding a new one
        } catch (error) {
            setTheMsg('Error adding specific shift');
            setTheType('warning');
        }
    };

    return (
        <div style={{ paddingTop: '15px' }}>
            {/* <VisAlert message={msg} type={msgType} /> */}

            <Form onSubmit={handleSubmit}>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <InfoIcon
                        msgText="Use this Form if you would like to add a specific shift to a therapist on a selected day"
                    />
                </div>
                <Form.Label style={{ display: 'block', textAlign: 'center', fontSize: 'x-large', fontWeight: 'normal' }}>Add Specific Shift</Form.Label>
                <Form.Group controlId="date">
                    <Form.Label>Date</Form.Label>
                    <Form.Control type="date" value={date} onChange={e => setDate(e.target.value)} required />
                </Form.Group>
                <Form.Group controlId="start_time">
                    <Form.Label>Start Time</Form.Label>
                    <Form.Control type="time" value={startTime} onChange={e => setStartTime(e.target.value)} required />
                </Form.Group>
                <Form.Group controlId="end_time">
                    <Form.Label>End Time</Form.Label>
                    <Form.Control type="time" value={endTime} onChange={e => setEndTime(e.target.value)} required />
                </Form.Group>
                <div className="btn-shift">
                    <Button variant="primary" type="submit">Add Shift</Button>
                </div>
            </Form>

             <h3 style={{ paddingTop: '15px' }}>Extra Shifts Taken</h3>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Start Time</th>
                        <th>End Time</th>
                    </tr>
                </thead>
                <tbody>
                    {shifts.length === 0 ? (
                        <tr>
                            <td colSpan="3" style={{ textAlign: 'center' }}>No extra shifts scheduled for this therapist.</td>
                        </tr>
                    ) : (
                        shifts.map(shift => (
                            <tr key={shift.id}>
                                <td>{shift.date}</td>
                                <td>{shift.start_time}</td>
                                <td>{shift.end_time}</td>
                            </tr>
                        ))
                    )}
                </tbody>
            </Table>
        </div>
    );
};

export default SpecificShiftForm;
