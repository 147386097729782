const CloudinaryUploader = () => {
    const uploadToCloudinary = async (fileData, folder = '', uploadPreset = 'default_preset', publicId = null) => {
        try {
            const cloudName = import.meta.env.VITE_CLOUDINARY_CLOUD_NAME; // Cloudinary cloud name
            const formData = new FormData();
            formData.append('file', fileData);
            formData.append('upload_preset', uploadPreset); // Use specified unsigned upload preset
            if (folder) {
                formData.append('folder', folder); // Specify folder if provided
            }
            if (publicId) {
                formData.append('public_id', publicId);
            }

            const cloudinaryUrl = `https://api.cloudinary.com/v1_1/${cloudName}/image/upload`;
            const uploadResponse = await fetch(cloudinaryUrl, {
                method: 'POST',
                body: formData,
            });

            if (!uploadResponse.ok) {
                throw new Error(`Cloudinary upload failed: ${uploadResponse.statusText}`);
            }

            const responseData = await uploadResponse.json();
            console.log('File uploaded successfully:', responseData);
            return responseData.secure_url; // Return the Cloudinary URL
        } catch (error) {
            console.error('Error uploading file to Cloudinary:', error);
            throw error;
        }
    };

    return { uploadToCloudinary };
};

export default CloudinaryUploader;
