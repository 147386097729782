import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import api from "../../api";
import jsPDF from "jspdf";
import VisAlert from "../VisAlert";
import "jspdf-autotable";
import "../../styles/ListReceiptsPage.css";

const ListReceiptsById = () => {
  const { userId } = useParams();
  const [receipts, setReceipts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [pdfContent, setPdfContent] = useState(null);
  const [custName, setCustName] = useState("");
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState('error');
//   const [voidBackground, setVoidBackground] = useState(null);

  const fetchReceiptDetails = async (receipt, clinic_id) => {
    try {
      const response = await api.get("/api/receipt-details/", {
        params: {
          receipt_id: receipt.receipt_sale_id,
          clinic_id,
        },
      });

      const { receipt_totals = [], appointment = {}, clinic_info = {} } = response.data;

      const normalizedTotals = Array.isArray(receipt_totals) ? receipt_totals : [receipt_totals];

      const itemsWithTherapist = normalizedTotals.map((item) => ({
        ...item,
        therapist_name: appointment?.therapist_name || "Unknown Therapist",
      }));

      return { itemsWithTherapist, appointment, clinic_info };
    } catch (error) {
      console.error("Error fetching receipt details:", error);
      return { itemsWithTherapist: [], appointment: {}, clinic_info: {} };
    }
  };

  const handleToggleVoid = async (receipt) => {
    try {
      const response = await api.post(`/api/receipt/${receipt.receipt_sale_id}/toggle-void/`);
      const updatedReceipts = receipts.map((r) =>
        r.receipt_sale_id === receipt.receipt_sale_id ? { ...r, is_void: response.data.is_void } : r
      );
      setReceipts(updatedReceipts);

    } catch (error) {
      console.error("Error toggling void status:", error);
    }
  };

  const handleImageClick = async (receipt) => {
    try {
      const { clinic_id } = JSON.parse(localStorage.getItem("user")).profile;
      const { itemsWithTherapist, clinic_info, appointment } = await fetchReceiptDetails(receipt, clinic_id);

      const pdfBlob = await generateReceipt(
        receipt.receipt_sale_id,
        clinic_info,
        appointment.therapist_name,
        itemsWithTherapist,
        receipt
      );
      const pdfUrl = URL.createObjectURL(pdfBlob);
      setPdfContent(pdfUrl);
      setShowModal(true);
    } catch (error) {
      console.error("Error generating receipt PDF:", error);
    }
  };

  const handleEmailClick = async (receipt) => {
    try {
        const { clinic_id } = JSON.parse(localStorage.getItem("user")).profile;

        // Fetch the clinic info and receipt details
        const { itemsWithTherapist, clinic_info, appointment } = await fetchReceiptDetails(receipt, clinic_id);

        // Prepare the data for generating the PDF
        const pdfBlob = await generateReceipt(
            receipt.receipt_sale_id,
            clinic_info,
            appointment.therapist_name,
            itemsWithTherapist,
            receipt
        );

        // Prepare the email request payload
        const formData = new FormData();
        formData.append("pdf", pdfBlob, `receipt_${receipt.receipt_sale_id}.pdf`);
        formData.append("receiptData", JSON.stringify({
            receipt_sale_id: receipt.receipt_sale_id,
            clinic_name: clinic_info.name,
            customer_email: receipt.customer_email,
            customer_name: receipt.customer_name,
            sales_rep: receipt.sales_rep,
            checkout_therapist: receipt.checkout_therapist,
            date: receipt.date,
            grand_total: receipt.grand_total,
            selected_payment: receipt.selected_payment,
            is_void: receipt.is_void,
        }));
        formData.append("receiptTotalsData", JSON.stringify(itemsWithTherapist));

        // Send the email request to the backend
        const response = await api.post("/api/send-receipt-email/", formData, {
            headers: { "Content-Type": "multipart/form-data" },
        });

        if (response.status === 200) {
            setAlertMessage("Receipt email sent successfully!");
            setAlertType('success');
        } else {
            console.error("Error sending receipt email:", response.data);
            setAlertMessage("Failed to send receipt email. Please try again.");
            setAlertType('warning');
        }
    } catch (error) {
        console.error("Error in handleEmailClick:", error);
        setAlertMessage("An error occurred while sending the email.");
        setAlertType('warning');
    }
};



  const generateReceipt = async (
    receiptId,
    clinicData,
    therapistName,
    receiptTotalsData,
    receipt
  ) => {
    console.log('receipt in GR,' , receipt);
    const doc = new jsPDF({ unit: "pt", format: [227, 450] });

    const lineHeight = 14;
    const leftMargin = 15;

    const { name, address, phone } = clinicData;
    const saleDate = receipt.date || "N/A";

    let y = 95;

    if (receipt.is_void) {
        const img = new Image();
        img.src = "https://res.cloudinary.com/dhdn6hovg/image/upload/v1732544843/void_sloq7k.png";
        doc.addImage(img, "PNG", 0, 0, doc.internal.pageSize.width, doc.internal.pageSize.height);
      }

    const drawAlignedText = (label, value, yPosition, fontSize = 9) => {
      doc.setFontSize(fontSize);
      doc.text(`${label}:`, leftMargin, yPosition);
      doc.text(value || "N/A", leftMargin + 100, yPosition);
    };

    doc.setFontSize(12).setFont("Helvetica", "bold");
    doc.text(name || "Clinic Name", doc.internal.pageSize.width / 2, y, {
      align: "center",
    });
    y += lineHeight;
    doc.setFontSize(10);
    doc.text(address || "Clinic Address", doc.internal.pageSize.width / 2, y, {
      align: "center",
    });
    y += lineHeight;
    doc.text(phone || "Clinic Phone", doc.internal.pageSize.width / 2, y, {
      align: "center",
    });
    y += lineHeight * 2;

    doc.setFontSize(12).setFont("Helvetica", "normal");
    drawAlignedText("Receipt ID", receiptId.toString(), y);
    y += lineHeight;
    drawAlignedText("Therapist", therapistName, y);
    y += lineHeight;
    drawAlignedText("Sales Rep", receipt.sales_rep, y);
    y += lineHeight;
    drawAlignedText("Sale Date", saleDate, y);
    y += lineHeight * 2;

    doc.setFont("Helvetica", "bold").setFontSize(10);
    doc.text("Items:", leftMargin, y);
    y += lineHeight;

    receiptTotalsData.forEach((item) => {
      doc.setFont("Helvetica", "normal");
      doc.text(`${item.appt_length} Minute ${item.title}`, leftMargin, y);
      y += lineHeight;
      doc.text(`Rate: $${item.rate}`, leftMargin, y);
      y += lineHeight;
      doc.text(`Tax: $${item.tax}`, leftMargin, y);
      y += lineHeight;
      doc.text(`Total: $${item.total}`, leftMargin, y);
      y += lineHeight * 1.5;
    });

    doc.setFont("Helvetica", "bold");
    drawAlignedText("Grand Total", `$${receipt.grand_total}`, y);
    y += lineHeight * 2;

    doc.setFontSize(8).setFont("Helvetica", "italic");
    doc.text("Thank you for your business!", doc.internal.pageSize.width / 2, y, {
      align: "center",
    });

    return doc.output("blob");
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setPdfContent(null);
  };

  useEffect(() => {
    const fetchReceipts = async () => {
      try {
        const userData = localStorage.getItem("user");
        const { clinic_id } = JSON.parse(userData).profile;

        const userResponse = await api.get(`/api/user/${userId}/`);
        const username = userResponse.data.username;

        const receiptsResponse = await api.get("/api/user/receipts/", {
          params: { email: username, clinic_id },
        });

        setReceipts(receiptsResponse.data);

        if (receiptsResponse.data && receiptsResponse.data.length > 0) {
            const rawName = receiptsResponse.data[0].customer_name || "User";
            const formattedName = rawName.replace(",", ""); // Remove the comma
            setCustName(formattedName);
          }
       
      } catch (err) {
        console.error("Error fetching receipts:", err);
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchReceipts();
  }, [userId]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <div>
        {alertMessage && <VisAlert message={alertMessage} type={alertType} />}
      <h2>{custName}&apos;s Receipts</h2>
      {receipts.length > 0 ? (
        <table className="receipt-table">
          <thead>
            <tr>
              <th>Receipt ID</th>
              <th>Items</th>
              <th>Total</th>
              <th>Payment Method</th>
              <th>Options</th>
            </tr>
          </thead>
          <tbody>
            {receipts.map((receipt) => (
              <tr key={receipt.receipt_sale_id}>
                <td>{receipt.receipt_sale_id}</td>
                <td>
                  {receipt.items.map((item, index) => (
                    <div key={index}>
                      {item.appt_length} Minute {item.title} with {receipt.checkout_therapist}
                    </div>
                  ))}
                </td>
                <td>${receipt.grand_total}</td>
                <td>{receipt.selected_payment}</td>
                <td>
                <img
                    src="https://res.cloudinary.com/dhdn6hovg/image/upload/v1732586133/pdf-removebg-preview_yifjqa.png"
                    alt="PDF Preview"
                    onClick={() => handleImageClick(receipt)}
                    style={{ cursor: "pointer", width: "24px", height: "24px", marginRight: "5px" }}
                  />
                  <img
                    src={
                        receipt.is_void
                        ? "https://res.cloudinary.com/dhdn6hovg/image/upload/v1732545302/check-mark_uq7iki.png"
                        : "https://res.cloudinary.com/dhdn6hovg/image/upload/v1732545195/void-btn-removebg-preview_g6wuvc.png"
                    }
                    alt={receipt.is_void ? "Check Mark" : "Void Button"}
                    onClick={() => handleToggleVoid(receipt)}
                    style={{ cursor: "pointer", width: "24px", height: "24px" }}
                />
                <img
                    src="https://res.cloudinary.com/dhdn6hovg/image/upload/v1732590609/email-image_qm2wgk.png"
                    alt="Send Email"
                    onClick={() => handleEmailClick(receipt)}
                    style={{ cursor: "pointer", width: "35px", height: "35px"}}
                  />
                  
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No receipts found.</p>
      )}

      {showModal && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={handleCloseModal}>
              &times;
            </span>
            <iframe
              src={pdfContent}
              width="100%"
              height="500px"
              title="PDF Preview"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ListReceiptsById;
