import { Navigate, Outlet, useLocation } from 'react-router-dom';
import React, { useEffect, useState } from "react";
import api from "../api";

const ProtectedRoute = ({ isAuthenticated, setIsAuthenticated, userId, children }) => {
    const location = useLocation();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const checkAuthentication = async () => {
            const token = localStorage.getItem('token');  // Check token existence in local storage
            if (!token) {
                setIsAuthenticated(false);
                setLoading(false);
                return;
            }
            try {
                const response = await api.get('/api/user/current/');
                if (response.status === 200 && response.data.user) {
                    setIsAuthenticated(true);
                } else {
                    setIsAuthenticated(false);
                }
            } catch (error) {
                console.error("Error verifying user authentication:", error);
                setIsAuthenticated(false);
            }
            setLoading(false); // Finished loading
        };

        if (isAuthenticated === null) {
            checkAuthentication(); // Check only if isAuthenticated is null (not yet confirmed)
        } else {
            setLoading(false);
        }
    }, [isAuthenticated, setIsAuthenticated]);

    if (loading) {
        return <div>Loading...</div>; // Show loading spinner or message while checking authentication
    }

    if (!isAuthenticated) {
        return <Navigate to="/login" state={{ from: location }} />;
    }

    return children ? children : <Outlet />;
};

export default ProtectedRoute;
