import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import api from '../api'
import axios from 'axios';

const VerifyAccount = () => {
    const { confirmation_code } = useParams();
    const [status, setStatus] = useState(null);
    const navigate = useNavigate();



    useEffect(() => {
        const verifyAccount = async () => {
            try {
                const response = await api.get(`/verify/${confirmation_code}/`);
                if (response.status === 200) {
                    setStatus('success');
                    // Redirect after 5 seconds if successful
                    setTimeout(() => {
                        navigate('/login');
                    }, 5000);
                } else {
                    // Handle unexpected responses
                    setStatus('error');
                }
            } catch (error) {
                console.error('Error verifying account:', error);
                setStatus('error');
            }
        };
    
        verifyAccount();
    }, [confirmation_code, navigate]);

    if (status === 'success') {
        return (
            <div>
                <h4>Account activated</h4>
                <p>Your account has been activated, you can now log in.</p>
            </div>
        );
    }

    if (status === 'error') {
        return (
            <div>
                <h4>Ooops!</h4>
                <p>An error has occurred, please try again.</p>
            </div>
        );
    }

    // Optional loading state
    return <div>Loading...</div>;
};

export default VerifyAccount;