import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import api from '../../api'; // Adjust this import based on your API setup
import '../../styles/DisplaySoap.css';
import { Button } from 'react-bootstrap';
import { parseISO, format } from 'date-fns';
import { toDate } from 'date-fns-tz';
import StyledSVGContainer from './StyledSVGContainer';
import AddSoapNote from './AddSoapNote'; // Import AddSoapNote component
import NoteDisplay from './NoteDisplay'; // Import the NoteDisplay component for canvas images

const DisplaySoap = ({ clientId, appointmentId }) => {
    const [soapNotes, setSoapNotes] = useState([]);
    const [dataFetched, setDataFetched] = useState(false);
    const [showAddSoapNote, setShowAddSoapNote] = useState(false);
    const [eligableUser, setEligibleUser] = useState(false);

    useEffect(() => {
        const fetchSOAPNotes = async () => {
            if (dataFetched || !clientId) return;

            try {
                const response = await api.get(`/api/clients/${clientId}/soap_notes/`);
                console.log('Fetched SOAP notes:', response.data); 
                setSoapNotes(response.data);
            } catch (error) {
                console.error('Error fetching SOAP notes:', error);
            } finally {
                setDataFetched(true);
            }
        };

        const fetchUser = async () => {
            const profile = JSON.parse(localStorage.getItem('profile'));
            const userApp = await api.get(`api/appointments/${appointmentId}/`);
            if (userApp.data.therapist === profile.id && userApp.data.note_created === 0) {
                setEligibleUser(true);
            } else {
                setEligibleUser(false);
            }
        };

        fetchSOAPNotes();
        fetchUser();
    }, [clientId, appointmentId, dataFetched, eligableUser]);

    const handleAddNoteClick = () => {
        setShowAddSoapNote(true);
    };

    const handleCloseAddSoapNote = () => {
        setShowAddSoapNote(false);
        setDataFetched(false); // Re-fetch data when returning to the list
    };

    const timeZone = 'America/Toronto';
    
    return (
        <div className='main-soap-container'>
            <h1>SOAP Notes</h1>
            {!showAddSoapNote ? (
                <>
                    {eligableUser && 
                        <Button onClick={handleAddNoteClick}>ADD NOTE</Button>
                    }
                    {soapNotes.length > 0 && (
                        <div className="soap-notes-container">
                            <br />
                            <ul>
                                {soapNotes.map(note => {
                                    // Parse muscle_data from a string to an object
                                    const parsedMuscleData = note.muscle_data ? JSON.parse(note.muscle_data) : {};
                                    console.log('')
                                    // Parse the appointment date as a UTC ISO string
                                    const utcDate = parseISO(note.appointment_date);

                                    // Convert UTC date to America/Toronto timezone
                                    const localDate = toDate(utcDate, { timeZone: 'America/Toronto' });

                                    // Format the local date for display
                                    const formattedDate = format(localDate, 'yyyy-MM-dd HH:mm');

                                    return (
                                        <li key={note.id} className="soap-note-item">
                                            <div className="left-container">
                                                {note.canvas_image ? (
                                                    // Display canvas image using NoteDisplay component
                                                    <NoteDisplay note={note} />
                                                ) : note.svg_image_data === 'Yes' ? (
                                                    // Display StyledSVGContainer if canvas_image is not available
                                                    <StyledSVGContainer
                                                        muscleData={parsedMuscleData} // Pass parsed object here
                                                    />
                                                ) : (
                                                    <p>No visual data available</p>
                                                )}
                                            </div>
                                            <div className="right-container">
                                                <p><strong>Therapist:</strong> {note.therapist_name}</p>
                                                <p><strong>Patient:</strong> {note.client_name}</p>
                                                <p><strong>Service:</strong> {note.apt_duration} min {note.apt_title}</p>
                                                <p><strong>Cost:</strong> ${note.apt_rate}</p>
                                                <p><strong>Date:</strong> {formattedDate}</p>
                                                <p><strong>Notes</strong></p>
                                                <textarea
                                                    value={note.notes
                                                        .split('\n') // Split notes by line breaks
                                                        .map(line => line.trim()) // Trim each line
                                                        .filter(line => line) // Remove empty lines
                                                        .map(line => {
                                                            const [side, ...rest] = line.split(':'); // Split into side and condition
                                                            return `${side.trim().charAt(0).toUpperCase()}${side.trim().slice(1)}: ${rest.join(':').trim().charAt(0).toUpperCase()}${rest.join(':').trim().slice(1)}`;
                                                        })
                                                        .join('\n')} // Rejoin with new line breaks
                                                    readOnly // Make it non-editable
                                                    style={{
                                                        width: '100%',
                                                        minHeight: '100px',
                                                        resize: 'none', // Disable resizing
                                                        border: '1px solid #ccc',
                                                        borderRadius: '4px',
                                                        padding: '10px',
                                                        backgroundColor: '#f9f9f9',
                                                        fontSize: '14px',
                                                        lineHeight: '1.5',
                                                        fontFamily: 'Arial, sans-serif',
                                                        whiteSpace: 'pre-wrap', // Preserve new lines
                                                    }}
                                                />

                                            </div>
                                        </li>
                                    );
                                })}
                            </ul>

                        </div>
                    )}
                </>
            ) : (
                <AddSoapNote 
                    client_id={clientId}
                    appointment_id={appointmentId} 
                    onClose={handleCloseAddSoapNote} 
                />
            )}
        </div>
    );
};

export default DisplaySoap;
