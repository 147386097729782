import React, { useState, useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';
import api from "../../api";
import TherapistAvailabilityForm from '../Schedule/Availability/TherapistAvailabilityForm';
import SpecificShiftForm from './SpecificShiftForm';
import VisAlert from '../VisAlert';

const TherapistServices = () => {
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [isTherapist, setIsTherapist] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const [adminChecked, setAdminChecked] = useState(false);
    const [msg, setMsg] = useState('');
    const [msgType, setMsgType] = useState('');
    const [services, setServices] = useState([]); // Make sure to fetch this data as well
    const [selectedServices, setSelectedServices] = useState([]);
    const [availableDurations, setAvailableDurations] = useState({});
    const [selectedDurations, setSelectedDurations] = useState({});

    const prof = JSON.parse(localStorage.getItem('profile'))

    useEffect(() => {
        // Clear selected services and durations when selectedUser changes
        setSelectedServices([]);
        setSelectedDurations({});
    }, [selectedUser]);
    
    // Fetch users
    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const response = await api.get('/api/profiles/');
                setUsers(response.data);
            } catch (error) {
                console.error('Error fetching users:', error);
            }
        };
        fetchUsers();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Fetch all services
                const servicesResponse = await api.get('/api/services/');
                setServices(servicesResponse.data);

                // Prepare available durations based on fetched services
                const durations = {};
                servicesResponse.data.forEach(service => {
                    durations[service.id] = {
                        "30": service.rate_30 !== "0.00",
                        "45": service.rate_45 !== "0.00",
                        "60": service.rate_60 !== "0.00",
                        "75": service.rate_75 !== "0.00",
                        "90": service.rate_90 !== "0.00",
                    };
                });
                setAvailableDurations(durations);

                // If a selected user is present, fetch their saved services and durations
                if (selectedUser) {
                    const therapistServicesResponse = await api.get(`/api/therapist-services/${selectedUser.user_id}`);
                    console.log('ther', therapistServicesResponse.data);
        
                    if (therapistServicesResponse.data.length > 0) {
                        const therapistServices = therapistServicesResponse.data; // Access the first item
                        console.log('thera', therapistServices);
        
                        // Set selected services
                        const services = therapistServices.map(service => service.service_name);
                        setSelectedServices(services); 
        
                        // Set the selected durations for each service
                        const durations = {};
                        therapistServices.forEach(service => {
                            durations[service.service_name] = service.durations; // Store durations for each service
                        });
                        setSelectedDurations(durations);
                    } else {
                        console.log('No therapist services found');
                    }
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [selectedUser]);
    // Fetch services for selection (optional)
    // useEffect(() => {
    //     const fetchAllServicesAndTherapistServices = async () => {
    //         try {
    //             // First, fetch all services
    //             const allServicesResponse = await api.get('/api/services/');
    //             const allServices = allServicesResponse.data;
    //             setServices(allServices); // Set all available services
    
    //             // Then, fetch therapist's selected services (only if a user is selected)
    //             if (selectedUser?.id) {
    //                 try {
    //                     const therapistServicesResponse = await api.get(`/api/therapist-services/${selectedUser.id}/`);
    //                     const therapistServices = therapistServicesResponse.data; // Directly use response data
                
    //                     // Assuming therapistServices is a list of objects, extract available_durations
    //                     const therapistServiceNames = therapistServices.map(service => service.available_durations).flat();
    //                     console.log('services', therapistServiceNames);
    //                     setSelectedServices(therapistServiceNames); // Set selected services to the therapist's available durations
    //                 } catch (error) {
    //                     console.error('Error fetching therapist services:', error);
    //                 }
    //             }
    //         } catch (error) {
    //             console.error('Error fetching services or therapist services:', error);
    //         }
    //     };
    
    //     // Only run this effect when a user is selected
    //     if (selectedUser) {
    //         fetchAllServicesAndTherapistServices();
    //     }
    // }, [selectedUser]);  // Re-run when the selected user changes
    
    const handleUserChange = async (event) => {
        const userId = event.target.value;
        const user = users.find(user => user.user_id === parseInt(userId));

        try {
            const resp = await api.get(`/api/check-superuser/${userId}/`)
            setIsAdmin(resp.data.is_superuser);
            console.log('superuser:', resp.data);
        } catch (error) {
            console.error('Error fetching superuser');
        }

        setSelectedUser(user);
        setIsTherapist(user?.is_therapist || false);

        if (user?.is_therapist) {
            try {
                const response = await api.get(`/api/therapist-services/?therapist=${userId}/`);
                console.log('res', response.data);
                setSelectedServices(response.data.available_durations || []);
            } catch (error) {
                console.error('Error fetching therapist services:', error);
            }
        }
    };

    const formatDateToDDMMYYYY = (date) => {
        const [year, month, day] = date.split('-');
        return `${day} / ${month} / ${year}`; // Convert to DD / MM / YYYY
    };

    const handleTherapistToggle = async () => {
        if (selectedUser) {
            try {
                const updatedUser = {
                    ...selectedUser,
                    is_therapist: !isTherapist,
                    dob: formatDateToDDMMYYYY(selectedUser.dob)
                };
                await api.patch(`/api/profiles/${selectedUser.user_id}/`, updatedUser);
                setIsTherapist(!isTherapist);
                setMsg(`User ${updatedUser.is_therapist ? 'is now' : 'is no longer'} a therapist.`);
                setMsgType('caution');
            } catch (error) {
                setMsg('Error updating therapist status: (err: #416227)');
                setMsgType('warning');
            }
        }
    };

    const handleAdminToggle = async (event) => {
        if (selectedUser) {
            const isChecked = event.target.checked;
            setAdminChecked(isChecked);
            try {
                await api.patch(`/api/user/set-superuser/`, {
                    user_id: selectedUser.user_id,
                    is_superuser: isChecked  
                });
                setIsAdmin(isChecked);
                setMsg(`User ${selectedUser.email} is now ${isChecked ? 'an' : 'no longer an'} Administrator.`);
                setMsgType('caution');
            } catch (error) {
                setMsg('Error updating admin status: (err: #416329)');
                setMsgType('warning');
            }
        }
    };

    useEffect(() => {
        if (msg) {
            const timer = setTimeout(() => {
                setMsg('');
                setMsgType('');
            }, 5000);

            return () => clearTimeout(timer); // Cleanup the timer if the component unmounts
        }
    }, [msg]);

    const handleServiceChange = (serviceName) => {
        setSelectedServices((prev) => {
            if (prev.includes(serviceName)) {
                // If the service is already selected, remove it
                return prev.filter(name => name !== serviceName);
            } else {
                // Otherwise, add the service to the selected list
                return [...prev, serviceName];
            }
        });
    };

    const handleSaveServices = async () => {
        console.log('prof is ', prof);
        if (selectedUser) {
            try {
                // Prepare the payload to include therapist ID and services with durations
                const servicesDurationsPayload = {};
    
                selectedServices.forEach(service => {
                    // Assign the selected durations for each service
                    servicesDurationsPayload[service] = selectedDurations[service] || [];
                });
    
                const payload = {
                    therapist: selectedUser.user_id,
                    services_durations: servicesDurationsPayload,
                    clinic: prof.clinic // Now it maps each service to its durations
                };
    
                console.log('payload is', payload);
                await api.post('/api/therapist-services/', payload);
                setMsg('Available durations saved successfully!');
                setMsgType('success');
            } catch (error) {
                const errorMessage = error.response?.data?.detail || 'Error saving available durations.';
                setMsg(errorMessage);
                setMsgType('warning');
            }
        }
    };
    

    const handleDurationChange = (serviceName, duration) => {
        setSelectedDurations(prev => {
            // Check if the service is already in the selectedDurations object
            const currentDurations = prev[serviceName] || [];
            
            if (currentDurations.includes(duration)) {
                // If the duration is already selected, remove it
                return {
                    ...prev,
                    [serviceName]: currentDurations.filter(d => d !== duration)
                };
            } else {
                // Otherwise, add the duration to the selected list
                return {
                    ...prev,
                    [serviceName]: [...currentDurations, duration]
                };
            }
        });
    };

    return (
        <div>
            <>
                <VisAlert message={msg} type={msgType} />
            </>
            <h3><strong>Therapist Services</strong></h3>
            <label>Select user:</label>
            <Form.Control as="select" onChange={handleUserChange}>
                <option value="">-- Select User --</option>
                {users.map(user => (
                    <option key={user.user_id} value={user.user_id}>
                        {user.first_name} {user.last_name}
                    </option>
                ))}
            </Form.Control>
            {selectedUser && (
                <>
                    <p>Email: {selectedUser.email}</p>
                    <p>Admin Status: 
                        <p style={{ color: isAdmin ? 'green' : 'red' }}>
                            {isAdmin ? 'True' : 'False'}
                        </p>
                    </p>

                    <Form.Check
                        type="checkbox"
                        label="Mark as Therapist"
                        checked={isTherapist}
                        onChange={handleTherapistToggle}
                    />
                    
                    <Form.Check
                        type="checkbox"
                        label="Mark as Administrator"
                        checked={isAdmin}
                        onChange={handleAdminToggle}
                    />
                    <hr/>
                    {isTherapist && (
                        <div>
                            <TherapistAvailabilityForm userId={selectedUser.user_id} msg={setMsg} msgType={setMsgType}/>
                            <hr/>
                            <h4>Select Available Services:</h4>
                            {services.map(service => (
                                <div key={service.id}>
                                    <Form.Check
                                        type="checkbox"
                                        label={service.name}
                                        checked={selectedServices.includes(service.name)}
                                        onChange={() => handleServiceChange(service.name)}
                                    />
                                    {selectedServices.includes(service.name) && (
                                        <div style={{ marginLeft: '20px' }}>
                                            {['30', '45', '60', '75', '90'].map(duration => (
                                                <Form.Check
                                                    key={duration}
                                                    type="checkbox"
                                                    label={`${duration} minutes`}
                                                    disabled={!availableDurations[service.id][duration]} // Disable if rate is 0.00
                                                    checked={selectedDurations[service.name]?.includes(duration)}
                                                    onChange={() => handleDurationChange(service.name, duration)}
                                                />
                                            ))}
                                        </div>
                                    )}
                                </div>
                            ))}
                            <Button onClick={handleSaveServices}>Save Services</Button>
                            <hr/>
                            <SpecificShiftForm userId={selectedUser.user_id} setTheMsg={setMsg} setTheType={setMsgType} />
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default TherapistServices;
