import React, { useState, useEffect } from 'react';
import VisAlert from '../VisAlert';
import '../../styles/CreateSuperUser.css';
import api from '../../api';
import VerificationCodeModal from '../../components/VerificationCodeModal';

const CreateSuperuser = () => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [dob, setDob] = useState('');
    const [clinicName, setClinicName] = useState('');
    const [clinicAddress, setClinicAddress] = useState('');
    const [clinicCity, setClinicCity] = useState('');
    const [clinicState, setClinicState] = useState('');
    const [clinicCountry, setClinicCountry] = useState('Canada');
    const [clinicPhone, setClinicPhone] = useState('');
    const [areaCode, setAreaCode] = useState('1');
    const [timezone, setTimezone] = useState('');
    const [service, setService] = useState('free'); // Default to Free
    const [preferredSubdomain, setPreferredSubdomain] = useState('');
    const [alertMessage, setAlertMessage] = useState('');
    const [alertType, setAlertType] = useState('error');
    const [timezones, setTimezones] = useState([]);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isFormValid, setIsFormValid] = useState(false);

     // Modal state
     const [showModal, setShowModal] = useState(false);
     const [loading, setLoading] = useState(false);
     const [verificationCode, setVerificationCode] = useState(['', '', '', '', '']);
     const [attemptCount, setAttemptCount] = useState(0);

    const provincesStates = {
        Canada: ['ON', 'QC', 'BC', 'AB', 'MB', 'SK', 'NS', 'NB', 'NL', 'PE', 'NT', 'YT', 'NU'],
        USA: ['AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'FL', 'GA', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY'],
        Australia: ['NSW', 'QLD', 'SA', 'TAS', 'VIC', 'WA', 'ACT', 'NT']
      };


    useEffect(() => {
        const fetchTimezones = async () => {
        try {
            const response = await fetch("https://worldtimeapi.org/api/timezone");
            const timezoneData = await response.json();
    
            // Define a list of valid time zones for the USA, Canada, and Australia
            const validTimezones = [
            // USA
            "America/New_York",
            "America/Chicago",
            "America/Denver",
            "America/Los_Angeles",
            "America/Phoenix",
            "America/Anchorage",
            "America/Honolulu",
    
            // Canada
            "America/Toronto",
            "America/Vancouver",
            "America/Winnipeg",
            "America/Halifax",
            "America/St_Johns",
            "America/Edmonton",
            "America/Regina",
            "America/Moncton",
            "America/Whitehorse",
            "America/Iqaluit",
            "America/Yellowknife",
            "America/Thunder_Bay",
            "America/Inuvik",
    
            // Australia
            "Australia/Sydney",
            "Australia/Melbourne",
            "Australia/Brisbane",
            "Australia/Perth",
            "Australia/Adelaide",
            "Australia/Hobart",
            "Australia/Darwin",
            "Australia/Broken_Hill",
            "Australia/Lord_Howe"
            ];
    
            // Filter the time zones to include only USA, Canada, and Australia
            const filteredTimezones = timezoneData.filter((timezone) =>
            validTimezones.includes(timezone)
            );
    
            setTimezones(filteredTimezones);
        } catch (error) {
            console.error("Error fetching timezones:", error);
        }
        };
    
        fetchTimezones();
    }, []);
  
  
  

   // Validation
  const validateForm = () => {
    const nameRegex = /^[A-Za-z-]+$/;
    const subdomainRegex = /^[A-Za-z0-9]{1,30}$/;

    // First name and Last name validation
    if (!nameRegex.test(firstName) || firstName.length < 2 || firstName.length > 50) {
      setAlertMessage('First name must be 2-50 characters long, letters and hyphens only.');
      return false;
    }
    if (!nameRegex.test(lastName) || lastName.length < 2 || lastName.length > 50) {
      setAlertMessage('Last name must be 2-50 characters long, letters and hyphens only.');
      return false;
    }

    // Clinic name, address, and city validation
    if (clinicName.length < 2 || clinicName.length > 100 || !nameRegex.test(clinicName.replace(/\s/g, ''))) {
      setAlertMessage('Clinic name must be 2-100 characters long, no special characters except space and hyphens.');
      return false;
    }
    if (clinicAddress.length < 5 || clinicAddress.length > 150) {
      setAlertMessage('Clinic address must be 5-150 characters long.');
      return false;
    }
    if (clinicCity.length < 3 || clinicCity.length > 70 || !nameRegex.test(clinicCity.replace(/\s/g, ''))) {
      setAlertMessage('Clinic city must be 3-70 characters long, letters and hyphens only.');
      return false;
    }

    // Phone number validation
    if (!/^\d{10}$/.test(clinicPhone)) {
      setAlertMessage('Clinic phone must be exactly 10 digits.');
      return false;
    }

    // Preferred subdomain validation
    if (!subdomainRegex.test(preferredSubdomain)) {
      setAlertMessage('Preferred subdomain must be 1-30 alphanumeric characters, no special characters or spaces.');
      return false;
    }

    setAlertMessage('');
    setIsFormValid(true);
    return true;
  };

  useEffect(() => {
    validateForm();
  }, [firstName, lastName, clinicName, clinicAddress, clinicCity, clinicPhone, preferredSubdomain]);


  const formatDob = (date) => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day} / ${month} / ${year}`;
  };


  const handleSubmit = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
        setAlertMessage('Passwords do not match');
        setAlertType('warning');
        return;
      }

    if (!validateForm()) {
      setAlertType('warning');
      return;
    }


    const formattedDob = formatDob(new Date(dob));

    const formData = {
      first_name: firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase(),
      last_name: lastName.charAt(0).toUpperCase() + lastName.slice(1).toLowerCase(),
      email,
      dob: formattedDob,
      clinic_name: clinicName,
      clinic_address: clinicAddress,
      clinic_city: clinicCity,
      clinic_state: clinicState,
      clinic_country: clinicCountry,
      clinic_phone: clinicPhone,
      area_code: areaCode,
      timezone,
      service,
      preferred_subdomain: preferredSubdomain,
      password: password,
    };

    console.log('Form Data:', formData);
    try {
        const resp = api.post('api/superuser/create/', formData);
        console.log('Superuser created successfully', resp);
        setAlertMessage('Form submitted successfully!');
        setAlertType('success');
        setShowModal(true);
    } catch (err) {
        console.error('An error occured while creating superuser', err);
        setAlertMessage('Error occurred while creating superuser');
        setAlertType('error');
    }

  };

//   const handleSubmitVerificationCode = async (code) => {
//     setLoading(true);
//     try {
//         const isValid = await api.post('/api/verify-code/', { email, code });
//         if (isValid.data.success) {
//             // After verification, redirect to login page
//             setShowModal(false);

//             window.location.href = '/login';
//         } else {
//             setAttemptCount(attemptCount + 1);
//             if (attemptCount === 4) {
//                 setAlertMessage('You entered the wrong verification code too many times. A new verification code was sent to your email.');
//                 setAlertType('warning');
//                 await api.post('/api/send-confirmation-code/', { email });
//                 setAttemptCount(0);
//             }
//         }
//     } catch (error) {
//         console.error('Verification failed:', error);
//     } finally {
//         setLoading(false);
//     }
// };

  return (
    <>
      {alertMessage && <VisAlert message={alertMessage} type={alertType} />}
      <form onSubmit={handleSubmit}>
        <h2>Clinic Owner Registration</h2>

        {/* User Info */}
        <label>First Name:</label>
        <input type="text" value={firstName} onChange={(e) => setFirstName(e.target.value)} required />
        <br />

        <label>Last Name:</label>
        <input type="text" value={lastName} onChange={(e) => setLastName(e.target.value)} required />
        <br />

        <label>Email:</label>
        <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
        <br />

        <label>Date of Birth:</label>
        <input type="date" value={dob} onChange={(e) => setDob(e.target.value)} required />
        <br />

        {/* Clinic Info */}
        <h3>Clinic Information</h3>

        <label>Clinic Name:</label>
        <input type="text" value={clinicName} onChange={(e) => setClinicName(e.target.value)} required />
        <br />

        <label>Clinic Address:</label>
        <input type="text" value={clinicAddress} onChange={(e) => setClinicAddress(e.target.value)} required />
        <br />

        <label>Clinic City:</label>
        <input type="text" value={clinicCity} onChange={(e) => setClinicCity(e.target.value)} required />
        <br />

        <label>Clinic Country:</label>
        <select value={clinicCountry} onChange={(e) => setClinicCountry(e.target.value)} required>
          <option value="Canada">Canada</option>
          <option value="USA">USA</option>
          <option value="Australia">Australia</option>
        </select>
        <br />

        <label>Clinic Province/State:</label>
        <select value={clinicState} onChange={(e) => setClinicState(e.target.value)} required>
          <option value="">Select Province/State</option>
          {provincesStates[clinicCountry].map((state, index) => (
            <option key={index} value={state}>{state}</option>
          ))}
        </select>
        <br />

        <label>Clinic Phone:</label>
        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          <p><strong>+</strong></p><input type="text" value={areaCode} onChange={(e) => setAreaCode(e.target.value)} maxLength={4} placeholder="Area Code" style={{ width: '50px' }} />
          <input type="tel" value={clinicPhone} onChange={(e) => setClinicPhone(e.target.value)} maxLength={10} required />
        </div>
        <br />

        <label>Timezone:</label>
        <select value={timezone} onChange={(e) => setTimezone(e.target.value)} required>
          <option value="">Select a timezone</option>
          {timezones.map((tz, index) => (
            <option key={index} value={tz}>{tz}</option>
          ))}
        </select>
        <br />

        {/* Service Selection */}
        <div className="service-selection">
          <label>Service Plan:</label>
          <label className="service-option">
            <input type="radio" value="free" checked={service === 'free'} onChange={() => setService('free')} /> Free
          </label>
          <label className="service-option">
            <input type="radio" value="premium" checked={service === 'premium'} onChange={() => setService('premium')} /> Premium
          </label>
        </div>
        <br />

        {/* Subdomain */}
        <label>Preferred Subdomain:</label>
        <input type="text" value={preferredSubdomain} onChange={(e) => setPreferredSubdomain(e.target.value)} required />
        <br />

        {/* Password field */}
      <label>Password:</label>
      <input
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        required
      />
      <br />

      {/* Confirm Password field */}
      <label>Confirm Password:</label>
      <input
        type="password"
        value={confirmPassword}
        onChange={(e) => setConfirmPassword(e.target.value)}
        required
      />
      <br />

      {/* Password match warning */}
      {password && confirmPassword && password !== confirmPassword && (
        <p style={{ color: 'red' }}>Passwords do not match</p>
      )}
      <button type="submit" disabled={!isFormValid}>Register</button>
      </form>
       {/* Verification Code Modal */}
       {/* <VerificationCodeModal
                show={showModal}
                onClose={() => setShowModal(false)}
                onSubmitVerification={handleSubmitVerificationCode}
                loading={loading}
            /> */}
    </>
  );
};

export default CreateSuperuser;