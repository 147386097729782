import { useState, useEffect } from "react";
import api from "../api";
import { useNavigate, useLocation } from "react-router-dom";
import { ACCESS_TOKEN, REFRESH_TOKEN } from "../constants";
import VisAlert from '../pages/VisAlert';
import "../styles/Form.css";
import LoadingIndicator from "./LoadingIndicator";

function Form({ route, method, onLogin }) {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || '/profile';
    const [passwordValidation, setPasswordValidation] = useState({
        length: false,
        digit: false,
    });
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phone, setPhone] = useState('');
    const [agreedToTerms, setAgreedToTerms] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertType, setAlertType] = useState('error');

    const name = method === "login" ? "Login" : "Register";

    useEffect(() => {
        const length = password.length >= 5;
        const digit = /\d/.test(password);
        setPasswordValidation({ length, digit });
    }, [password]);

    const handleSubmit = async (e) => {
        setLoading(true);
        e.preventDefault();

        if (method === "register" && password !== confirmPassword) {
            setErrorMessage("Passwords do not match");
            setLoading(false);
            return;
        }

        if (!passwordValidation.length || !passwordValidation.digit) {
            setErrorMessage("Password does not meet the required criteria");
            setLoading(false);
            return;
        }

        try {
            // Build payload based on method
            let payload = { username: username, password: password };
            if (method === "register") {
                payload.profile = {
                    email: username,
                    first_name: firstName,
                    last_name: lastName,
                    first_login: 0,
                    phone: phone,
                };
            }
    
            // Handle registration and login requests
            const res = await api.post(route, payload);
            if (res.data.message === 'User exists but is inactive.') {
                // Show option to resend activation link
                setAlertMessage("Your account is not activated. Click the link below to resend the activation email.");
                setAlertType('info');
                return; // Prevent further processing
            }
    

            if (method === "login") {
                // Handle login logic
                localStorage.setItem(ACCESS_TOKEN, res.data.access);
                localStorage.setItem("REFRESH_TOKEN", res.data.refresh);
                localStorage.setItem('username', username);
                const currentUserResponse = await api.get('/api/user/current/');
                const user = currentUserResponse.data.user;
                localStorage.setItem('user', JSON.stringify(user));
                const clinicSubdomain = currentUserResponse.data.clinic_subdomain || 'default';
                localStorage.setItem('userSubdomain', clinicSubdomain);
                const profileResponse = await api.get(`/api/user/profile-data/${clinicSubdomain}/`);
                localStorage.setItem('profile', JSON.stringify(profileResponse.data));
                const superUserResponse = await api.get('/api/check-superuser/');
                localStorage.setItem('userSuperUser', superUserResponse.data.is_superuser);
                window.dispatchEvent(new CustomEvent('login'));
                onLogin(username);
                navigate(from, { replace: true });
            } else {
                // For registration, redirect to login page
                navigate("/login");
            }
        } catch (error) {
            if (error.response && error.response.data.message === 'User exists but is inactive.') {
                // Handle inactive user response from the backend
                setAlertMessage("Your account is not activated. Click below to resend the activation link.");
                setAlertType('info');
            } else {
                setAlertMessage("Login/Registration failed. Please try again.");
                setAlertType('warning');
            }
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const sendActivationLink = async () => {
        try {
            await api.post('/api/send-activation-link/', { email: username });
            setAlertMessage("A new activation link has been sent to your email.");
            setAlertType('success');
        } catch (error) {
            console.error("Error sending activation link:", error);
            setAlertMessage("Failed to send a new activation link. Please try again later.");
            setAlertType('warning');
        }
    };

    const formatName = (name) => {
        return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
    };

    return (
        <>
            {alertMessage && <VisAlert message={alertMessage} type={alertType} />}
            <form onSubmit={handleSubmit} className="form-container">
                <h1>{name}</h1>
                {errorMessage && (
                    <p style={{ color: 'red' }}>
                        {errorMessage}{" "}
                        {alertType === 'info' && (
                            <span
                                style={{ color: 'blue', cursor: 'pointer', textDecoration: 'underline' }}
                                onClick={sendActivationLink}
                            >
                                Resend Activation Link
                            </span>
                        )}
                    </p>
                )}
                <input
                    name="form-login"
                    className="form-login"
                    type="email"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    placeholder="Username"
                    required
                />
                <input
                    name="form-password"
                    className="form-password"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Password"
                    required
                />
                {method === "register" && (
                    <>
                        <input
                            name="form-confirm-password"
                            className="form-confirm-password"
                            type="password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            placeholder="Confirm Password"
                        />
                        <ul className="password-rules">
                            {!passwordValidation.length && (
                                <li>Password must be at least 8 characters in length</li>
                            )}
                            {!passwordValidation.digit && (
                                <li>Password must contain at least one digit</li>
                            )}
                        </ul>
                        <input 
                            name="form-name"
                            className="form-name"
                            type="text"
                            value={firstName}
                            onChange={(e) => {
                                const formattedName = formatName(e.target.value);
                                if (/^[A-Za-z\s]*$/.test(formattedName) && formattedName.length <= 30) {
                                    setFirstName(formattedName);
                                }
                            }}
                            placeholder="First Name"
                            required
                        />
                        <input 
                            name="form-lname"
                            className="form-lname"
                            type="text"
                            value={lastName}
                            onChange={(e) => {
                                const formattedName = formatName(e.target.value);
                                if (/^[A-Za-z\s]*$/.test(formattedName) && formattedName.length <= 30) {
                                    setLastName(formattedName);
                                }
                            }}
                            placeholder="Last Name"
                            required
                        />
                        <input 
                            name="form-phone"
                            className="form-phone"
                            type="text"
                            value={phone}
                            onChange={(e) => {
                                if (/^\d{0,10}$/.test(e.target.value) && e.target.value.length <= 10) {
                                    setPhone(e.target.value);
                                }
                            }}
                            placeholder="Phone number"
                            required
                        />
                        <div className="form-checkbox" style={{ display: 'flex', alignItems: 'center' }}>
                            <input
                                type="checkbox"
                                id="terms-checkbox"
                                checked={agreedToTerms}
                                onChange={(e) => setAgreedToTerms(e.target.checked)}
                                required
                                style={{ marginRight: '5px' }} // Add some spacing between the checkbox and label
                            />
                            <label htmlFor="terms-checkbox">I agree to the terms and conditions</label>
                        </div>

                    </>
                )}
                {loading && <LoadingIndicator />}
                <button name="form-button" className="form-button" type="submit">
                    {name}
                </button>
            </form>
        </>
    );
}

export default Form;
