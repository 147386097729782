import React from 'react';
import { Link } from 'react-router-dom';
import Form from "../components/Form"


function Login({ onLogin }) {
    return (
        <div>
            <Form route="/api/token/" method="login" onLogin={onLogin} />
            <p style={{ textAlign: 'center', fontWeight: 'bold'}}>Not registered yet? <Link to="/register">Click here</Link>.</p>
            <p style={{ textAlign: 'center', fontWeight: 'bold'}}>Forgot password? <Link to="/forgotpass">Click here</Link>.</p>
        </div>
    );
}

export default Login