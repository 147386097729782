import React, { useState, useEffect } from 'react';
import '../../styles/ClinicInfo.css';
import api from '../../api';
import CloudinaryUpload from '../../components/CloudinaryUpload';
import { Button } from 'react-bootstrap';
import axios from 'axios';


const ClinicInfo = () => {
    const [name, setName] = useState('');
    const [address, setAddress] = useState('');
    const [phone, setPhone] = useState('');
    const [areaCode, setAreaCode] = useState('');
    const [email, setEmail] = useState('');
    const [clinicState, setClinicState] = useState('');
    const [city, setCity] = useState('');
    const [country, setCountry] = useState('');
    const [logo, setLogo] = useState(null);
    const [logoUrl, setLogoUrl] = useState('');
    const [eligibleUser, setEligibleUser] = useState(false);
    const [timezone, setTimezone] = useState(localStorage.getItem('userTimezone') || '');

    const { uploadToCloudinary } = CloudinaryUpload(); // Use refactored uploader

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const response = await api.get('/api/check-superuser/');
                const { is_superuser } = response.data;
                setEligibleUser(is_superuser);
            } catch (error) {
                console.error('Error fetching user status:', error);
            }
        };
        fetchUser();
    }, []);

    useEffect(() => {
        const fetchClinicInfo = async () => {
            try {
                const response = await api.get('/api/clinic-info/');
                if (response.data) {
                    setName(response.data.name);
                    setAddress(response.data.address);
                    setPhone(response.data.phone);
                    setCity(response.data.city);
                    setAreaCode(response.data.area_code);
                    setCountry(response.data.country);
                    setClinicState(response.data.state);
                    setLogoUrl(response.data.logo || '');
                    setEmail(response.data.email);
                }
            } catch (err) {
                console.error('Failed to fetch clinic info', err);
            }
        };

        fetchClinicInfo();
    }, []);

    const handleLogoChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setLogo(file);
            const reader = new FileReader();
            reader.onloadend = () => {
                setLogoUrl(reader.result); // Temporary preview URL
            };
            reader.readAsDataURL(file);
        }
    };


    const handleSubmit = async (e) => {
        e.preventDefault();

        let uploadedLogoUrl = logoUrl;
        if (logo) {
            try {
                uploadedLogoUrl = await uploadToCloudinary(logo, 'clinic', 'upload_profile_images'); // Specify folder and upload preset
                setLogoUrl(uploadedLogoUrl);
            } catch (error) {
                console.error('Error uploading logo to Cloudinary', error);
                return; // Exit if upload fails
            }
        }

        try {
            await api.put('/api/clinic-info/', {
                name,
                address,
                phone,
                area_code: areaCode,
                state: clinicState,
                city,
                country,
                logo: uploadedLogoUrl,
                email
            });
        } catch (err) {
            console.error('Error updating clinic info', err);
        }
    };

    const timezones = [
        { value: 'UTC', label: 'UTC' },
        { value: 'America/New_York', label: 'Eastern Time (US & Canada)' },
        { value: 'Europe/London', label: 'London' },
        // Add more timezones as needed
      ];
      
      
      const handleTimezoneChange = (e) => {
          const selectedTimezone = e.target.value;
          setTimezone(selectedTimezone);
          localStorage.setItem('userTimezone', selectedTimezone);
      };


    return (
        <div>
            {eligibleUser ? (
                <div className="clinic-info-container">
                    <form className="clinic-info-form" onSubmit={handleSubmit}>
                        <label className="clinic-info-intro">Clinic Information</label>
                        {/* Form inputs for clinic info */}
                        <label>Clinic name:</label>
                        <input 
                            type="text" 
                            value={name} 
                            onChange={(e) => setName(e.target.value)} 
                            required 
                        />
                        <label>Clinic address:</label>
                        <input
                            type="text"
                            value={address}
                            onChange={(e) => setAddress(e.target.value)}
                            required
                        />
                        <label>Area Code (phone):</label>
                        <input
                            type="text"
                            value={areaCode}
                            onChange={(e) => setAreaCode(e.target.value)}
                            required
                        />
                        <label>Clinic phone:</label>
                        <input
                            type="text"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            required
                        />
                       <label>State:</label>
                        <input
                            type="text"
                            value={clinicState}
                            onChange={(e) => setClinicState(e.target.value)}
                            required
                        />
                        <label>City:</label>
                        <input
                            type="text"
                            value={city}
                            onChange={(e) => setCity(e.target.value)}
                            required
                        />
                        <label>Country:</label>
                        <input
                            type="text"
                            value={country}
                            onChange={(e) => setCountry(e.target.value)}
                            required
                        />    
                        <label>Clinic logo:</label>
                        <input
                            type="file"
                            accept="image/*"
                            onChange={handleLogoChange}
                        />
                         <label>Clinic email:</label>
                        <input
                            type="email"
                            value={email}
                             onChange={(e) => setEmail(e.target.value)}
                        />
                        <label className="logo-label">Current Logo:</label>
                        {logoUrl && (
                            <div className="logo-preview">
                                <img src={logoUrl} alt="Logo Preview" style={{ width: '200px', height: '200px' }} />
                            </div>
                        )}
                        <Button type="submit" className="submit-button">Submit</Button>
                    </form>
                    <div className="timezone-container">
                    <label>Select your timezone:</label>
                    <select value={timezone} onChange={handleTimezoneChange}>
                    {timezones.map((tz) => (
                        <option key={tz.value} value={tz.value}>
                        {tz.label}
                        </option>
                    ))}
                    </select>
                    </div>
                </div>
            ) : (
                <div>
                    <h3>Whoopsie, this is an admin-only page.</h3>
                </div>
            )}
        </div>
    );
};

export default ClinicInfo;
