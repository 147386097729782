import React, { useState, useEffect } from 'react';
import { Form, Button, Spinner } from 'react-bootstrap';
import moment from 'moment-timezone';
import api from '../api'; // Adjust the path to where your api logic resides

const BookingComp = ({ therapistId, newUsrId, usrStatus, isLoggedIn, profile, onBookingSuccess, setAlertMessage, setAlertType }) => {
    const [availableSlots, setAvailableSlots] = useState([]);
    const [selectedDate, setSelectedDate] = useState('');
    const [selectedTime, setSelectedTime] = useState('');
    const [duration, setDuration] = useState(null);
    const [title, setTitle] = useState('');
    const [services, setServices] = useState([]);
    const [isUnavailable, setIsUnavailable] = useState(false);
    const [availableDurations, setAvailableDurations] = useState([]);
    const [loading, setLoading] = useState(false);
    const [noAvailability, setNoAvailability] = useState(false);

    useEffect(() => {
        // Fetch therapist services
        const fetchServices = async () => {
            try {
                const response = await api.get(`/api/therapists/${therapistId}/services/`);
                setServices(response.data);
                if (response.data.length > 0) {
                    setTitle(response.data[0].name);
                    setAvailableDurations(response.data[0].durations);
                    setDuration(response.data[0].durations[0]);
                }
            } catch (error) {
                console.error('Error fetching services:', error);
                setServices([]);
            }
        };
        fetchServices();
    }, [therapistId]);

    useEffect(() => {
        const fetchTherapistAvailability = async () => {
            try {
                const response = await api.get(`/api/therapists/${therapistId}/availability/${selectedDate}/${duration}/`);
    
                if (response.data.message === 'Therapist not available on this day') {
                    setIsUnavailable(true); 
                    setAvailableSlots([]); // Clear available slots
                } else {
                    setIsUnavailable(false);
                    const slots = Array.isArray(response.data) ? response.data : [];
                    
                    if (slots.length === 0) {
                        setNoAvailability(true);
                        setAvailableSlots([]);
                    } else {
                    const torontoTz = 'America/Toronto';

                    // Convert the slots from UTC to Toronto time for display
                    const convertedSlots = slots.map(slot => {
                        const startTimeUtc = moment.utc(`${selectedDate} ${slot.start_time}`, 'YYYY-MM-DD HH:mm');
                        const endTimeUtc = moment.utc(`${selectedDate} ${slot.end_time}`, 'YYYY-MM-DD HH:mm');
                        
                        return {
                            start_time: startTimeUtc.tz(torontoTz).format('HH:mm'),
                            end_time: endTimeUtc.tz(torontoTz).format('HH:mm')
                        };
                    });
                    console.log('convertedSlots are', convertedSlots);
                    setAvailableSlots(convertedSlots);
                    setNoAvailability(false);
                }
                setIsUnavailable(false);
            }
            } catch (error) {
                console.error('Error fetching therapist availability:', error);
                setIsUnavailable(true);
                setNoAvailability(true);
            }
        };
    
        if (selectedDate && duration) {
            fetchTherapistAvailability();
        }
    }, [therapistId, selectedDate, duration]);

    const handleServiceChange = (e) => {
        const selectedService = services.find(service => service.name === e.target.value);
        setTitle(selectedService.name);
        setAvailableDurations(selectedService.durations);
        setDuration(selectedService.durations[0]); // Set the default duration for the selected service
    };

    const handleBookingSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
    
        let userProfile = profile; // Default to the profile from props (for logged-in users)
    
        // Fetch profile data if the user is new, inactive, or not logged in
        if (usrStatus === 'new' || usrStatus === 'inactive' || (usrStatus === 'active' && !isLoggedIn)) {
            try {
                console.log('newUsrId is ', newUsrId);
                const resp = await api.get(`/api/profile/${newUsrId}/`);  // Fetch the profile for the new/inactive user
                userProfile = resp.data;  // Save the fetched profile data
            } catch (error) {
                console.error('Error fetching user profile:', error);
                setAlertMessage('Error fetching user profile. Please try again.');
                setAlertType('danger');
                setLoading(false);
                return;
            }
        }
    
        // Prepare therapist data
        try {
            console.log('therapistId is ', therapistId);
            const therapistProfData = await api.get(`/api/profile/${therapistId}`);
            console.log('therapistProfData.data', therapistProfData.data);

            const { first_name: therapistFirstName, last_name: therapistLastName } = therapistProfData.data;
            const therapistName = `${therapistFirstName}, ${therapistLastName}`;
    
            // Prepare start and end time
            const startTime = moment.tz(`${selectedDate}T${selectedTime}:00`, 'America/Toronto');
            const endTime = startTime.clone().add(duration, 'minutes');
            const now = moment.tz('America/Toronto');
            const selectedDateTime = moment.tz(`${selectedDate}T${selectedTime}:00`, 'America/Toronto');
    
            // Check if selected time is in the past
            if (selectedDateTime.isBefore(now)) {
                setAlertMessage('You cannot book an appointment for a time that has already passed.');
                setAlertType('warning');
                setLoading(false);
                return;
            }
    
            // Prepare booking data
            const appointmentData = {
                user: userProfile.user_id,  // Use user_id from either logged-in profile or fetched profile
                teacher: therapistId,
                therapist: therapistId,
                therapist_name: therapistName,
                start_time: startTime.format('YYYY-MM-DDTHH:mm:ss'),
                end_time: endTime.format('YYYY-MM-DDTHH:mm:ss'),
                title,
                date: selectedDate,
                username: `${userProfile.first_name}, ${userProfile.last_name}`,  // Use profile's first and last name
                user_email: userProfile.email,  // Use profile's email
                appt_length: duration,
                is_therapist_req: true,
                clinic: therapistProfData.data.clinic,
                apt_msg: "", 
            };
    
            // Log appointment data for debugging
            console.log('Appointment Data:', appointmentData);
    
            // Submit the booking
            await api.post('/api/schedule/appointments/add/', appointmentData);
    
            // Notify the parent component of success
            setAlertMessage('Appointment booked successfully');
            setAlertType('success');
            onBookingSuccess();
        } catch (error) {
            console.error('Error booking appointment:', error);
            setAlertMessage('Failed to book the appointment. Please try again.');
            setAlertType('danger');
        } finally {
            setLoading(false);
        }
    };
    

    return (
        <div>
            <Form onSubmit={handleBookingSubmit}>
                <Form.Group controlId="dateSelect">
                    <Form.Label>Select Date</Form.Label>
                    <Form.Control
                        type="date"
                        value={selectedDate}
                        min={moment().format('YYYY-MM-DD')}
                        onChange={(e) => setSelectedDate(e.target.value)}
                    />
                </Form.Group>
    
                {isUnavailable ? (
                    <p>Sorry, the therapist is not available on this day. Please try selecting another date.</p>
                ) : noAvailability ? (
                    <p>Sorry, the therapist has no more availability for today.</p>
                ) : (
                    selectedDate && (
                        <>
                          {services.length > 0 && (
                                <Form.Group controlId="serviceSelect">
                                    <Form.Label>Select Service</Form.Label>
                                    <Form.Select
                                        className="select-service"
                                        value={title}
                                        onChange={handleServiceChange}
                                    >
                                        {services.map((service, index) => (
                                            <option key={index} value={service.name}>{service.name}</option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>
                            )}
                            
                            {availableDurations.length > 0 && (
                                <Form.Group controlId="durationSelect">
                                    <Form.Label>Duration:</Form.Label>
                                    <Form.Select
                                        className="select-duration"
                                        value={duration}
                                        onChange={(e) => setDuration(parseInt(e.target.value))}
                                    >
                                        {availableDurations.map((dur, index) => (
                                            <option key={index} value={dur}>{`${dur} Minutes`}</option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>
                            )}
    
                            {availableSlots.length > 0 && (
                                <Form.Group controlId="timeSelect">
                                    <Form.Label>Select Time</Form.Label>
                                    <Form.Select
                                        className="select-time"
                                        value={selectedTime}
                                        onChange={(e) => setSelectedTime(e.target.value)}
                                    >
                                        <option value="">Select Time</option>
                                        {availableSlots.map((slot, index) => (
                                            <option key={`${slot.start_time}-${index}`} value={slot.start_time}>
                                                {`${slot.start_time} - ${slot.end_time}`}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>
                            )}
    
                        
                        </>
                    )
                )}
    
                <Button 
                    type="submit" 
                    variant="primary" 
                    disabled={!selectedDate || !selectedTime || !duration || loading}
                >
                    {loading ? <Spinner animation="border" size="sm" /> : 'Book Appointment'}
                </Button>
            </Form>
        </div>
    );
};

export default BookingComp;
