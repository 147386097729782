import React, { useState, useEffect } from 'react';
import { ProgressBar, Button, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import '../../styles/HealthHistory.css';
import api from '../../api'; // Adjust the import path for your API
import VisAlert from '../VisAlert';


const HealthHistory = () => {
    const profileData = localStorage.getItem('profile');
    const parsedUserData = JSON.parse(profileData);
    const profileId = parsedUserData?.id;
    const [alertType, setAlertType] = useState('');
    const [alertMessage, setAlertMessage] = useState('');
    const [currentStep, setCurrentStep] = useState(0);
    const [progress, setProgress] = useState(25);
    const [dateUpdated, setDateUpdated] = useState(null);
    const [formData, setFormData] = useState({
        firstName: parsedUserData?.first_name || '',
        lastName: parsedUserData?.last_name || '',
        phone: parsedUserData?.phone || '',
        dob: parsedUserData?.dob || '',
        address: '',
        occupation: '',
        receivedMassageBefore: false,
        referredForMassage: false,
        hasExtendedBenefits: false,
        benefitsCompany: '',
        cardiovascular: [],
        respiratory: [],
        infections: [],
        gastrointestinal: [],
        headNeck: [],
        muscleJoint: [],
        womenConditions: [],
        otherConditions: [],
        internalPinsWires: false,
        pinsLocation: '',
        generalHealth: '',
        allergies: [],
        injuries: [],
        surgeries: [],
        massageReason: '',
        additionalNotes: '',  
        certify: false, 
        cancelPolicy: false,
    });
    useEffect(() => {
        if (!profileId) return;
        // Fetch existin health history if it exists
        const fetchHealthHistory = async () => {
            try {
                const response = await api.get(`/api/health-history/${profileId}/`);
                if (response.data && response.data.date_updated) {
                    // Populate formData with existing data if date_updated is not null
                    setFormData({
                        ...formData,
                        ...response.data.health_data,
                    });
                    setDateUpdated(response.data.date_updated);
                }
            } catch (error) {
                console.error('Error fetching health history data:', error);
            }
        };

        fetchHealthHistory();
    }, [profileId]);

    // Function to handle navigation between steps
    const nextStep = () => {
        if (currentStep === 0) {
            if (!formData.occupation || !/^[A-Za-z\s]+$/.test(formData.occupation)) {
                setAlertMessage('Please enter a valid occupation (letters only).');
                setAlertType('warning');
                return; // Prevent navigation to the next step
            }
        }

        if (currentStep === steps.length - 2 && (!formData.certify || !formData.cancelPolicy)) {
            setAlertMessage('Please agree to the terms before proceeding.');
            setAlertType('warning');
            // Prevent navigation to the next step if the agreement checkbox isn't checked
            
            return;
        }

        setCurrentStep((prevStep) => {
            const newStep = prevStep + 1;
            setProgress(((newStep + 1) / steps.length) * 100); // Update progress
            return newStep;
        });
    };

    const handleBack = () => {
        setCurrentStep((prevStep) => {
            const newStep = prevStep - 1;
            setProgress(((newStep + 1) / steps.length) * 100); // Update progress
            return newStep;
        });
    };

    // Function to handle form submission on the last slide
    const handleSubmit = async () => {
        try {
            console.log('formData healthhistory', formData);
            // Send formData to the backend
            //const response = await api.post('/api/health-history/', formData);
            //console.log('Form submitted successfully:', response.data);

            const payload = {
                profile_id: profileId,
                health_data: formData,
                clinic: parsedUserData.clinic_id
            };
            const response = await api.patch(`api/health-history/profile/${profileId}/`, payload);
            console.log('Form submitted successfully:', response.data);

            const lastStepIndex = steps.length - 1;
            setCurrentStep(lastStepIndex);
            setProgress(((lastStepIndex + 1) / steps.length) * 100);
        } catch (error) {
            console.error('Error submitting form:', error);
        }
    };

    // Function to handle checkbox change for health conditions
    const handleCheckboxChange = (category, condition, isChecked) => {
        console.log("Category:", category);
        console.log("Condition:", condition);
        console.log("Is Checked:", isChecked);
        console.log("Current FormData:", formData);
        setFormData((prevData) => {
            // Ensure the category is initialized as an array if it doesn't exist
            const categoryData = prevData[category] || [];
            return {
                ...prevData,
                [category]: isChecked
                    ? [...categoryData, condition]
                    : categoryData.filter((item) => item !== condition),
            };
        });
    };
    
    const handleAllergyChange = (value, index) => {
        // Restrict to alphabetical characters only for Allergies
        if (/^[A-Za-z]*$/.test(value) || value === '') {
            setFormData({
                ...formData,
                allergies: formData.allergies.map((a, i) => (i === index ? value : a)),
            });
        }
    };

    const isAddAllergyButtonDisabled = () => {
        // Disable the button if the last allergy input is empty
        const lastAllergy = formData.allergies[formData.allergies.length - 1];
        return formData.allergies.length > 0 && (!lastAllergy || lastAllergy.trim() === '');
    };
    
    const isAddButtonDisabled = (array) => {
        // Disable the button if the last item in the array is not filled
        if (array.length === 0) return false;
        const lastItem = array[array.length - 1];
        if (typeof lastItem === 'string') {
            return !lastItem || lastItem.trim() === '';
        }
        return !lastItem.date || !lastItem.description;
    };

    const handleCertifyChange = (e) => {
        setFormData({ ...formData, certify: e.target.checked });
    };

    const handleCancelPolicyChange = (e) => {
        setFormData({ ...formData, cancelPolicy: e.target.checked })
    }
    // Categories and conditions
    const categories = [
        {
            label: 'Cardiovascular',
            conditions: [
                'High Blood Pressure',
                'Low Blood Pressure',
                'Chronic Congestive Heart Failure',
                'Heart Attack',
                'Heart Disease',
                'Heart Murmur',
                'Angina',
                'Phlebitis / Varicose Veins',
                'Stroke / CVA',
                'Pacemaker or Similar Device',
                'Blood Clots',
                'Poor Circulation',
            ],
        },
        {
            label: 'Respiratory',
            conditions: [
                'Chronic Cough',
                'Shortness of Breath',
                'Bronchitis',
                'Asthma',
                'Emphysema',
                'Pneumonia',
                'Tuberculosis',
                'Sinusitis',
            ],
        },
        {
            label: 'Infections',
            conditions: [
                'Hepatitis A B C',
                'Skin Conditions',
                'TB',
                'HIV',
                'Herpes',
                'Warts',
                'Eczema',
                'Rashes',
            ],
        },
        {
            label: 'Gastrointestinal',
            conditions: [
                'Constipation',
                'Diarrhea',
                'Bladder Infection',
                'Kidney Infections',
                'Liver Problems',
                'Hernia',
                'Ulcers',
                'IBS (Irritable Bowel Syndrome)',
                'Nausea',
                'Urination Problems',
            ],
        },
        {
            label: 'Head/Neck',
            conditions: [
                'Vision Loss',
                'Hearing Loss',
                'Ear Pain',
                'Jaw Pain',
                'Whiplash',
                'Migraines',
                'Headaches',
            ],
        },
        {
            label: 'Muscle/Joint',
            conditions: [
                'Muscle Strain',
                'Ligament Sprain',
                'Spasms/Cramps',
                'Tendinitis',
                'Bursitis',
                'Fibromyalgia',
                'Ankylosing Spondylitis',
                'Arthritis (Rheumatoid)',
                'Arthritis (Osteo)',
                'Osteoporosis',
                'Herniated Disc',
                'Degenerative Disc',
                'Scoliosis',
                'Dislocation',
                'Fracture',
            ],
        },
        {
            label: 'Women',
            conditions: [
                'Pregnant',
                'Infertility',
                'Fibroids',
                'Menopausal Concerns',
                'Menstrual Concerns / Pain',
                'Endometriosis',
            ],
        },
        {
            label: 'Other Conditions',
            conditions: [
                'Diabetes',
                'Cancer',
                'Multiple Sclerosis (MS)',
                'Epilepsy',
                'Thyroid Disorder',
                'Lupus',
                'Loss of Sensation',
                'Insomnia/Fatigue',
                'Fainting/Dizziness',
                'Anxiety/Nervousness',
                'Depression',
                'Alcohol/Drug Addiction',
            ],
        },
    ];

    const steps = [
        {
            title: 'Personal Information',
            component: (
                <div>
                    <h3>Personal Information</h3>
                    <Form.Group controlId="firstName">
                        <Form.Label>First Name</Form.Label>
                        <Form.Control
                            type="text"
                            value={formData.firstName}
                            onChange={(e) => setFormData({ ...formData, firstName: e.target.value })}
                        />
                    </Form.Group>
                    <Form.Group controlId="lastName">
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control
                            type="text"
                            value={formData.lastName}
                            onChange={(e) => setFormData({ ...formData, lastName: e.target.value })}
                        />
                    </Form.Group>
                    <Form.Group controlId="phone">
                        <Form.Label>Phone</Form.Label>
                        <Form.Control
                            type="text"
                            value={formData.phone}
                            onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
                        />
                    </Form.Group>
                    <Form.Group controlId="dob">
                        <Form.Label>Date of Birth</Form.Label>
                        <Form.Control
                            type="date"
                            value={formData.dob}
                            onChange={(e) => setFormData({ ...formData, dob: e.target.value })}
                        />
                    </Form.Group>
                    <Form.Group controlId="address">
                        <Form.Label>Address</Form.Label>
                        <Form.Control
                            type="text"
                            value={formData.address}
                            onChange={(e) => setFormData({ ...formData, address: e.target.value })}
                        />
                    </Form.Group>
                    <Form.Group controlId="occupation">
                        <Form.Label>Occupation</Form.Label>
                        <Form.Control
                            type="text"
                            value={formData.occupation}
                            onChange={(e) => {
                                const value = e.target.value;
                                // Only allow letters and spaces
                                if (/^[A-Za-z\s]*$/.test(value) || value === '') {
                                    setFormData({ ...formData, occupation: value });
                                }
                            }}
                        />
                    </Form.Group>
                </div>
            ),
        },
        {
            title: 'Health Conditions',
            component: (
                <div>
                    <h3>Health Conditions</h3>
                    <div className="health-conditions-container">
                        {categories.map((category, index) => (
                            <div key={index} className="health-category">
                                <Form.Group>
                                    <Form.Label className="health-label">{category.label}</Form.Label>
                                    <div className="condition-group">
                                        {category.conditions.map((condition, idx) => (
                                            <Form.Check
                                                key={idx}
                                                type="checkbox"
                                                label={condition}
                                                checked={formData[category.label.toLowerCase().replace(/ /g, '')]?.includes(
                                                    condition
                                                )}
                                                onChange={(e) =>
                                                    handleCheckboxChange(
                                                        category.label.toLowerCase().replace(/ /g, ''),
                                                        condition,
                                                        e.target.checked
                                                    )
                                                }
                                            />
                                        ))}
                                    </div>
                                </Form.Group>
                            </div>
                        ))}
                    </div>
                </div>
            ),
        },
        {
            title: 'General Health',
            component: (
                <div>
                    <h3>General Information</h3>
                    <Form.Group>
                        <Form.Label>General Health:</Form.Label>
                        <Form.Check
                            type="radio"
                            name="generalHealth"
                            label="Good"
                            checked={formData.generalHealth === 'Good'}
                            onChange={() => setFormData({ ...formData, generalHealth: 'Good' })}
                        />
                        <Form.Check
                            type="radio"
                            name="generalHealth"
                            label="Fair"
                            checked={formData.generalHealth === 'Fair'}
                            onChange={() => setFormData({ ...formData, generalHealth: 'Fair' })}
                        />
                        <Form.Check
                            type="radio"
                            name="generalHealth"
                            label="Poor"
                            checked={formData.generalHealth === 'Poor'}
                            onChange={() => setFormData({ ...formData, generalHealth: 'Poor' })}
                        />
                    </Form.Group>
        
                    {/* Massage Therapy Questions */}
                    <Form.Group>
                        <Form.Label>Have you received massage therapy before?</Form.Label>
                        <Form.Check
                            type="radio"
                            name="receivedMassageBefore"
                            label="Yes"
                            checked={formData.receivedMassageBefore === true}
                            onChange={() => setFormData({ ...formData, receivedMassageBefore: true })}
                        />
                        <Form.Check
                            type="radio"
                            name="receivedMassageBefore"
                            label="No"
                            checked={formData.receivedMassageBefore === false}
                            onChange={() => setFormData({ ...formData, receivedMassageBefore: false })}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Did a health care provider refer you to massage therapy?</Form.Label>
                        <Form.Check
                            type="radio"
                            name="referredForMassage"
                            label="Yes"
                            checked={formData.referredForMassage === true}
                            onChange={() => setFormData({ ...formData, referredForMassage: true })}
                        />
                        <Form.Check
                            type="radio"
                            name="referredForMassage"
                            label="No"
                            checked={formData.referredForMassage === false}
                            onChange={() => setFormData({ ...formData, referredForMassage: false })}
                        />
                    </Form.Group>
        
                    {/* Internal Pins, Wires, etc. */}
                    <Form.Group>
                        <Form.Label>Do you have any internal pins, wires, artificial joints, or special equipment?</Form.Label>
                        <Form.Check
                            type="checkbox"
                            label="Yes"
                            checked={formData.internalPinsWires}
                            onChange={(e) =>
                                setFormData({ ...formData, internalPinsWires: e.target.checked })
                            }
                        />
                        {formData.internalPinsWires && (
                            <div className="mt-2">
                                <Form.Label>Where?</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={formData.pinsLocation}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        // Only allow alphabetical characters
                                        if (/^[A-Za-z\s]*$/.test(value)) {
                                            setFormData({ ...formData, pinsLocation: value });
                                        }
                                    }}
                                    placeholder="Enter location"
                                />
                            </div>
                        )}
                    </Form.Group>
        
                    <Form.Group>
                        <Form.Label>Allergies:</Form.Label>
                        {formData.allergies.map((allergy, index) => (
                            <div key={index} className="mb-2 d-flex align-items-center">
                                <Form.Control
                                    type="text"
                                    value={allergy}
                                    onChange={(e) => handleAllergyChange(e.target.value, index)}
                                />
                                <Button
                                    variant="danger"
                                    type="button"
                                    className="ml-2"
                                    onClick={() =>
                                        setFormData({
                                            ...formData,
                                            allergies: formData.allergies.filter((_, i) => i !== index),
                                        })
                                    }
                                >
                                    <FontAwesomeIcon icon={faTimes} />
                                </Button>
                            </div>
                        ))}
                        <Button
                            variant="primary"
                            type="button"
                            onClick={() => {
                                if (formData.allergies.length < 5) {
                                    setFormData({ ...formData, allergies: [...formData.allergies, ''] });
                                }
                            }}
                            disabled={isAddAllergyButtonDisabled()}
                        >
                            <FontAwesomeIcon icon={faPlus} />
                        </Button>
                    </Form.Group>
        
                    {/* Additional Notes */}
                    <Form.Group>
                        <Form.Label>Additional Notes:</Form.Label>
                        <Form.Control
                            as="textarea"
                            value={formData.additionalNotes || ''}
                            onChange={(e) => setFormData({ ...formData, additionalNotes: e.target.value })}
                        />
                    </Form.Group>

                    <Form.Group>
                    <Form.Check 
                        type="checkbox" 
                        label="I agree to notify the clinic within 24 hours if I cannot attend my scheduled appointment for any reason. I agree that if I do not notify the clinic within the 24 hour period I will be charged for a missed appointment."
                        required
                        checked={formData.cancelPolicy}
                        onChange={handleCancelPolicyChange}
                    />
                    </Form.Group>
                    <Form.Group>
                    <Form.Check 
                        type="checkbox" 
                        label="I agree that all of the information I have filled out is correct, if there are any changes with regards to my health I will notify the therapist as soon as possible."
                        required
                        checked={formData.certify}
                        onChange={handleCertifyChange}
                    />
                    </Form.Group>
                </div>
            ),
        },       
        {
            title: 'Thank You',
            component: (
                <div>
                    <h3>Thank You</h3>
                    <p>Thank you for completing your health history form!</p>
                </div>
            ),
        },
    ];

    return (
        <div className="health-history-container">
            <div className="progress-bar">
                <ProgressBar now={progress} label={`${progress.toFixed(0)}%`} />
            </div>
            <div className="form-container">
                {steps[currentStep].component}
                <div className="form-navigation">
                {currentStep < steps.length - 1 ? (
                    currentStep > 0 ? (
                        <>
                            <Button onClick={handleBack} className="btn btn-secondary">Back</Button>
                            <Button
                                onClick={currentStep === steps.length - 2 ? handleSubmit : nextStep}
                                className="btn btn-primary"
                                disabled={currentStep === steps.length - 2 && (!formData.certify || !formData.cancelPolicy)}
                            >
                                {currentStep === steps.length - 2 ? 'Submit' : 'Next'}
                            </Button>
                        </>
                    ) : (
                        <Button onClick={nextStep} className="btn btn-primary single-button">Next</Button>
                    )
                ) : null}
            </div>
            </div>
        </div>
    );
    
    
};

export default HealthHistory;
