import React from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import '../../../styles/SimpleResizableCalendar.css';

const localizer = momentLocalizer(moment);
const DnDCalendar = withDragAndDrop(Calendar);

const SimpleResizableCalendar = () => {
  const events = [
    {
        id: 1,
        title: 'Test Event',
        start: new Date('2024-11-17T14:00:00'),
        end: new Date('2024-11-17T16:00:00'), // 
    },
  ];

  return (
    <DnDCalendar
      step={15}
      timeslots={1}
      localizer={localizer}
      events={events}
      defaultView="day"
      resizable={true}
      style={{ height: 500 }}
      onEventResize={(data) => console.log('Resize event data:', data)}
    />
  );
};

export default SimpleResizableCalendar;
