import React, { useState, useEffect } from 'react';
import api from '../api';
import TherapistAvailabilityForm from './Schedule/Availability/TherapistAvailabilityForm';
import TherapistAvailabilityView from './Schedule/Availability/TherapistAvailabilityView';
import SquareSetupForm from './Checkout/Square/SquareSetupForm';
import BlockedDatesForm from './Schedule/Availability/BlockedDatesForm';
import AddService from './Administrative/AddService';
import ClinicInfo from './Checkout/ClinicInfo';
import MassageServices from './Administrative/MassageServices';
import RemovedAppts from './Administrative/RemovedAppts';
import TherapistServices from './Administrative/TherapistServices';
import CloudinaryUploader from '../components/CloudinaryUpload';
import UpcomingAppts from '../components/UpcomingAppts';
import HealthHistory from './HealthHistory/HealthHistory';
import axios from 'axios';
import { Button, Modal, Form, Spinner } from 'react-bootstrap';
import DatePicker from 'react-date-picker';
import { useNavigate } from 'react-router-dom';
import VisAlert from './VisAlert';
import { ACCESS_TOKEN } from '../constants';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import '../styles/UserSettingsPage.css';


const UserSettingsPage = () => {
    const [activeTab, setActiveTab] = useState('profile');
    const [adminActiveTab, setAdminActiveTab] = useState('addService');
    const [isTherapist, setIsTherapist] = useState(false);
    const [userId, setUserId] = useState(null);
    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [firstLogin, setFirstLogin] = useState('');
    const [phone, setPhone] = useState('');
    const [rawPhone, setRawPhone] = useState('');
    const [profileExists, setProfileExists] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [newEmail, setNewEmail] = useState('');
    const [emailChangeError, setEmailChangeError] = useState('');
    const [dob, setDob] = useState(null);
    const [showPhoneWarning, setShowPhoneWarning] = useState(false);
    const [profileImageUrl, setProfileImageUrl] = useState('');
    const [error, setError] = useState('');
    const [eligibleUser, setEligibleUser] = useState(false);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const { uploadToCloudinary } = CloudinaryUploader();
    const [logoUrl, setLogoUrl] = useState(localStorage.getItem('cachedProfileUrl') || ''); 
    // New state for the image file
    const [profileImage, setProfileImage] = useState(null);
    const [imageError, setImageError] = useState('');

    const [alertMessage, setAlertMessage] = useState('');
    const [alertType, setAlertType] = useState('error');
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);


    const [aptMsg, setAptMsg] = useState('');
    const [healthHistComplete, setHealthHistComplete] = useState(null);
    const [showHealthHistModal, setShowHealthHistModal] = useState(false);
    // Password Modal States
    const [showPasswordModal, setShowPasswordModal] = useState(false);
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordError, setPasswordError] = useState('');

    useEffect(() => {
        // Fetch superuser status from localStorage
        const fetchSuperuserStatus = () => {
            const userSuperUser = localStorage.getItem('userSuperUser');
            if (userSuperUser !== null) {
                setEligibleUser(JSON.parse(userSuperUser));
            }
        };
        fetchSuperuserStatus();
    }, []);
    
    useEffect(() => {
        const fetchProfileData = () => {
            try {
                const userData = localStorage.getItem('user');
                console.log('User data from localStorage:', userData);
                const profileData = localStorage.getItem('profile');
                console.log('Profile data from localStorage:', profileData);
                
                if (userData) {
                    const parsedUserData = JSON.parse(userData);
                    setUserId(parsedUserData.id);
                    setEmail(parsedUserData.username);
                }
    
                if (profileData) {
                    const parsedProfileData = JSON.parse(profileData);
                    const { first_name, last_name, dob, is_therapist, phone, first_login, image, apt_msg, healthhist_completed } = parsedProfileData;
    
                    // Set the profile data into the component's state
                    setFirstName(first_name);
                    setLastName(last_name);
                    setDob(new Date(dob)); // Assuming dob is in yyyy-mm-dd format
                    setProfileExists(true);
                    setIsTherapist(is_therapist);
                    setPhone(phone);
                    setFirstLogin(first_login);
                    setAptMsg(apt_msg);
                    setHealthHistComplete(healthhist_completed);
                    // Handle profile image (from Cloudinary)
                    if (image) {
                        const cachedImageUrl = localStorage.getItem('cachedProfileUrl');
                        if (cachedImageUrl) {
                            setProfileImageUrl(cachedImageUrl); // Use cached image
                        } else {
                            setProfileImageUrl(image); // Directly use the Cloudinary URL
                            localStorage.setItem('cachedProfileUrl', image); // Cache the Cloudinary URL
                        }
                    }
                }
    
                setLoading(false);
            } catch (error) {
                setLoading(false);
                console.error('Error loading profile data:', error);
            }
        };
    
        fetchProfileData();
    }, []);
    
    
    
    

    
    useEffect(() => {
        if (firstLogin) {
            setShowPasswordModal(true);
        }
    }, [firstLogin]);

    // Reintroduced handleToggle function
    const handleToggle = async () => {
        try {
            const response = await api.patch('/api/user/toggle-therapist-status/', null);
            setIsTherapist(response.data.is_therapist);
        } catch (error) {
            console.error('Error toggling therapist status:', error);
        }
    };

    // const formatDate = (date) => {
    //     const day = String(date.getDate()).padStart(2, '0'); // Pad day with leading zero
    //     const month = String(date.getMonth() + 1).padStart(2, '0'); // Pad month with leading zero
    //     const year = date.getFullYear();
    
    //     return `${day} / ${month} / ${year}`;
    // };

        
    // Helper function to convert a file to base64
    const convertFileToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => {
                resolve(reader.result);
            };
            reader.onerror = reject;
            reader.readAsDataURL(file);
        });
    };
    
    useEffect(() => {
        if (healthHistComplete === false) {
            // Logic to show the modal for incomplete health history
            setShowHealthHistModal(true);
        }
    }, [healthHistComplete]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const subdomain = localStorage.getItem('userSubdomain');
    
        const rawPhoneNumber = phone.replace(/\D/g, '');
        if (rawPhoneNumber.length === 0 || rawPhoneNumber.length < 10) {
            setError('Phone number is required.');
            return;
        }
        if (!email || !firstName || !lastName || !phone) {
            setErrorMessage('All fields must be filled.');
            return;
        }
    
        let formattedDate = dob ? formatDob(dob) : null;
        if (!formattedDate) {
            const today = new Date();
            formattedDate = formatDob(today);
        }
    
        setErrorMessage('');
    
        let uploadedImageUrl = logoUrl; // Default to existing logoUrl if no new image is provided
    
        // If an image file has been selected
        if (profileImage) {
            try {
                const fileData = await convertFileToBase64(profileImage); // Convert file to base64
                uploadedImageUrl = await uploadToCloudinary(fileData, 'profile_images', 'upload_profile_images', userId);
                localStorage.setItem('cachedProfileUrl', uploadedImageUrl);
            } catch (err) {
                console.error('Error uploading image:', err);
                setErrorMessage('An error occurred while uploading the image.');
                return;
            }
        }
    
        // Create a FormData object and append the data
        const formData = new FormData();
        formData.append('email', email);
        formData.append('first_name', firstName);
        formData.append('last_name', lastName);
        formData.append('dob', formattedDate);
        formData.append('is_therapist', isTherapist);
        formData.append('phone', rawPhoneNumber);
        formData.append('first_login', false);
        formData.append('image', uploadedImageUrl); // Only save the URL, not base64 data
        formData.append('apt_msg', aptMsg);
    
        try {
            // Check if the profile exists and update or create accordingly
            const profileResponse = await api.get(`/api/user/profile-data/${subdomain}/`);
            
            if (profileResponse.status === 200) {
                // Update existing profile
                await api.patch(`/api/user/profile-data/${subdomain}/`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
            } else if (profileResponse.status === 404) {
                // Create new profile
                await api.post(`/api/user/profile-data/${subdomain}/`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
            }
            setAlertMessage('User information updated successfully.');
            setAlertType('success');
        } catch (error) {
            console.error('Error updating user information:', error.response || error);
            setErrorMessage('An error occurred while updating the profile.');
        }
    };
    
    

    
    

    const handleNameChange = (setter) => (e) => {
        const { value } = e.target;
        const cleanedValue = value.replace(/[^a-zA-Z]/g, '');
        setter(cleanedValue);
    };

    const handlePhoneChange = (e) => {
        const value = e.target.value;
        // Remove non-digit characters
        const cleanedValue = value.replace(/\D/g, '');
        // Limit to 10 digits
        const limitedValue = cleanedValue.slice(0, 10);
        // Update raw phone number
        setRawPhone(limitedValue);
        // Format phone number for display
        setPhone(formatPhoneNumber(limitedValue));

        if (limitedValue.length > 0) {
            setError('');
        }
    };
   

    const formatPhoneNumber = (value) => {
        if (!value) return '';
        
        const cleaned = value.replace(/\D/g, ''); // Remove all non-digits
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        
        if (match) {
            return `(${match[1]}) ${match[2]} - ${match[3]}`;
        }
        
        return cleaned;
    };
    
    const handleEmailChangeRequest = () => {
        setShowModal(true);
    };

    const handleEmailChangeSubmit = async () => {
        try {
            const response = await api.post('/api/user/change-email/', { new_email: newEmail });
            alert(response.data.message);
            setShowModal(false);
        } catch (error) {
            if (error.response && error.response.data.message) {
                setEmailChangeError(error.response.data.message);
            } else {
                console.error('Error changing email:', error);
                setEmailChangeError('An error occurred while changing the email address.');
            }
        }
    };

    function formatDob(date) {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        const year = date.getFullYear();
    
        return `${day} / ${month} / ${year}`;
    }
    
    function handleDobChange(date) {
        setDob(date);
        console.log(formatDob(date));  // For debugging purposes
    }

    // const handleFileChange = (e) => {
    //     const file = e.target.files[0]; // Get the uploaded file
    //     const validExtensions = ['image/jpeg', 'image/jpg', 'image/png'];
        
    //     if (file && validExtensions.includes(file.type)) {
    //         const reader = new FileReader(); // Create a FileReader object to read the file
    //         reader.onloadend = () => {
    //             const base64String = reader.result; // The base64 string of the uploaded image
    //             setProfileImage(base64String); // Set the base64 string to profileImage for preview
    //         };
    //         reader.readAsDataURL(file); // Read the file as a data URL (base64)
            
    //         setImageError('');
    //     } else {
    //         setImageError('Invalid file type. Only .jpg, .jpeg, and .png files are allowed.');
    //         setProfileImage(null); // Clear the image preview if invalid
    //     }
    // };
    const handleFileChange = (e) => {
        const file = e.target.files[0]; // Get the uploaded file
        if (file) {
            setProfileImage(file); // Set the file object for uploading later
            setImageError(''); // Clear any previous errors
            
            // Create a preview URL for immediate feedback
            const previewUrl = URL.createObjectURL(file); 
            setProfileImageUrl(previewUrl); 
            console.log('preview URL', previewUrl);
            console.log('profIk', profileImage)
        } else {
            setImageError('Invalid file type. Only .jpg, .jpeg, and .png files are allowed.');
            setProfileImage(null); // Clear profile image if invalid file
        }
    };
    
    
    

    useEffect(() => {
        if (emailChangeError) {
            const timer = setTimeout(() => {
                setEmailChangeError('');
            }, 5000);

            return () => clearTimeout(timer);
        }
    }, [emailChangeError]);

    const handlePasswordSubmit = async () => {
        if (newPassword !== confirmPassword) {
            setPasswordError('Passwords do not match.');
            return;
        }
        try {
            // Assuming you have an endpoint to handle password changes
            await api.post('/api/user/change-password/', { new_password: newPassword });
            await api.patch('/api/user/profile-data/', { first_login: false });
            alert('Password updated successfully.');
            setShowPasswordModal(false);
        } catch (error) {
            console.error('Error changing password:', error);
            setPasswordError('An error occurred while changing the password.');
        }
    };

    const convertRawUrlToApiUrl = (rawUrl) => {
        const pathPart = rawUrl.split('/src/branch/main/')[1];
        const baseParts = rawUrl.split('/src/branch/')[0].split('/');
        const owner = baseParts[3];
        const repo = baseParts[4];
        return `https://gitea.com/api/v1/repos/${owner}/${repo}/contents/${pathPart}?ref=main`;
    };
    


    const formattedPhone = formatPhoneNumber(rawPhone);

    const handleImageUpload = (e) => {
        const file = e.target.files[0]; // Get the uploaded file
        if (file) {
          const reader = new FileReader();
          reader.onloadend = () => {
            const base64String = reader.result; // The base64 string of the uploaded image
            setProfileImage(base64String); // Set it for preview
          };
          reader.readAsDataURL(file); // Only use FileReader for local file upload
        }
      };

    // const redirectToAddServices = () => {
    //     navigate('/add-service');
    // };

    console.log('profimg', profileImageUrl);

    if (loading) {
        return (
            <div className="spinner-container">
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </div>
        );
    }

    console.log('Access token saved:', localStorage.getItem(ACCESS_TOKEN));

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
      };

      
    return (
        <div className="container">
            {/* Sidebar */}
            <button className="toggle-sidebar" onClick={toggleSidebar}>
                ☰ Sidebar Menu
            </button>

            <div className={`profile-sidebar ${isSidebarOpen ? 'open' : ''}`}>
            {alertMessage && <VisAlert message={alertMessage} type={alertType} />}
            <ul>
                <li>
                    <a
                        href="#profile"
                        className={activeTab === 'profile' ? 'active' : ''}
                        onClick={() => setActiveTab('profile')}
                    >
                        Profile
                    </a>
                </li>
                <li>
                    <a
                        href="#appointments"
                        className={activeTab === 'appointments' ? 'active': ''}
                        onClick={() => setActiveTab('appointments')}
                    >Appointments</a>
                </li>
                {eligibleUser && (
                    <li>
                        <a
                            href="#admin"
                            className={activeTab === 'admin' ? 'active' : ''}
                            onClick={() => setActiveTab('admin')}
                        >
                            Administrative Page
                        </a>
                        {/* Nested Admin Sidebar */}
                        {activeTab === 'admin' && (
                            <ul className="nested-sidebar">
                                <li>
                                    <a
                                        href="#clinicInfo"
                                        className={adminActiveTab === 'clinicInfo' ? 'active' : ''}
                                        onClick={() => setAdminActiveTab('clinicInfo')}
                                    >
                                        Clinic Information
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="#MassageService"
                                        className={adminActiveTab === 'MassageService' ? 'active' : ''}
                                        onClick={() => setAdminActiveTab('MassageService')}
                                    >
                                        Massage Services
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="#TherapistService"
                                        className={adminActiveTab === 'TherapistService' ? 'active' : ''}
                                        onClick={() => setAdminActiveTab('TherapistService')}
                                    >
                                        Therapist Services
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="#RemovedAppt"
                                        className={adminActiveTab === 'RemovedAppointmentData' ? 'active' : ''}
                                        onClick={() => setAdminActiveTab('RemovedAppointmentData')}
                                    >
                                        Removed Appointment Data
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="#PaymentSystem"
                                        className={adminActiveTab === 'PaymentSystem' ? 'active' : ''}
                                        onClick={() => setAdminActiveTab('PaymentSystem')}
                                    >
                                        Payment System
                                    </a>
                                </li>
                            </ul>
                        )}      
                    </li>
                    )}
                    {isTherapist && (
                        <div>
                            <li>
                                <a
                                    href="#availability"
                                    className={activeTab === 'availability' ? 'active' : ''}
                                    onClick={() => setActiveTab('availability')}
                                >
                                    Therapist Availability
                                </a>
                            </li>
                            <li>
                                <a
                                    href="#blockedDates"
                                    className={activeTab === 'blockedDates' ? 'active' : ''}
                                    onClick={() => setActiveTab('blockedDates')}
                                >
                                    Block Availability
                                </a>
                            </li>
                                </div>
                            )}
                            
                        </ul>
                    </div>


                    {/* Content */}
                    <div className="content">
                        {activeTab === 'profile' && (
                            <div>
                                <Form onSubmit={handleSubmit} encType="multipart/form-data">
                                    <Form.Label className="text-center w-100 fs-4 fst-italic">
                                        User Settings
                                    </Form.Label>
                                    <Form.Group controlId="formFirstName">
                                        <Form.Label>First Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={firstName}
                                            onChange={handleNameChange(setFirstName)}
                                            placeholder="Enter your first name"
                                        />
                                    </Form.Group>
                                    <Form.Group controlId="formLastName">
                                        <Form.Label>Last Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={lastName}
                                            onChange={handleNameChange(setLastName)}
                                            placeholder="Enter your last name"
                                        />
                                    </Form.Group>
                                    <Form.Group controlId="formDob">
                                        <Form.Label>Date of Birth</Form.Label>
                                        <DatePicker 
                                            selected={dob} 
                                            onChange={handleDobChange} 
                                            dateFormat="dd/MM/yyyy" 
                                            maxDate={new Date('2010-12-31')} 
                                            placeholderText="Select Date of Birth"
                                            showYearDropdown
                                            scrollableYearDropdown
                                            yearDropdownItemNumber={100}
                                        />
                                        {/* Display the selected date for debugging */}
                                        <p>Selected Date: {dob ? formatDob(dob) : 'None'}</p>
                                    </Form.Group>
                                    <Form.Group controlId="formPhone">
                                        <Form.Label>Phone</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={formattedPhone}
                                            onChange={handlePhoneChange}
                                            placeholder={phone}
                                            isInvalid={!!error}
                                        />
                                    </Form.Group>
                                    <Form.Group controlId="formImage">
                                    <Form.Label>Profile Image</Form.Label>
                                    <Form.Control
                                        type="file"
                                        onChange={handleFileChange}
                                        accept=".jpg, .jpeg, .png"
                                    />
                                    {imageError && <p className="text-danger">{imageError}</p>}

                                    {/* Conditionally display either the existing or newly selected image */}
                                    {profileImageUrl ? (
                                        <img src={profileImageUrl} alt="Profile Image" style={{ maxWidth: '100px', maxHeight: '100px' }} />
                                    ) : (
                                        <p>No profile image available</p>
                                    )}
                                </Form.Group>

                                    <hr />
                                    <Button type="submit">Update Profile</Button>
                                    {errorMessage && <p className="text-danger">{errorMessage}</p>}
                                </Form>

                                {/* Therapist Toggle */}
                                {/* <div className="therapist-toggle">
                                    <label>
                                        <input type="checkbox" checked={isTherapist} onChange={handleToggle} />
                                        Therapist Status
                                    </label>
                                </div> */}
                            </div>
                        )}
                    {activeTab === 'appointments' && (
                        <div className="center-container">
                            <UpcomingAppts />
                        </div>
                    )}
                    {activeTab === 'admin' && eligibleUser && adminActiveTab === 'clinicInfo' && (
                            <div className="center-container">
                                <ClinicInfo />
                            </div>
                    )}
                    {activeTab === 'admin' && eligibleUser && adminActiveTab === 'MassageService' && (
                            <div className="center-container">
                                <MassageServices />
                            </div>
                    )}
                    {activeTab === 'admin' && eligibleUser && adminActiveTab === 'TherapistService' && (
                            <div className="center-container">
                                <TherapistServices />
                            </div>
                    )}
                    {activeTab === 'admin' && eligibleUser && adminActiveTab === 'RemovedAppointmentData' && (
                            <div className="center-container">
                                <RemovedAppts />
                            </div>
                    )}
                    {activeTab === 'admin' && eligibleUser && adminActiveTab === 'PaymentSystem' && (
                            <div className="center-container">
                                <SquareSetupForm userId={userId} setAlertMsg={setAlertMessage} setAlertType={setAlertType}/>
                            </div>
                    )}


                        {/* Password Change Modal */}
                        <Modal show={showPasswordModal} onHide={() => setShowPasswordModal(false)}>
                            <Modal.Header closeButton>
                                <Modal.Title>Change Password</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form.Group controlId="formNewPassword">
                                    <Form.Label>New Password</Form.Label>
                                    <Form.Control
                                        type="password"
                                        value={newPassword}
                                        onChange={(e) => setNewPassword(e.target.value)}
                                    />
                                </Form.Group>
                                <Form.Group controlId="formConfirmPassword">
                                    <Form.Label>Confirm Password</Form.Label>
                                    <Form.Control
                                        type="password"
                                        value={confirmPassword}
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                    />
                                </Form.Group>
                                {passwordError && <p className="text-danger">{passwordError}</p>}
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={() => setShowPasswordModal(false)}>
                                    Close
                                </Button>
                                <Button variant="primary" onClick={handlePasswordSubmit}>
                                    Change Password
                                </Button>
                            </Modal.Footer>
                        </Modal>

                        {/* Email Change Modal */}
                        <Modal show={showModal} onHide={() => setShowModal(false)}>
                            <Modal.Header closeButton>
                                <Modal.Title>Change Email</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form.Group controlId="formNewEmail">
                                    <Form.Label>New Email</Form.Label>
                                    <Form.Control
                                        type="email"
                                        value={newEmail}
                                        onChange={(e) => setNewEmail(e.target.value)}
                                        placeholder="Enter new email"
                                    />
                                </Form.Group>
                                {emailChangeError && <p className="text-danger">{emailChangeError}</p>}
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={() => setShowModal(false)}>
                                    Close
                                </Button>
                                <Button variant="primary" onClick={handleEmailChangeSubmit}>
                                    Change Email
                                </Button>
                            </Modal.Footer>
                        </Modal>


                        {/* Health History Modal*/}
                        <Modal show={showHealthHistModal} onHide={() => setShowHealthHistModal(false)} size="lg">
                            <Modal.Header closeButton>
                                <Modal.Title>Health History Form</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <HealthHistory />
                            </Modal.Body>
                        </Modal>



                        {/* Display therapist availability if the user is a therapist */}
                        {activeTab === 'availability' && (
                            <div>
                                <TherapistAvailabilityForm userId={userId} />
                                <TherapistAvailabilityView />
                            </div>

                        )}

                        {activeTab == 'blockedDates' && (
                            <BlockedDatesForm />
                        )  
                        }
                    </div>
                </div>
    );
};

export default UserSettingsPage;