import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import api from '../../api';
import jsPDF from 'jspdf';
import '../../styles/CartItems.css';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import PaymentConfirmModal from "./PaymentConfirmModal";
import VisAlert from "../VisAlert";
import moment from "moment";
import { format, differenceInMinutes } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';


const CartItems = ({ items, appointment, formattedDate, onRemoveItem, alertVis, alertType}) => {
    const [listTherapists, setListTherapists] = useState([]);
    const [selectedTherapist, setSelectedTherapist] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('');
    const [paypalSuccess, setPaypalSuccess] = useState(false);
    const [paypalError, setPaypalError] = useState(null);
    //const [clinicData, setClinicData] = useState({})

    const navigate = useNavigate();


    useEffect(() => {
        const fetchAvailableTherapists = async () => {
            try {
                const response = await api.get(`/api/therapists/find_all/`);
                setListTherapists(response.data);
            } catch (error) {
                console.error('Error fetching all therapists:', error);
            }
        };
        fetchAvailableTherapists();
    }, []);

    useEffect(() => {
        const getTherapist = async () => {
            try {
                const response = await api.get(`/api/profile/${appointment.resourceId}`);
                const therFirstName = response.data.first_name;
                const therLastName = response.data.last_name;
                const therFullName = `${therFirstName} ${therLastName}`;
                setSelectedTherapist(therFullName);
            } catch (error) {
                console.error('Error getting therapist in CartItems:', error);
            }
        };
        getTherapist();
    }, [appointment.resourceId]);

    const handleTherapistChange = (event) => {
        setSelectedTherapist(event.target.value);
    };

    const calculateTotal = (price, qty, discount, discountType) => {
        let discountedPrice = price;

        if (discountType === "Percent") {
            discountedPrice = price - (price * (discount / 100));
        } else if (discountType === "Amount") {
            discountedPrice = price - discount;
        }

        return discountedPrice * qty; // Return as a number
    };

    const calculateFinalTotals = (items) => {
        const totals = items.map(item => {
            const total = parseFloat(calculateTotal(item.rate || 0, item.qty || 1, item.discount || 0.0, item.discountType || 'Percent'));
            const tax = total * 0.13; // 13% tax
            const finalTotal = total + tax;
            return {
                ...item,
                total: total.toFixed(2),
                tax: tax.toFixed(2),
                finalTotal: parseFloat(finalTotal.toFixed(2)) // Ensure finalTotal is formatted as a number
            };
        });
        return totals;
    };

    const totals = calculateFinalTotals(items);

    const grandTotal = totals.reduce((acc, item) => acc + item.finalTotal, 0).toFixed(2);


    const openModal = (method) => {
        setSelectedPaymentMethod(method);
        setShowModal(true);
        console.log(`Opening modal for payment method: ${method}`);
    };

    const closeModal = () => {
        setShowModal(false);
        setSelectedPaymentMethod('');
        console.log('Modal closed'); 
    };

    // const confirmPayment = async () => {
    //     try {
    //         // Define the fields to update
    //         const generated_math_num = Math.floor(Math.random() * 1000000)
    //         const updatedFields = {
    //             is_paid: 1,
    //             receipt_sale_id: generated_math_num
    //         };

    //         const resp = await api.get('/api/clinic-info/')
    //         const dat = resp.data
    //         // Perform the PATCH request to update the appointment
    //         //await api.patch(`/api/schedule/appointments/${appointment.id}/`, updatedFields);
    
    //         console.log(`Confirmed payment with ${selectedPaymentMethod}`);

    //         //setClinicData(resp.data)
    //         const receiptData = {
    //             appointmentId: appointment.id,
    //             username: appointment.username,
    //             items, // List of items
    //             totals, // List of totals
    //             grandTotal, // Grand total
    //             selectedPaymentMethod, // Payment method
    //             generated_math_num, 
    //             dat// Receipt number
    //         };

    //         console.log('receiptDataaa is', receiptData);
    //         //await api.post('/api/generate-receipt/', receiptData);
    
    //         // Optionally generate receipt
    //         //generateReceipt(generated_math_num, resp.data);
    
    //         // Close the modal
    //         closeModal();
    //     } catch (error) {
    //         console.error('Error updating appointment:', error.response ? error.response.data : error.message);
    //         alert('Error updating appointment. Please try again.');
    //     }
    // };

    const confirmPayment = async () => {
        try {
            const profileData = localStorage.getItem('profile');
            const parsedProfileData = JSON.parse(profileData);
            const { first_name, last_name, clinic_id } = parsedProfileData;
            const generated_math_num = Math.floor(Math.random() * 1000000);
            const clinicResponse = await api.get(`/api/clinic-info/`, { params: { clinic_id: clinic_id } });
            const clinicInfo = clinicResponse.data;
            console.log('clinicInfo', clinicInfo);
            const fullUrl = window.location.origin;
            const salesRep = `${first_name}, ${last_name}`;
            const torontoTz = 'America/Toronto';
           // Ensure start and end times are in Toronto timezone
            const startToronto = format(toZonedTime(appointment.start, torontoTz), 'yyyy-MM-dd\'T\'HH:mm:ss');
            const endToronto = format(toZonedTime(appointment.end, torontoTz), 'yyyy-MM-dd\'T\'HH:mm:ss');


            const receiptData = {
                appointment: appointment.id,
                grand_total: parseFloat(grandTotal),
                selected_payment: selectedPaymentMethod,
                receipt_sale_id: generated_math_num,
                clinic_id: clinic_id,
                clinic_name: clinicInfo.name,
                customer_email: appointment.email, 
                customer_name: `${appointment.username}`,
                date: format(new Date(), 'yyyy-MM-dd', { timeZone: torontoTz }),
                checkout_therapist: selectedTherapist,
                sales_rep: salesRep,
                clinic_url: fullUrl,
            };


            console.log('apt', appointment);
            console.log('clinicResponse', clinicInfo.id);
            console.log('receiptData is', receiptData);
            const receiptResponse = await api.post('/api/create-receipt/', receiptData);
            const receiptId = receiptResponse.data.receipt_sale_id;
            
            console.log('receiptId', receiptId)
        

            
            const receiptTotalsData = [];
            for (const appt of items) {
                const totalData = calculateFinalTotals([appt])[0];
                const appt_length = differenceInMinutes(
                    toZonedTime(appointment.end, torontoTz),
                    toZonedTime(appointment.start, torontoTz)
                );

                const singleReceiptTotalData = {
                    receipt: receiptId,
                    user: appointment.user,
                    clinic: clinic_id,
                    rate: totalData.rate,
                    tax: totalData.tax,
                    discount_type: totalData.discountType,
                    discount_rate: totalData.discount || null,
                    total: totalData.total,
                    appt_length: appt_length,
                    start_time: startToronto,
                    title: appointment.title,
                    therapist: appointment.resourceId,
                    
                };
    
                await api.post('/api/create-receipt-totals/', singleReceiptTotalData);
                receiptTotalsData.push(singleReceiptTotalData); 
            }

            const pdfBlob = await generateReceipt(generated_math_num, clinicInfo, fullUrl, selectedTherapist, salesRep);
            const formData = new FormData();
            formData.append('pdf', pdfBlob, `receipt_${generated_math_num}.pdf`);
            formData.append('receiptData', JSON.stringify(receiptData));
            formData.append('receiptTotalsData', JSON.stringify(receiptTotalsData));
            const response = await api.post('/api/generate-receipt/', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
    
            console.log("Response from generate-receipt:", response.data);
    
            closeModal();
            alertType('success');
            alertVis('Payment confirmed and receipt sent!');
            const now = new Date();
            const year = now.getFullYear();
            const month = now.toLocaleString('default', { month: 'long' });
            setTimeout(() => {
                window.location.href = `/schedule/${year}/${month}`;
            }, 5000);
        } catch (error) {
            console.error("Error confirming payment:", error);
            alertType('warning');
            alertVis('Error checking out');
        }
    };
    
    
    

    const generateReceipt = async (generated_math_num, clinicData, urlData, therapistSel, salesRep) => {
        console.log("Generating receipt...");
        const doc = new jsPDF({
            unit: 'pt',
            format: [227, 450] // Adjust height for a receipt-sized page
        });
    
        // Define styles and layout settings
        const line_height = 14;
        const left_margin = 15; // Left alignment margin for labels
    
        // Extract data from clinicData and other parameters
        const { name, address, city, state, phone } = clinicData;
        console.log('Rendering Clinic Name:', name);
        console.log('Rendering Clinic Address:', address);
        const user_name = String(items[0]?.username || 'Customer Name');
        const sale_date = new Date().toLocaleString('en-US', { hour12: true, year: 'numeric', month: '2-digit', day: '2-digit', hour: 'numeric', minute: 'numeric' });
        const payment_method = String(selectedPaymentMethod || 'Unknown Method');
        // doc.setFillColor(200, 200, 200); // Light gray for testing visibility
        // doc.rect(0, 0, doc.internal.pageSize.width, doc.internal.pageSize.height, 'F'); // Fills the entire page
        let y = 80;
    
        // Calculate max label length for consistent alignment
        const labels = ['Patient Name', 'Sale Date', 'Invoice ID', 'Payment Method'];
        const longestLabelWidth = Math.max(...labels.map(label => doc.getTextWidth(label))) + 3; // Add 5 units padding
    
        // Helper function to draw left-aligned text with consistent value starting point
        const drawAlignedText = (label, value, yPosition, fontSize = 9, fontStyle = 'normal') => {
            doc.setFont("Helvetica", fontStyle);
            doc.setFontSize(fontSize);
            // Draw label
            doc.text(`${label}:`, left_margin, yPosition);
            // Draw value, aligned consistently using longestLabelWidth
            doc.text(value, left_margin + longestLabelWidth, yPosition);
        };
    
        // Clinic Header (Centered)
        const clinicName = (name || 'Clinic Name').trim();
        const clinicAddress = (address || 'Clinic Address').trim();

        doc.setFontSize(12);
        doc.setFont("Helvetica", "bold");
        doc.text(clinicName, doc.internal.pageSize.width / 2, y, { align: 'center' });
        y += line_height;
        doc.setFontSize(10);
        doc.setFont("Helvetica", "normal");
        doc.text(clinicAddress, doc.internal.pageSize.width / 2, y, { align: 'center' });
        y += line_height;
        doc.text(`${city || ''}, ${state || ''}`.trim(), doc.internal.pageSize.width / 2, y, { align: 'center' });
        y += line_height;
        doc.text(phone || 'Clinic Phone', doc.internal.pageSize.width / 2, y, { align: 'center' });
        y += line_height;
        doc.text(urlData || '', doc.internal.pageSize.width / 2, y, { align: 'center' });
        y += line_height * 2;
    
        // Customer and Sale Info (aligned as per your request)
        drawAlignedText('Patient Name', user_name, y);
        y += line_height;
        drawAlignedText('Sale Date', sale_date, y);
        y += line_height;
        drawAlignedText('Invoice ID', String(generated_math_num), y);
        y += line_height;
        drawAlignedText('Sales Rep', salesRep, y);
        y += line_height;
        drawAlignedText('Payment Method', payment_method, y);
        y += line_height * 2;
    
        // Item Details (other content remains unchanged)
        doc.setFontSize(10);
        doc.setFont("Helvetica", "bold");
        doc.text('Items:', left_margin, y);
        y += line_height;
        doc.setFontSize(9);
        doc.setFont("Helvetica", "normal");
    
        // List items
        totals.forEach((item) => {
            const itemDescription = `${item.appt_length} min ${item.title} Therapy - Qty: ${item.qty || 1}`;
            const therapistName = `Therapist: ${therapistSel}`
            const itemTotal = `Total: $${item.finalTotal}`;
            doc.text(itemDescription, left_margin, y);
            y += line_height;
            doc.text(therapistName, left_margin, y);
            y += line_height;
            doc.text(itemTotal, left_margin, y);
            y += line_height * 1.5;
        });
    
        // Line Separator and Total Section (as before)
        doc.setLineWidth(0.5);
        doc.line(10, y, 217, y); // Draw horizontal line across the width
        y += line_height;
    
        // Total Section
        drawAlignedText('Subtotal', `$${totals.reduce((acc, item) => acc + parseFloat(item.total), 0).toFixed(2)}`, y);
        y += line_height;
        drawAlignedText('Tax', `$${totals.reduce((acc, item) => acc + parseFloat(item.tax), 0).toFixed(2)}`, y);
        y += line_height;
        drawAlignedText('Grand Total', `$${grandTotal}`, y, 10, 'bold');
        y += line_height * 2;
    
        // Footer
        doc.setFontSize(8);
        doc.setFont("Helvetica", "italic");
        doc.text("Thank you for your business!", doc.internal.pageSize.width / 2, y, { align: 'center' });
        y += line_height;
        doc.text("Please retain this receipt for your records.", doc.internal.pageSize.width / 2, y, { align: 'center' });
    
        // Adjust page size
        doc.internal.pageSize.height = y + 20;
    
        // For testing: preview the PDF
        const pdfUrl = doc.output("bloburl");
        // window.open(pdfUrl, "_blank");
        const pdfBlob = doc.output("blob");
        return pdfBlob;
    };
    
    
    
    
    
    const loadImage = (url) => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.crossOrigin = 'Anonymous';
            img.onload = () => {
                const canvas = document.createElement('canvas');
                canvas.width = img.width;
                canvas.height = img.height;
                const ctx = canvas.getContext('2d');
                ctx.drawImage(img, 0, 0);
                resolve(canvas.toDataURL('image/png'));
            };
            img.onerror = (error) => {
                console.error('Error loading image:', error);
                reject(error);
            };
            img.src = url;
        });
    };
    
    const onPayPalSuccess = (details, data) => {
        console.log("PayPal payment successful:", details);
        setPaypalSuccess(true);

        // Call confirmPayment function to update the appointment in your backend
        confirmPayment();
    };

    const onPayPalError = (error) => {
        console.error("PayPal payment error:", error);
        setPaypalError(error);
        alert('PayPal payment failed. Please try again.');
    };

    const redirectReceipt = () => {
        navigate(`/receipts/${appointment.user}`);
    }
    console.log('apt.paid', appointment.is_paid)

    return (
        <div>
            <div className="cart-container">
                <div className="cart-name" onClick={redirectReceipt}>
                {appointment.username}
        </div>
                <div>
                    <label htmlFor="therapist-select">Select therapist:</label>
                    <select 
                        id="therapist-select" 
                        value={selectedTherapist} 
                        onChange={handleTherapistChange}
                    >
                        <option value="">{selectedTherapist}</option>
                        {listTherapists
                            .filter(therapist => `${therapist.first_name} ${therapist.last_name}` !== selectedTherapist)
                            .map((therapist) => (
                                <option key={therapist.user_id} value={`${therapist.first_name} ${therapist.last_name}`}>
                                    {therapist.first_name} {therapist.last_name}
                                </option>
                        ))}
                    </select>
                    <div>
                        Date: <strong>{formattedDate}</strong>
                    </div>
                    <div className="cart-items-list">
                        {items.length > 0 ? totals.map((item, index) => (
                            <div key={index} className="cart-item">
                                <button onClick={() => onRemoveItem(item)}>X</button>
                                <div>
                                    {item.appt_length} min {item.title} - Qty: {item.qty || 1} - Discount: {item.discount || 0.0} {item.discountType !== 'Percent' ? '(DOLLARS)' : '(PERCENT)'}
                                    <div>
                                        Price: ${item.rate > 0 ? item.rate.toFixed(2) : (item.rate === 0 ? '0' : item.rate)}
                                        <br/>
                                        {item.discount ? (
                                            <>
                                                Total after discount: ${item.total}
                                                <br />
                                            </>
                                        ) : null}
                                        Tax (13%): ${item.tax} 
                                        <br/>
                                        Total: ${item.finalTotal}
                                    </div>
                                    {console.log('Item Discount:', item.discount)}
                                </div>
                            </div>
                        )) : <p>No items added yet.</p>}
                    </div>
                    <div className="bottom-total">Grand Total: ${grandTotal}</div>
                </div>
            </div>
            {items.length > 0 && (
                <div className="payment-info">
                    Payment Info
                    <div>Select a payment method</div>
                    <div className="payment-selection">
                        <button onClick={() => openModal('Cash')}>Cash</button>
                        <button onClick={() => openModal('Debit')}>Debit</button>
                        <button onClick={() => openModal('Visa')}>Visa</button>
                        <button onClick={() => openModal('Mastercard')}>Mastercard</button>
                        <button>Gift Card</button>
                        <button onClick={() => openModal('PayPal')}>PayPal</button>
                    </div>
                </div>
            )}
            <PaymentConfirmModal 
                show={showModal} 
                paymentMethod={selectedPaymentMethod} 
                onConfirm={confirmPayment} 
                onCancel={closeModal} 
                total={grandTotal}
            />
        </div>
    );
};

export default CartItems;
