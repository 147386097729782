import React, { useState } from 'react';
import { ReactSVG } from 'react-svg';
import Popup from 'reactjs-popup';
import styled from 'styled-components';
import 'reactjs-popup/dist/index.css';

const MuscleMap = ({ onSelect }) => {
  const [selectedMuscle, setSelectedMuscle] = useState(null);
  const [selectedElement, setSelectedElement] = useState(null);
  const [open, setOpen] = useState(false);
  const [muscleColors, setMuscleColors] = useState({});

  const handleMuscleClick = (event) => {
    const target = event.target;
    if (target.tagName === 'path') {
      const muscleLabel = target.getAttribute('inkscape:label');
      if (muscleLabel) {
        setSelectedMuscle(muscleLabel);
        setSelectedElement(target);
        setOpen(true);
      }
    }
  };

  
  const handleConditionSelect = (side, condition) => {
    console.log("Condition selected:", condition, "Side selected:", side);
    console.log("Selected muscle:", selectedMuscle);

    let color = "transparent";
    switch (condition) {
        case "Adhesions":
            color = "green";
            break;
        case "Scar tissue":
            color = "red";
            break;
        case "Trigger point":
            color = "purple";
            break;
        default:
            color = "transparent";
            break;
    }

    console.log("Color to apply:", color);

    if (selectedElement && side && condition && selectedMuscle) {
        const parts = selectedMuscle.split("-");
        console.log("Parsed parts:", parts);

        let position = "";
        let muscleName = "";

        // Extract position and muscle name
        if (parts.length >= 3 && (parts[1] === "ant" || parts[1] === "post")) {
            position = parts[1];
            muscleName = parts.slice(2).join("-");
        } else {
            muscleName = parts.slice(1).join("-");
        }

        console.log("Extracted position:", position, "Muscle name:", muscleName);

        // Create a list of muscles to update based on the side
        let musclesToUpdate = [];

        if (side === "Left") {
            if (position) {
                // Add the actual selected muscle and opposite position
                musclesToUpdate.push(`L-${position}-${muscleName}`);
                musclesToUpdate.push(`L-${position === "ant" ? "post" : "ant"}-${muscleName}`);
            } else {
                // No position, just the muscle name
                musclesToUpdate.push(`L-${muscleName}`);
            }
        } else if (side === "Right") {
            if (position) {
                musclesToUpdate.push(`R-${position}-${muscleName}`);
                musclesToUpdate.push(`R-${position === "ant" ? "post" : "ant"}-${muscleName}`);
            } else {
                musclesToUpdate.push(`R-${muscleName}`);
            }
        } else if (side === "Bilateral") {
            if (position) {
                // Add both sides with both positions
                musclesToUpdate.push(`L-${position}-${muscleName}`);
                musclesToUpdate.push(`R-${position}-${muscleName}`);
                musclesToUpdate.push(`L-${position === "ant" ? "post" : "ant"}-${muscleName}`);
                musclesToUpdate.push(`R-${position === "ant" ? "post" : "ant"}-${muscleName}`);
            } else {
                // No position, add both sides
                musclesToUpdate.push(`L-${muscleName}`);
                musclesToUpdate.push(`R-${muscleName}`);
            }
        }

        console.log("Final muscles to update:", musclesToUpdate);

        // Update muscle colors
        setMuscleColors((prevColors) => {
            const updatedColors = { ...prevColors };
            musclesToUpdate.forEach((muscle) => {
                updatedColors[muscle] = color;
                console.log(`Updated color for muscle ${muscle} to ${color}`);
            });
            return updatedColors;
        });

        // Create a new note
        const displayMuscleName = muscleName.replace(/-/g, " ");
        const newNote = `${side} ${displayMuscleName}: ${condition}`;
        onSelect(newNote, selectedMuscle, color, side); // Pass data to parent
        console.log('parent:', selectedMuscle)
    }

    setSelectedMuscle(null);
    setOpen(false);
};



  
  
  const extractCleanMuscleName = (muscleLabel) => {
    if (!muscleLabel) return '';
    
    const parts = muscleLabel.split('-');
    
    let muscleName = '';
  
    if (parts.length >= 3 && (parts[1] === 'ant' || parts[1] === 'post')) {
      // Format is "L/R-ant-muscleName" or "L/R-post-muscleName"
      muscleName = parts.slice(2).join('-');
    } else {
      // Format is "L/R-muscleName"
      muscleName = parts.slice(1).join('-');
    }
  
    // Replace dashes with spaces for better readability
    return muscleName.replace(/-/g, ' ');
  };


  return (
    <div className="main-container">
      <StyledSVGContainer>
        <ReactSVG
          src="https://res.cloudinary.com/dhdn6hovg/image/upload/v1732072540/muscle2_af52ss.svg"
          beforeInjection={(svg) => {
            svg.setAttribute('style', 'width: 50%; height: auto;');
            const paths = svg.querySelectorAll('path');
            paths.forEach((path) => {
              const muscleLabel = path.getAttribute('inkscape:label').trim(); 
              console.log('Available muscle in SVG:', muscleLabel); // Check all labels present in the SVG
              if (muscleColors[muscleLabel]) {
                path.style.fill = muscleColors[muscleLabel];
              } else {
                path.style.fill = 'transparent';
              }
            });
          }}
          onClick={handleMuscleClick}
          className="react-svg-container"
        />
      </StyledSVGContainer>
      {selectedMuscle && (
        <Popup open={open} closeOnDocumentClick onClose={() => setSelectedMuscle(null)}>
          <div className="mmodal" style={{ padding: '20px', backgroundColor: 'white', borderRadius: '10px' }}>
            <h3>Select condition for {selectedMuscle && extractCleanMuscleName(selectedMuscle)}</h3>

            {/* <div>
              <label>
                Areas:
              </label>
              <label>
                <input type="checkbox" id="anterior" name="areas" value="anterior" /> Anterior
              </label>
              <br />
              <label>
                <input type="checkbox" id="posterior" name="areas" value="posterior" /> Posterior
              </label>
              <br />
              <label>
                <input type="checkbox" id="lateral" name="areas" value="lateral" /> Lateral
              </label>
              <br />
            </div> */}
            <div>
              <label>
                <input type="radio" name="side" value="Left" /> Left
              </label>
              <label>
                <input type="radio" name="side" value="Right" /> Right
              </label>
              <label>
                <input type="radio" name="side" value="Bilateral" /> Bilateral
              </label>
            </div>
            <div>
              <label>
                <input type="radio" name="condition" value="Trigger point" /> Trigger point
              </label>
              <label>
                <input type="radio" name="condition" value="Scar tissue" /> Scar Tissue
              </label>
              <label>
                <input type="radio" name="condition" value="Adhesions" /> Adhesions
              </label>
            </div>
            <button
              onClick={() => {
                const side = document.querySelector('input[name="side"]:checked').value;
                const condition = document.querySelector('input[name="condition"]:checked').value;
                handleConditionSelect(side, condition);
              }}
            >
              Save
            </button>
            <button onClick={() => setSelectedMuscle(null)}>Close</button>
          </div>

        </Popup>
      )}
    </div>
  );
};

const StyledSVGContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  height: auto;
  margin: 0 auto;
`;

export default MuscleMap;
