// import React from 'react';
// import styled from 'styled-components';
// import { ReactSVG } from 'react-svg';

// const StyledSVGContainer = ({ svgData, muscleData }) => {
//     return (
//         <SVGWrapper>
//             <ReactSVG
//                 src={`data:image/svg+xml;base64,${btoa(svgData)}`}
//                 beforeInjection={(svg) => {
//                     svg.setAttribute('style', 'width: 100%; height: auto;');
//                     const paths = svg.querySelectorAll('path');
//                     paths.forEach(path => {
//                         const muscleLabel = path.getAttribute('inkscape:label');
//                         if (muscleLabel && muscleData[muscleLabel]) {
//                             path.style.fill = muscleData[muscleLabel];
//                         } else {
//                             path.style.fill = 'transparent';
//                         }
//                     });
//                 }}
//             />
//         </SVGWrapper>
//     );
// };

// const SVGWrapper = styled.div`
//     width: 100%;
//     height: auto;
// `;

// export default StyledSVGContainer;
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ReactSVG } from 'react-svg';

const StyledSVGContainer = ({ muscleData }) => {
    const [svgContent, setSvgContent] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchSvgFromCloudinary = async () => {
            try {
                // Cloudinary URL for the SVG
                const url = 'https://res.cloudinary.com/dhdn6hovg/image/upload/v1732072540/muscle2_af52ss.svg';

                const response = await fetch(url);
                if (!response.ok) {
                    throw new Error(`Error fetching SVG: ${response.statusText}`);
                }

                const svgText = await response.text();
                setSvgContent(svgText); // Set the SVG content directly
                setLoading(false);
            } catch (error) {
                console.error('Error fetching SVG:', error);
                setLoading(false);
            }
        };

        fetchSvgFromCloudinary();
    }, []);

    return (
        <SVGWrapper>
            {loading ? (
                <p>Loading SVG...</p>
            ) : (
                svgContent && (
                    <ReactSVG
                        src={`data:image/svg+xml;base64,${btoa(svgContent)}`}
                        beforeInjection={(svg) => {
                            svg.setAttribute('style', 'width: 100%; height: 100%;');
                            const paths = svg.querySelectorAll('path');
                            paths.forEach((path) => {
                                const muscleLabel = path.getAttribute('inkscape:label'); // Adjust based on your SVG
                                if (muscleLabel && muscleData[muscleLabel]) {
                                    path.style.fill = muscleData[muscleLabel];
                                } else {
                                    path.style.fill = 'transparent';
                                }
                            });
                        }}
                    />
                )
            )}
        </SVGWrapper>
    );
};

const SVGWrapper = styled.div`
    width: 100%;
    height: auto;
`;

export default StyledSVGContainer;
