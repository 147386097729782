import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import api from '../../../api';
import '../../../styles/TherapistAvailabilityForm.css'
import TherapistAvailabilityView from './TherapistAvailabilityView';
import VisAlert from '../../VisAlert';

const TherapistAvailabilityForm = ({ userId, msg, msgType }) => {
    const [availability, setAvailability] = useState([]);
    const [key, setKey] = useState(0);



    const [weekdays] = useState([
        { value: 0, label: 'Monday' },
        { value: 1, label: 'Tuesday' },
        { value: 2, label: 'Wednesday' },
        { value: 3, label: 'Thursday' },
        { value: 4, label: 'Friday' },
        { value: 5, label: 'Saturday' },
        { value: 6, label: 'Sunday' },
    ]);
    const timeSlots = [
        '09:00:00', '10:00:00', '11:00:00', '12:00:00', '13:00:00', '14:00:00', '15:00:00', '16:00:00', '17:00:00',
        '18:00:00', '19:00:00', '20:00:00', '21:00:00'
    ];

    useEffect(() => {
        const fetchAvailability = async () => {
            try {
                console.log('userid is ', userId)
                const response = await api.get(`/api/therapists/${userId}/availability/`);
                if (response.data.length > 0) {
                    setAvailability(response.data);
                } else {
                    setAvailability(initializeAvailability());
                }
                console.log('Availability data:', response.data);
            } catch (error) {
                console.error('Error fetching availability:', error);
            }
        };
    
        fetchAvailability();
    }, [userId, key]);
    
    
    const refreshAvailabilityView = () => {
        setKey(prevKey => prevKey + 1); // Increment key to force remount
    };


    const initializeAvailability = () => {
        return weekdays.map((day, index) => ({
            therapist: userId,
            weekday: index,
            start_time: '',
            end_time: ''
        }));
    };
    const handleSubmit = async (event) => {
        event.preventDefault();
    
        const isValid = availability.every(slot => {
            const hasStartTime = !!slot.start_time;
            const hasEndTime = !!slot.end_time;
            return (hasStartTime && hasEndTime) || (!hasStartTime && !hasEndTime);
        });
    
        if (!isValid) {
            msg('Please provide both start and end times or leave them both empty.');
            msgType('warning');
            return;
        }
    
        // Additional validation: Check if start time is before end time
        const isStartTimeBeforeEndTime = availability.every(slot => {
            if (!slot.start_time || !slot.end_time) {
                // Skip validation if either start or end time is empty
                return true;
            }
            const startTime = new Date(`1970-01-01T${slot.start_time}`);
            const endTime = new Date(`1970-01-01T${slot.end_time}`);
            return startTime < endTime;
        });
    
        if (!isStartTimeBeforeEndTime) {
            msg('Start time must be before end time.');
            msgType('warning');
            return;
        }


        // Format availability data to include therapist ID and weekday
        const formattedAvailability = availability.map((slot, index) => ({
            therapist: userId,  // therapistId corresponds to the User ID
            weekday: index,
            start_time: slot.start_time || null,
            end_time: slot.end_time || null,
        }));

        console.log('Formatted Availability:', formattedAvailability);
        
        try {
            await api.post(`/api/therapists/${userId}/availability/`, formattedAvailability);
            msg(`Therapist's permanent availability updated successfully!`);
            msgType('success');
            refreshAvailabilityView();
        } catch (error) {
            console.error('Error updating availability:', error);
        }

    };
    
    const handleChange = (index, field, value) => {
        if (availability.length === 0) {
            const newAvailability = weekdays.map((_, idx) => ({
                therapist: userId,  // therapistId corresponds to the User ID
                weekday: idx,
                start_time: '',
                end_time: ''
            }));
            newAvailability[index][field] = value;
            setAvailability(newAvailability);
        } else {
            if (index >= 0 && index < availability.length) {
                const newAvailability = [...availability];
                newAvailability[index][field] = value;
                setAvailability(newAvailability);
            } else {
                console.log('Index is out of range.');
            }
        }
        console.log('Updated availability:', availability);
    };

    console.log('Availability:', availability);
    console.log('Time slots:', timeSlots);

    return (
        <div>
            <>

            </>
             {availability.length > 0 && (
        <form onSubmit={handleSubmit}>
            <h2>Set Weekly Availability</h2>
            {weekdays.map((day, index) => {
                const currentAvailability = availability.find(item => item.weekday === index);
                const startTime = currentAvailability ? currentAvailability.start_time : '';
                const endTime = currentAvailability ? currentAvailability.end_time : '';
            
            return (
                        <div key={day.value}>
                            <label className="weekday">{day.label}</label>
                            <select value={availability[index]?.start_time || ''} onChange={(e) => handleChange(index, 'start_time', e.target.value)}>
                    <option value="">Start Time</option>
                    {timeSlots.map(time => <option key={time} value={time}>{time}</option>)}
                </select>
                <select value={availability[index]?.end_time || ''} onChange={(e) => handleChange(index, 'end_time', e.target.value)}>
                    <option value="">End Time</option>
                    {timeSlots.map(time => <option key={time} value={time}>{time}</option>)}
                </select>
                </div>
            );
        })}

            <br/>
            <div className="div-btn">
            <Button className="btn" type="submit">Save</Button>
            </div>
        </form>
         )}
        </div>
    );
};


export default TherapistAvailabilityForm;
